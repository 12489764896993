import { useRef, useState } from 'react';
import { BsFillMicFill } from 'react-icons/bs';
import { IoMdReturnLeft } from 'react-icons/io';
import { MdDelete, MdOutlinePause } from 'react-icons/md';
import { ReactMic } from 'react-mic';
import styles from "./VoiceMediaRecorderBtn.module.css";

// eslint-disable-next-line react/prop-types
const VoiceMediaRecorderBtn = ({ setVisibleMicrophoneHandler }) => {
    const [voice, setVoice] = useState(false);
    const [recordBlobLink, setRecordBlobLink] = useState(null);
    const [isRecording, setIsRecording] = useState(false);
    const audioChunksRef = useRef([]);

    const audioRef = useRef(null); // Referencia al elemento <audio>

    const [audioUrl, setAudioUrl] = useState(null);

    const mediaRecorderRef = useRef(null);

    // Función para iniciar la grabación

    const startHandle = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          mediaRecorderRef.current = new MediaRecorder(stream);
    
          mediaRecorderRef.current.ondataavailable = (event) => {
            audioChunksRef.current.push(event.data);
          };
    
          mediaRecorderRef.current.onstop = () => {
            const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioUrl(audioUrl);
            audioChunksRef.current = []; // Limpiar el buffer de audio
          };
    
          mediaRecorderRef.current.start();
          setIsRecording(true);
        } catch (error) {
          console.error("Error al acceder al micrófono:", error);
        }
        setVoice(true);
      };

    // Función para detener la grabación

    const stopHandle = () => {
        setVoice(false);
        if (mediaRecorderRef.current && isRecording) {
          mediaRecorderRef.current.stop();
          setIsRecording(false);
        }
      };


    // Función para limpiar el estado
    const clearHandle = () => {
        if (recordBlobLink) {
            URL.revokeObjectURL(recordBlobLink); // Liberar el Object URL
        }
        setVoice(false);
        setRecordBlobLink(null);
    };

    // Función que se ejecuta cuando se detiene la grabación
    const onStop = (recordedBlob) => {
        const audioUrl = URL.createObjectURL(recordedBlob.blob); // Crear un Object URL a partir del blob
        setRecordBlobLink(audioUrl);
        setVoice(false);
    };

    return (

        <div>
            <div 
                className={styles.voiceMediaRecorder}
                >
                <IoMdReturnLeft
                    style={{
                        display: "block",
                        backgroundColor: "transparent",
                        border: "none",
                        boxShadow: "none",
                        cursor: "pointer",
                        fontSize: "25px",
                        marginTop: "20px"
                    }}
                    onClick={() => setVisibleMicrophoneHandler(false)}
                />
                {!recordBlobLink && (
                    <ReactMic
                        record={voice}
                        pause={voice}
                        visualSetting="sinewave"
                        className={styles.micContainer}
                        onStop={onStop}
                        strokeColor={"#000000"}
                        backgroundColor={"#FFFFFF"}
                        mimeType="audio/webm"
                        echoCancellation={true}
                        autoGainControl={true}
                        noiseSuppression={true}
                    />
                )}
                <div 
                    className={styles.deleteAudioBtn}
                    >
                    {recordBlobLink && (
                        <button onClick={clearHandle}>
                            <MdDelete
                                style={{
                                    backgroundColor: "transparent",
                                    color: "inherit",
                                    border: "none",
                                    boxShadow: "none",
                                    cursor: "pointer",
                                    fontSize: "25px",
                                }}
                            />
                        </button>
                    )}
                </div>
                <div >
                    {recordBlobLink && (
                        <audio  
                            className={styles.audioControls}
                             ref={audioRef} controls src={audioUrl}>No se puede reproducir el audio</audio>
                    )}
                </div>
                <div 
                    className={styles.recordAudioBtn}
                    >
                    {!voice ? (
                        <button onClick={startHandle} className="">
                            <BsFillMicFill
                                style={{
                                    backgroundColor: "transparent",
                                    color: "inherit",
                                    border: "none",
                                    boxShadow: "none",
                                    cursor: "pointer",
                                    fontSize: "21px"
                                }}
                            />
                        </button>
                    ) : (
                        <button onClick={stopHandle}>
                            <MdOutlinePause
                                style={{
                                    backgroundColor: "transparent",
                                    color: "inherit",
                                    border: "none",
                                    boxShadow: "none",
                                    cursor: "pointer",
                                    fontSize: "27px"
                                }}
                            />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default VoiceMediaRecorderBtn;

