import { FaPencilAlt } from "react-icons/fa"
import styles from "./MessageListHeader.module.css"
import MessageListSearch from "./MessageListSearch/MessageListSearch"
import { IoChevronBackOutline } from "react-icons/io5";
import { SIDEBARMENU } from "../../utils/CRMconstants";

export default function MessageListHeader({onSelectMenuOption}) {
    return (
        <div>
            <div className={styles.messageListHeader}>
                <button onClick={() => onSelectMenuOption(SIDEBARMENU)}>
                    <IoChevronBackOutline className={styles.goBackBtnIcon} />
                </button>
                <h2 className={styles.sidebarTitle}>Mensajes</h2>
                <button className={styles.sidebarPencilBtn}>
                    <FaPencilAlt className={styles.iconSidebarPencilBtn} />
                </button>
            </div>
            {/* Buscador de mensajes */}
            <MessageListSearch />
        </div>
    )
}