import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase/firebase";


const getProductsSnapshot = async (query, previousProducts, onData, onQuantityDocuments, quantityDocuments, setOcultLoadMoreProducts) => {
  const unsubscribe = onSnapshot(query, (snapshot) => {
    const products = [];
    snapshot.forEach((doc) => {
      products.push(doc.data());
    });

    // Comparamos los nuevos productos con los productos anteriores
    const isNewData = !arraysEqual(products, previousProducts, setOcultLoadMoreProducts);
    onData(products);
    if (isNewData) {
      onQuantityDocuments(prevQuantity => prevQuantity + quantityDocuments);
    }
  });

  return unsubscribe;
};

export const getProductsByTerm = async (searchString, setFunction) => {
  // const searchString = e.target.value // Obtener el valor del input y limpiarlo

  if (searchString.length > 0) {
    try {
      const productDocuments = [];
      const productsCollection = collection(db, "products"); // Colección de productos en Firestore
      const startAt = searchString;
      const endAt = searchString + '\uf8ff';

      // Crear la consulta para Firestore v10
      const productsQuery = query(
        productsCollection,
        where('product_name', '>=', startAt),
        where('product_name', '<=', endAt)
      );

      // Ejecutar la consulta y obtener los documentos
      const querySnapshot = await getDocs(productsQuery);

      // Procesar los documentos
      querySnapshot.forEach((doc) => {
        productDocuments.push(doc.data())
      });
      setFunction(productDocuments)
    } catch (error) {
      console.error("Error fetching products: ", error);
    }
  }
};

// Función para comparar dos arrays
function arraysEqual(a, b, setOcultLoadMoreProducts) {
  console.log("Productos : ", a.length, " , Previos : " ,b)
  if (a.length === b) {
    // console.log("es igual, no hay datos nuevos, se oculta el boton")
    if( setOcultLoadMoreProducts) {
      setOcultLoadMoreProducts(true)
    }
    return true;
  } else {
    return false;
  }
}

function groupVisibleProductIds(visibleProducts) {
  const productIdsSet = new Set();

  // Recorremos cada clave (ID) del objeto visibleProducts
  Object.keys(visibleProducts).forEach(productId => {
    // Si el valor asociado al ID es true, lo agregamos al Set
    if (visibleProducts[productId] === true) {
      productIdsSet.add(productId);
    }
  });

  return Array.from(productIdsSet);
}

// Funcion 
export const getPaginatedProducts = async (body) => {
    const { productName, type, quantityDocuments, products10X, setProducts, setQuantityDocuments, isInsider, setOcultLoadMoreProducts, catalogData, productsIds , isCatalogOwner} = body;
    let productosIds = productsIds;
    let tipo = type;
    // Crear la referencia base a la colección "products
    if (catalogData && !isCatalogOwner && !catalogData?.show_all_productos && type !== "byProductName") {
      const catalogProductsIdS = groupVisibleProductIds(catalogData.visible_products);
      tipo = "onlyProductsSnapshot";
      productosIds = catalogProductsIdS
    }
    let productsRef = query(collection(db, "products"), orderBy("id"));
    const quantityDocumentsPerPage = quantityDocuments ? parseInt(quantityDocuments, 10)  : 10;
        // Aplicar el filtro "show_product_to_other_sellers"

      let q =  isInsider ? [
        limit(quantityDocumentsPerPage) 
      ] : [
      where("show_product_to_other_sellers", "==", true),
      where("product_is_active", "==", true),
      limit(quantityDocumentsPerPage) 
    ];
    // console.log("Productos paginados: tipo: ", tipo, quantityDocuments, body);
    switch (tipo) {
      case "byCategories": {
        const { productValuesCategories, isArray } = body;
        // console.log("productValuesCategories", productValuesCategories)
        // Verificar si productValuesCategories es un solo y no un array
        if (!isArray) {
          const categoryId = productValuesCategories.id;
          console.log(categoryId);
          q =[ ...q, where("categories_ids", "array-contains", categoryId)];
        } else if (isArray && productValuesCategories.length > 0) {
          const categoryIds = productValuesCategories.map(categoryObject => categoryObject.id);
          q = [ ...q, where("categories_ids", "array-contains-any", categoryIds)];
        }
        q = query( productsRef, ...q);
        try {
          const snapshot = await getProductsSnapshot(q, products10X, setProducts, setQuantityDocuments, quantityDocuments);
          return snapshot;
        } catch (error) {
          console.error("Error al ejecutar la consulta por categoria:", error);
          return { error: "Error en la consulta" };
        }
      }
      case "allCategories": {
        const { productValuesCategories } = body;
        if (
          Array.isArray(productValuesCategories) &&
          productValuesCategories.length > 0
        ) {
          const categoryIds = productValuesCategories.map(categoryObject => categoryObject.id);
          q = [
            ...q,
            where("categories_ids", "array-contains-any", categoryIds)
          ];
        }
        q = query( productsRef, ...q);
        try {
          const snapshot = await getProductsSnapshot(q, products10X, setProducts, setQuantityDocuments, quantityDocuments);
          return snapshot;
          
        } catch (error) {
          console.error("Error al ejecutar la consulta por todas las categorias:", error);
          return { error: "Error en la consulta" };
        }

      }
      case "productScroll":
        try {
          const productsRef = collection(db, "products");
          q = query( productsRef, ...q);
          const snapshot = await getProductsSnapshot(q, products10X, setProducts, setQuantityDocuments, quantityDocuments, setOcultLoadMoreProducts);
          // Retornar una función para cancelar la suscripciónx
          return snapshot;
        } catch (error) {
          console.error("Error en la consulta:", error);
          return { error: "Error en la consulta" };
      }
      case "categoryScroll": {
        try {
          const { productValuesCategories } = body;
          // Aplica los filtros where antes de ordenar por "id"
          q =  query(
            productsRef, ...q,
            where("categories", "array-contains", productValuesCategories )
          );
          const snapshot = await getProductsSnapshot(q, products10X, setProducts, setQuantityDocuments, quantityDocuments, setOcultLoadMoreProducts);
          return snapshot;

        } catch (error) {
          console.error("Error fetching products:", error);
          return { error: "Error fetching products" };
        }
      }
      case "onlyProducts":
        try {
          const products = [];
          const productsRef = collection(db, "products");
          for (const productId of productosIds) {
            const snapshot = await getDocs(
              query( productsRef, where("id", "==", productId))
            );
            snapshot.forEach((doc) =>  products.push(doc.data()));
          }
          console.log("products", products)
          return products;
        } catch (error) {
          console.error("Error al ejecutar la consulta:", error);
          return { error: "Error en la consulta" };
      }
      case "oneProduct": {
        const { productId } = body;
        try {
          const productoDoc = await getDoc(doc(db, "products", productId));
          if (!productoDoc.exists()) {
            return { message: "El producto no fue encontrado" };
          }
          const producto = productoDoc.data();
          console.log("producto", producto);
          return producto;
        } catch (error) {
          return { message: "Hubo un error en el servidor" };
        }
      }
      case "onlyProductsSnapshot": {
        const productsRef = collection(db, "products");
      
        if (productosIds && productosIds.length > 0) {
          const batchSize = 10; // Firestore 'in' query limit
          const batches = [];
      
          // Dividir productosIds en lotes de tamaño batchSize
          for (let i = 0; i < productosIds.length; i += batchSize) {
            const batch = productosIds.slice(i, i + batchSize);
            batches.push(batch);
          }
      
          const promises = batches.map((batch) => {
            const q = query(productsRef, where("id", "in", batch));
            return getDocs(q).then((snapshot) => {
              const productsDocuments = [];
              snapshot.forEach((doc) => {
                productsDocuments.push(doc.data());
              });
              return productsDocuments;
            });
          });
      
          return Promise.all(promises)
            .then((results) => {
              // Unir todos los resultados en un solo array
              const allProducts = [].concat(...results);
              setProducts(allProducts)
              return allProducts;
            })
            .catch((error) => {
              console.error("Error fetching products:", error);
              throw new Error("Ocurrió un error al obtener los productos");
            });
        } else {
          // Manejar caso cuando no hay IDs de productos proporcionados
          console.log("No hay IDs de productos proporcionados");
          return [];
        }
      }
      case "allProducts": {
        const productsRef = collection(db, "products");
        return new Promise((resolve, reject) => {
          const unsubscribe = onSnapshot(
            productsRef,
            (snapshot) => {
              const productsDocuments = [];
              snapshot.forEach((doc) => {
                productsDocuments.push(doc.data());
              });
              resolve(productsDocuments);
            },
            (error) => {
              reject(error);
            }
          );

          // Opcionalmente, puedes devolver la función de desuscripción para manejarla externamente
          return unsubscribe;
        });
      }
      case "byProductName": {
        if (productName.length > 0) {
          const productsCollection = collection(db, "products"); // Referencia a la colección de productos
          
          // Convertir el valor de búsqueda a minúsculas
          const searchProductName = productName.trim().toLowerCase();
          
          // Definir el rango de búsqueda usando el string en minúsculas
          const startAt = searchProductName;
          const endAt = searchProductName + '\uf8ff';
          q = [...q,  
            where('product_name_for_search', '>=', startAt),
            where('product_name_for_search', '<=', endAt)
           ];
          // Crear la consulta para Firestore v10
          const productsQuery = query(productsCollection, ...q);
      
          // Usar onSnapshot para obtener los documentos en tiempo real
          const unsubscribe = onSnapshot(productsQuery, (querySnapshot) => {
            const productDocuments = [];
            querySnapshot.forEach((doc) => {
              productDocuments.push(doc.data()); // Agregar cada documento al array
            });
            setProducts(productDocuments); // Actualizar el estado con los documentos obtenidos
          }, (error) => {
            console.error("Error fetching products: ", error);
          });
      
          // Retornar la función que desuscribe el listener del snapshot
          return unsubscribe;
        }
        break;
      }
    }
};
