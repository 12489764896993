import styles from "./MessagesListWindow.module.css"
import MessageListHeader from "./MessageListHeader/MessageListHeader";
import MessageList from "./MessagesList/MessagesList";

export default function MessagesListWindow({onSelectMenuOption, onMessageSelect}) {
    return (
      <div className={styles.menuListBar}>
        <MessageListHeader onSelectMenuOption={onSelectMenuOption}/>
        <MessageList onMessageSelect={onMessageSelect}/>
      </div>
    );
  }
  
  


