import PropTypes from "prop-types";
import styles from "./DetailShopifyStoreForm.module.css";
import { useEffect, useState } from "react";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import MainButton from "../MainButton/MainButton";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import { generateRandomId } from "../../utils/generateRandomId.JS";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext";
import { useTranslation } from "react-i18next";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog";
import CustomModal from "../CustomModal/CustomModal";
import VideoModal from "../VideoModal/VideoModal";
import InfoBox from "../InfoBox/InfoBox";
import copyValue from "../../utils/copyValue";
import DeleteButton from "../DeleteButton/DeleteButton";
import CryptoJS from "crypto-js";
import toastFunction from "../../utils/toastFunction";

const DetailShopifyStoreForm = ({ selectedSellerStore, labelSuccess, userData, labelDelete, onClose, toast }) => {
  const { t } = useTranslation("Stores");
  const { 
    addToArray,
    removeFromArray,
    updateArray,
    createDocument, 
    updateDocument,
    deleteDocument
  } = useFirebaseCRUD();
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const { platforms } = useGlobalContext();
  const { VIDEO_SHOPIFY_PRODUCT_TOKEN_TUTORIAL, VIDEO_SHOPIFY_ORDERS_ENDPOINT_TUTORIAL } = useGlobalContext();
  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);

  const [tokenProducts, setTokenProducts] = useState(selectedSellerStore?.product_sync_shopify_app_token || "");
  const [name, setName] = useState(selectedSellerStore?.name || "");
  const [url, setUrl] = useState(selectedSellerStore?.store_url || "");
  const [platform, setPlatform] = useState(selectedSellerStore?.platform || "");
  const [visibleTokenProductsVideo, setVisibleTokenProductsVideo] = useState(false);
  const [visibleWebTokenVideo, setVisibleWebTokenVideo] = useState(false);
  const [visibleInfoBox, setVisibleInfoBox] = useState(false);
  
  const [sellerStoreEndpoint, setSellerStoreEndpoint] = useState(
    selectedSellerStore.order_creation_endpoint ||
      `https://us-central1-shoppex-aa709.cloudfunctions.net/shopify/checkShopifyOrdersConnection/`
  );
  const [visibleCreateBtn, setVisibleCreateBtn] = useState(true);
  const STORESCOLLECTIONNAME = "stores"
  // eslint-disable-next-line no-unused-vars

  const hasEdit = !!Object.keys(selectedSellerStore).length;

  const sellerStoreValues = {
    created_at: selectedSellerStore?.created_at || new Date(),
    id: selectedSellerStore?.id || generateRandomId(),
    name: name,
    platform: platform,
    store_url: url,
    order_creation_endpoint: sellerStoreEndpoint,
    product_sync_shopify_app_token: tokenProducts,
  };

  // Función para encriptar
  const encrypt3DES = (message, secret) => {
    return CryptoJS.TripleDES.encrypt(message, secret).toString();
  };

  const handleShopifyUrl = (event) => {
    const baseDomain = ".myshopify.com";
    let inputVal = event.target.value;

    // Eliminar "https://"
    inputVal = inputVal.replace("https://", "");

    // Eliminar duplicaciones del dominio base
    if (inputVal.endsWith(baseDomain)) {
      inputVal = inputVal.slice(0, -baseDomain.length);
    }

    if (!inputVal.endsWith(baseDomain)) {
      inputVal = inputVal.replace(baseDomain, "");
      // Expresión regular para verificar si el último carácter es un backslash
      const regex = /\/$/;
      // Si el último carácter es un backslash, quitarlo
      if (regex.test(inputVal)) {
        inputVal = inputVal.slice(0, -1);
      }
      inputVal += baseDomain;
    }
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      // Evitar duplicación si la tecla de borrar es presionada
      if (inputVal.endsWith(baseDomain)) {
        inputVal = inputVal.slice(0, -baseDomain.length);
      }
    }
    setUrl(inputVal);
  };

  // const decrypt3DES = (encryptedMessage, secret) => {
  //   const bytes = CryptoJS.TripleDES.decrypt(encryptedMessage, secret);
  //   return bytes.toString(CryptoJS.enc.Utf8);
  // };

  const handleCRUDStore = async (e) => {
    e.preventDefault();
    setGlobalLoading(true);
    const hasEdit = !!Object.keys(selectedSellerStore).length;
    let noCloseModal = false;

    if (hasEdit) {
      try {
        if (selectedSellerStore.store_url !== url) {
          const concatIds = `${userData.uid}:${sellerStoreValues.id}`; // Codificar el ID del usuario
          // console.log("concatIds", concatIds);
          // console.log("sellerStoreValues.store_url", sellerStoreValues.store_url);
          const encryptSSid = encrypt3DES(concatIds, sellerStoreValues.store_url);
          const cadenaCodificada = encodeURIComponent(encryptSSid);

          // const cadenaDecodificada = decodeURIComponent(cadenaCodificada);
          // console.log("encryptSSid y cadenaCodificada", encryptSSid, cadenaCodificada);
          // console.log(desencryptSSid);

          const urlEndpoint = `https://us-central1-shoppex-aa709.cloudfunctions.net/shopify/checkShopifyOrdersConnection/?ssid=${cadenaCodificada}`;
          sellerStoreValues.order_creation_endpoint = urlEndpoint;
          setSellerStoreEndpoint(urlEndpoint);
          // console.log("urlEndpoint ", urlEndpoint);
          noCloseModal = true;
        }

        // const concatIds = `${userData.uid}:${sellerStoreValues.id}`; // Codificar el ID del usuario
        // const encryptSSid = encrypt3DES(concatIds, sellerStoreValues.store_url);
        // const cadenaCodificada = encodeURIComponent(encryptSSid);

        // const cadenaDecodificada = decodeURIComponent(cadenaCodificada);
        // const desencryptSSid = decrypt3DES(cadenaDecodificada, sellerStoreValues.store_url);
        // console.log("encryptSSid y cadenaCodificada", desencryptSSid, cadenaCodificada);

        const updatedStores = userData.seller_stores.filter((store) => store.id === sellerStoreValues.id);
        // console.log("Antes de actualizar", updatedStores[0].order_creation_endpoint, sellerStoreValues.order_creation_endpoint);

        const resultUpdateArrayValues = await updateArray(
          "seller_users",
          userData.uid,
          "seller_stores",
          updatedStores[0],
          sellerStoreValues
        );
        const resultUpdateShopifyStore = await updateDocument(STORESCOLLECTIONNAME, sellerStoreValues.id, sellerStoreValues)
        // console.log("resultUpdateArrayValues", resultUpdateArrayValues);
        if (resultUpdateArrayValues && resultUpdateShopifyStore) {
          toastFunction(toast, "success", t("toast-summary.updateStore"), "", 3000);
        }
        if (!noCloseModal) {
          onClose(false);
        }
      } catch (error) {
        toastFunction(toast, "error", t("toast-summary.errorUpdateStore"), error, 3000);
      }
    } else {
      setVisibleCreateBtn(false);
      try {
        const concatIds = `${userData.uid}:${sellerStoreValues.id}`; // Codificar el ID del usuario
        // console.log("concatIds", concatIds);
        // console.log("sellerStoreValues.store_url", sellerStoreValues.store_url);
        const encryptSSid = encrypt3DES(concatIds, sellerStoreValues.store_url);
        const cadenaCodificada = encodeURIComponent(encryptSSid);

        const urlEndpoint = `https://us-central1-shoppex-aa709.cloudfunctions.net/shopify/checkShopifyOrdersConnection/?ssid=${cadenaCodificada}`;
        sellerStoreValues.order_creation_endpoint = urlEndpoint;
        // console.log("urlEndpoint ", urlEndpoint);
        const resultCreateStore = await addToArray("seller_users", userData.uid, "seller_stores", sellerStoreValues);
        const resultCreateStoreDocument = await createDocument(STORESCOLLECTIONNAME, sellerStoreValues.id, sellerStoreValues);
        if (resultCreateStore && resultCreateStoreDocument) {
          toastFunction(toast, "success", t("toast-summary.createStore"), "", 3000);
        }
        setSellerStoreEndpoint(urlEndpoint);
        setVisibleInfoBox(true);
      } catch (error) {
        toastFunction(toast, "error", t("toast-summary.errorUpdateStore"), error, 3000);
      }
    }
    setGlobalLoading(false);
  };

  const handleDeleteSellerStore = async () => {
    setGlobalLoading(true);
    const updatedStores = userData.seller_stores.filter((store) => store.id === sellerStoreValues.id);
    const resultRemoveToArray = await removeFromArray("seller_users", userData.uid, "seller_stores", updatedStores[0]);
    const resultDeleteStore = await deleteDocument(STORESCOLLECTIONNAME, sellerStoreValues.id);

    if (resultRemoveToArray && resultDeleteStore) {
      toastFunction(toast, "success", t("toast-summary.deleteSellerStore"),  "", 3000);
    } else {
      toastFunction(toast, "error", t("toast-summary.errorSellerStore"),  "", 3000);
    }
    setGlobalLoading(false);
    onClose(false);
  };

  const copyValues = async (text) => {
    copyValue(text, toast);
  };

  useEffect(() => {
    const hasEdit = !!Object.keys(selectedSellerStore).length;
    if (hasEdit) {
      setVisibleInfoBox(true);
    }
  }, [userData]);

  return (
    <form onSubmit={handleCRUDStore} className={styles.containerInputs}>
      <CustomInputText
        floatLabel={true}
        type={"text"}
        value={name}
        placeholder={t("input-placeholders.name")}
        onChange={(e) => setName(e.target.value)}
        required={true}
      />
      <CustomSelect
        floatLabel={true}
        optionLabel={"name"}
        options={platforms}
        valueTemplate={""}
        itemTemplate={""}
        value={platform}
        placeholder={t("input-placeholders.platform")}
        onChange={(e) => setPlatform(e.target.value)}
        required={true}
      />

      <CustomInputText
        floatLabel={true}
        type={"text"}
        value={url}
        placeholder={t("input-placeholders.url")}
        onChange={handleShopifyUrl}
        required={true}
      />

      <CustomInputText
        floatLabel={true}
        type={"text"}
        value={tokenProducts}
        placeholder={t("input-placeholders.tokenProducts")}
        onChange={(e) => setTokenProducts(e.target.value)}
        required={true}
      />
      <div>
        <h4 className="text-green-600 cursor-pointer" onClick={() => setVisibleTokenProductsVideo(true)}>
          {" "}
          Ver tutotorial de TOKEN App productos{" "}
        </h4>
      </div>

      <CustomModal
        visible={visibleTokenProductsVideo}
        setVisible={setVisibleTokenProductsVideo}
        object={selectedSellerStore}
        // menu_option={{label:t('menu-options.home')}}
        content={
          <>
            <VideoModal videoUrl={VIDEO_SHOPIFY_PRODUCT_TOKEN_TUTORIAL} />
          </>
        }
        onClose={() => setVisibleTokenProductsVideo(false)}
      />
      {visibleInfoBox && (
        <>
          <InfoBox text={sellerStoreEndpoint} copyCode={() => {}} onClick={() => copyValues(sellerStoreEndpoint)} />
          <h5>Utiliza este webhook para sincronizar automaticamente tus pedidos</h5>
          <h4 className="text-green-600 cursor-pointer" onClick={() => setVisibleWebTokenVideo(true)}>
            Ver tutorial para sincronizar el WEBHOOK
          </h4>
        </>
      )}
      <CustomModal
        visible={visibleWebTokenVideo}
        setVisible={setVisibleWebTokenVideo}
        object={selectedSellerStore}
        // menu_option={{label:t('menu-options.home')}}
        content={<VideoModal videoUrl={VIDEO_SHOPIFY_ORDERS_ENDPOINT_TUTORIAL} />}
        onClose={() => setVisibleWebTokenVideo(false)}
      />

      <div className={styles.containerBtns}>
        {visibleCreateBtn && (
          <MainButton
            type={"onSubmit"}
            label={hasEdit ? t("updateStore") : labelSuccess}
            loading={globalLoading}
            disabled={!visibleCreateBtn}
            autoFocus
          />
        )}

        {hasEdit && (
          <DeleteButton
            type={"button"}
            label={labelDelete}
            loading={globalLoading}
            onClick={() => setVisibleConfirmDialog(true)}
          />
        )}
      </div>
      <div>
        <CustomConfirmDialog
          visible={visibleConfirmDialog}
          onHide={() => setVisibleConfirmDialog(false)}
          header={t("confirmDialog-deleteSellerStore.header")}
          message={t("confirmDialog-deleteSellerStore.message")}
          icon="pi pi-question-circle"
          accept={handleDeleteSellerStore}
          reject={() => setVisibleConfirmDialog(false)}
          acceptLabel={t("confirmDialog-deleteSellerStore.acceptLabel")}
          rejectLabel={t("confirmDialog-deleteSellerStore.rejectLabel")}
        />
      </div>
    </form>
  );
};

DetailShopifyStoreForm.propTypes = {
  selectedSellerStore: PropTypes.object,
  userData: PropTypes.object,
  labelSuccess: PropTypes.string,
  labelDelete: PropTypes.string,
  onClose: PropTypes.func,
  index: PropTypes.number,
  toast: PropTypes.object,
};

export default DetailShopifyStoreForm;
