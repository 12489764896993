import { doc, collection, getDoc, setDoc } from "firebase/firestore";
import { generateRandomId } from "../../../utils/generateRandomId";
import { db } from "../../../firebase/firebase";

// Función para crear o actualizar un documento de stock record
export const upsertStockRecordDocument = async ({
    stockSKU,
    relatedProductId,
    recordDirection,
    recordQty,
    recordDirectionType,
    typeOfDetail,
    valueOfDetail,
    recordNote,
    recordState,
    variationLabel,
}) => {
    try {
        const stockRecordId = generateRandomId();
        console.log("upsertRecordValues ",
            valueOfDetail,
        );

        // Crear referencia al documento de stock record en la colección
        const stockRecordRef = doc(collection(db, 'stock_records'), stockRecordId);
    
        const docSnapshotRecord = await getDoc(stockRecordRef);

    
        // Preparar los datos para actualizar/crear el documento
        const recordUpdateData = {
            ...(stockRecordId && { id: stockRecordId }),
            ...(relatedProductId && { related_product_id: relatedProductId }),
            ...(recordDirection !== undefined && { transaction_direction: recordDirection }),
            ...(recordQty !== undefined && { record_qty: recordQty }),
            ...(recordDirectionType !== undefined && { record_direction_type: recordDirectionType }),
            ...(typeOfDetail !== undefined && { type_of_detail: typeOfDetail }),
            ...(valueOfDetail !== undefined && { value_of_detail: valueOfDetail }),
            ...(recordNote !== undefined && { record_note: recordNote }),
            ...(recordState !== undefined && { record_state: recordState }),
            ...(variationLabel !== undefined && { variation_label: variationLabel }),
        };

        console.log("recordUpdateData", recordUpdateData);

        if (!docSnapshotRecord.exists()) {
            // Crear un nuevo documento si no existe
            await setDoc(stockRecordRef, {
                ...recordUpdateData,
                sku: stockSKU,
                created_date: new Date(),
            });
            console.log('stockRecord document created successfully');
            return { success: true, stockSKU };

        }

    } catch (error) {
        console.error('Error creating/updating stockRecord document: ', error);
        throw error;
    }
};

// Función para generar los registros de stock confirmados
export const generateItemsConfirmStockRecords = async (stockItems) => {
    console.log("entre a generateItemsConfirmStockRecords");
    if (!stockItems || stockItems.length === 0) {
        return;
    }

    const promises = stockItems.map(async (stockItem) => {
        try {
            const resultUpserStockRecord = await upsertStockRecordDocument({
                stockSKU: stockItem.sku,
                relatedProductId: stockItem.product_id,
                recordDirection: 2, // OUT
                recordQty: stockItem.quantity,
                recordDirectionType: 7, // salida_reserva_producto. Aumenta Reservado y Disminuye Disponible.
                typeOfDetail: "Orden confirmada",
                valueOfDetail: stockItem.product_title,
                recordNote: "Nota sobre el movimiento",
                recordState: "Completado",
                variationLabel: stockItem?.sku_title,
            });
            console.log("resultUpserStockRecord", resultUpserStockRecord);
        } catch (error) {
            console.error(`Error processing stockItem with SKU: ${stockItem.sku}`, error);
        }
    });

    await Promise.all(promises); // Espera a que todas las promesas se resuelvan
};

// Función para generar los registros de stock no confirmados
export const generateItemsDisconfirmStockRecords = async (stockItems, typeOfDetail) => {
    console.log("entre a generateItemsDisconfirmStockRecords");
    if (!stockItems || stockItems.length === 0) {
        return;
    }

    const promises = stockItems.map(async (stockItem) => {
        try {
            const resultUpserStockRecord = await upsertStockRecordDocument({
                stockSKU: stockItem.sku,
                relatedProductId: stockItem.product_id,
                recordDirection: 2, // OUT
                recordQty: stockItem.quantity,
                recordDirectionType: 10, // Ingreso_por_cancelacion. Disminuye Reservado y Aumenta Disponible.
                typeOfDetail: typeOfDetail,
                valueOfDetail: stockItem.product_title,
                recordNote: "Nota sobre el movimiento",
                recordState: "Completado",
                variationLabel: stockItem?.sku_title,
            });
            console.log("resultUpserStockRecord", resultUpserStockRecord);
        } catch (error) {
            console.error(`Error processing stockItem with SKU: ${stockItem.sku}`, error);
        }
    });

    await Promise.all(promises); // Espera a que todas las promesas se resuelvan
};

// Función para generar los registros de stock por cada balance
export const generateStockUpdates = async (stockToUpdate) => {
    console.log("entre a generateStockUpdates", stockToUpdate);
    
    if (!stockToUpdate || stockToUpdate.length === 0) {
      console.log("No hay stock para actualizar");
      return;
    }

    const promises = stockToUpdate.map(async (stockItem) => {
      
      const balances = {
        balance_disponible: stockItem.balance_disponible,
        balance_en_transito: stockItem.balance_en_transito,
        balance_reservado: stockItem.balance_reservado
      };


      for (const [key, balance] of Object.entries(balances)) {
        if (balance !== 0 && balance !== null && balance !== undefined && typeof balance === 'number') {
          const recordDirection = balance < 0 ? 2 : 1;
          let recordDirectionType;

          switch (key) {
            case "balance_disponible":
              recordDirectionType = 11;
              break;
            case "balance_en_transito":
              recordDirectionType = 12;
              break;
            case "balance_reservado":
              recordDirectionType = 13;
              break;
            default:
              break;
          }

          const recordQty = balance;

          try {
            const resultUpsertStockRecord = await upsertStockRecordDocument({
              stockSKU: stockItem.sku,
              relatedProductId: stockItem.related_product_id,
              recordDirection,
              recordQty,
              recordDirectionType,
              typeOfDetail: "Ajuste manual",
              valueOfDetail: stockItem.variation_label,
              recordNote: `Movimiento de ${key}`,
              recordState: "Completado",
            });

            console.log(`Registro de stock generado para SKU: ${stockItem.sku} en ${key}`, resultUpsertStockRecord,  stockItem);
          } catch (error) {
            console.error(`Error procesando SKU: ${stockItem.sku} en ${key}`, error);
          }
        }
      }
    });

    await Promise.all(promises);
};

// Función para generar los registros de stock por cada balance
export const generateStockUpdatesByExcelData = async (data) => {
    const {
        selectedRecordType,
        excelTableData,
        selectedProduct
    } = data;
    console.log("entre a generateStockUpdatesByExcelData", data);
    
    if (!excelTableData || excelTableData.length === 0) {
      console.log("No hay stock para actualizar");
      return;
    }
    let failedSkus = [];

    const promises = excelTableData.map(async (stockItem) => {
       
      const recordQty = Math.abs(stockItem.qty);
      const stockSKU = stockItem.sku;
      const findedVariation = selectedProduct.variations.find((variation) => variation.sku === stockSKU)
      const findedVariationLabel = findedVariation?.variation_label || "El sku ya no se encuentra en el producto"
      console.log("stockItem y cantidad", stockItem, recordQty)


      const stockRef = doc(collection(db, 'stock'), stockSKU);
      const docSnapshotStock = await getDoc(stockRef);
      
    if (!docSnapshotStock.exists()) {
        console.log(`SKU no encontrado: ${stockSKU}`);
        failedSkus.push({sku: stockSKU, qty: stockItem.qty});
        return
    }
    if (recordQty !== 0 || recordQty !== null || recordQty !== undefined) {
        let recordDirection;  //  Dirección de la transacción. CODIFICACION: 1: "IN", 2: "OUT”
        const recordTypeCode = selectedRecordType.code
        switch (recordTypeCode) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
                recordDirection = 1;
                break;
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
                recordDirection = 2;
                break;
            default:
                console.error("Tipo de transaccion de stock no definido")
                break;
        }
        console.log("validacion", findedVariation, stockSKU)
       
        try {
            const resultUpsertStockRecord = await upsertStockRecordDocument({
                stockSKU,
                relatedProductId: selectedProduct.id,
                recordDirection,
                recordQty,
                recordDirectionType: recordTypeCode,
                typeOfDetail: "Ajuste manual con Excel",
                valueOfDetail: findedVariationLabel,
                recordNote: `Movimiento de ${selectedRecordType.name}`,
                recordState: "Completado",
                });

        console.log(`Registro de stock generado para SKU: ${stockItem.sku} en ${findedVariationLabel}`, resultUpsertStockRecord,  stockItem);
        } catch (error) {
            console.error(`Error procesando SKU: ${stockItem.sku} en ${findedVariationLabel}`, error);
        }
    }
    });

    await Promise.all(promises);
    return failedSkus;
};

  
