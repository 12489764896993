import styles from './UserProfileFooter.module.css'; // Asegúrate de ajustar la ruta del archivo CSS

const UserProfile = () => {
  return (
    <div className={styles.profile}>
      <img
        src={"https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg"}
        alt="Foto de perfil"
        className={styles.profileImage}
      />
      <div>
        <p className={styles.profileName}>Samuel</p>
        <p className={styles.logout}>Cerrar sesión</p>
      </div>
    </div>
  );
};

export default UserProfile;
