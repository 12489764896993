import { useTranslation } from "react-i18next";
import MainButton from "../../Components/MainButton/MainButton";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import CHECK  from "../../assets/LOTTIE/CHECK.json"
import LottieAnimation from "../../Components/LottieAnimation/LottieAnimation";
import styles from "./ThankyouPage.module.css"
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";


export default function ThankyouPage() {
  const { waUrlHook, whatsappPhone } = useGlobalContext();
  const { getDocumentsByQuery } = useFirebaseCRUD();

    const [searchParams] = useSearchParams();
    const catalogId = searchParams.get('catalogId');
    const checkoutId = searchParams.get('checkoutId');

    // console.log("catalogId", catalogId);
    // console.log("checkoutId", checkoutId);

    const { t } = useTranslation("Catalog");
    const { globalLoading, setGlobalLoading} = useLoaderContext();

    const [ checkoutOrders, setCheckoutOrders ] = useState();
    const [ contactNumber, setContactNumber ] = useState();




    const askForSupport = async () => {
        const message = `Hola necesito ayuda con mi pedido. checkout ID: *${checkoutId}*`;
        waUrlHook("withPhone", contactNumber, message);
    }


    const fetchCatalogData = async () => {
      let contactWaNumber = null;
      const qMyCatalog = query(collection(db, 'catalogs'), where('id', '==', catalogId));

      try {
          const catalogDocuments = await getDocumentsByQuery(qMyCatalog);
          const catalogData = catalogDocuments[0];
          if(catalogData) {
            if(catalogData?.contact_number && catalogData?.contact_number !== "") {
              contactWaNumber = catalogData.contact_number;
            }else {
              const qSeller = query(
                  collection(db, 'seller_users'),
                  where('uid', '==', catalogData?.user_id),
                  where('business_id', '==', catalogData?.business_id),
                );
              const sellerDocuments = await getDocumentsByQuery(qSeller);
              const sellerData = sellerDocuments[0];
              if(sellerData && sellerData?.full_number) {
                contactWaNumber = sellerData?.full_number;
              } else {
                contactWaNumber = null;
              }
            }
            setContactNumber(contactWaNumber);
          }
      } catch (error) {
          console.error("Error al obtener datos del vendedor:", error);
      }
  };


  
  const fetchData = async () => {
    setGlobalLoading(true);
    try {
        await fetchCatalogData();
    } catch (error) {
        console.log("catalog", contactNumber)
    } finally {
        setGlobalLoading(false);
    }
};
    useEffect(() => {
        let unsubscribeCatalog = null;
        // Solo activa la suscripción si la ruta es '/thankyouPage'
        if (window.location.pathname.startsWith('/thankyouPage')) {
            setGlobalLoading(true);
            const qMyCatalog = query(collection(db, 'orders'), where('checkout_id', '==', checkoutId));

            // Obtener datos del catálogo
            unsubscribeCatalog = onSnapshot(qMyCatalog, (querySnapshot) => {
                if (!querySnapshot.empty) {
                    // Nota: querySnapshot.docs es una lista de documentos, no un solo documento
                    const catalogDocs = querySnapshot.docs.map(doc => doc.data());
                    setCheckoutOrders(catalogDocs);
                } else {
                    console.log("No se encontraron pedidos para checkoutId:", checkoutId);
                }
                setGlobalLoading(false);
            }, (error) => {
                console.error("Error al obtener los pedidos:", error);
                setGlobalLoading(false);
            });
        } else {
            console.log("No estoy en la ruta /thankyouPage");
        }

      fetchData();
        // Limpieza cuando el componente se desmonta o la ubicación cambia
        return () => {
            if (unsubscribeCatalog) {
                console.log("Unsubscribing from catalog data...");
                unsubscribeCatalog(); // Cancela la suscripción del catálogo
            }
        };
    }, [checkoutId]);

    // console.log(checkoutOrders)

    return (
        <div className="flex flex-col justify-between max-w-lg w-full mx-auto mb-8">
            <div className=" ">
                <div className="flex justify-center ">
                    <LottieAnimation animationData={CHECK}  />
                    <h4 className={styles.succefullPurchase}>Gracias por tu compra</h4>
                </div>
                
            </div>
           { checkoutOrders?.length > 0 && ( <main>
            <div className="mt-4 bg-white opacity-75 p-6 rounded-lg shadow-md grid grid-cols-2 gap-4 items-center text-sm">
              <p className="font-bold" >Dirección:</p>
              <p>{checkoutOrders[0]?.customer_shipping_address} </p> 
              
              <p className="font-bold">Ciudad:</p>
              <p>{checkoutOrders[0]?.shipping_destination?.city_name} </p> 
              
              <p className="font-bold">Departamento:</p>
              <p>{checkoutOrders[0]?.shipping_destination?.province_name} </p> 
              
              <p className="font-bold ">Nombre cliente:</p>
              <p>{checkoutOrders[0]?.customer_full_name} </p> 
              
              <p className="font-bold">Teléfono:</p>
              <p>{checkoutOrders[0]?.customer_phone_number}</p> 
              
              {checkoutOrders[0]?.contact_email && checkoutOrders[0]?.contact_email !== "" && (
                <>
                  <p className="font-bold">Email:</p>
                  <p>{checkoutOrders[0]?.contact_email} </p> 
                </>
              )}
            </div>


              <div className=" mt-4 bg-gray-100">
                <OrdenesList ordenes={checkoutOrders} />
              </div>
             {contactNumber && ( <div className="mt-12">
                <MainButton
                    type={"button"}
                    label={t("¿Necesitas ayuda con tu pedido?")}
                    onClick={askForSupport}
                    autoFocus
                    disabled={globalLoading}
                    loading={globalLoading}
                />
              </div>)}
            </main>)}
               
        </div>
    );
}



  export const OrdenesList = ({ ordenes }) => {
    console.log(ordenes);
    // Assuming total_order_price already includes shipping
    const subtotal = ordenes?.reduce((acc, orden) => {
      const shippingCost = orden?.free_shippings 
        ? 0 
        : (orden?.shipping_quote_cost?.price || 0) + (orden?.shipping_quote_cost?.servicePayment || 0);
      
      return acc + ((orden?.total_order_price || 0) - shippingCost);
    }, 0);
    
    const totalShipping = ordenes?.reduce((acc, orden) => {
      // Solo sumar los costos de envío si free_shippings es falso
      if (!orden?.free_shippings) {
        return acc + (orden?.shipping_quote_cost?.price || 0) + (orden?.shipping_quote_cost?.servicePayment || 0);
      }
      return acc;
    }, 0);
    
    
    // Total amount to be paid
    const total = subtotal + totalShipping;
  
    return (
      <div className="bg-gray-50 p-6 rounded-lg shadow-md ">
        {ordenes?.map((orden) => (
          <OrdenCard key={orden.id} orden={orden} />
        ))}
        <div className="mt-4 text-sm">
          <div className="flex justify-between">
            <span>Subtotal:</span>
            <span>{subtotal?.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</span>
          </div>
          <div className="flex justify-between">
            <span>Envíos ({ordenes?.length}):</span>
            <span>{totalShipping?.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</span>
          </div>
          <div className="flex justify-between font-semibold">
            <span>Total a pagar:</span>
            <span>{total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</span>
          </div>
        </div>
      </div>
    );
  };

  export const OrdenCard = ({ orden }) => {
    // console.log("orden", orden)
    return (
      <div className="bg-white rounded-lg shadow-md p-4 mb-4">
        <div className="flex items-center mb-2">
          <div className="flex flex-col">
            {orden?.line_items?.map((item) => (
                <div key={item.id} className="flex gap-2">
                    <div className="min-w-16 h-16 mt-2 mb-2 bg-gray-200 rounded-2xl mr-3">
                      <img
                        src={item?.sku_img || null}
                        className="w-full h-full object-cover rounded-2xl"
                        alt=""
                      />
                    </div>

                    <div className="grid p-1  justify-center items-center">
                        <span className="text-sm font-medium ">{item.product_title}</span>
                        <span className="text-sm text-gray-600">{item.sku_title}</span>
                        <span className="text-sm font-semibold">{(item.unit_price).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</span>
                        <span className="flex items-center">Cantidad: &nbsp; <span  className="text-sm font-semibold">{item.quantity}</span></span>
                        </div>

                </div>
            ))}
           
          </div>
        </div>
        <div className="text-sm text-gray-600 mb-2">Numero de orden: {orden?.order_number}</div>

        <div className="text-sm text-gray-600 mb-2">
            Envío: { 
              orden?.free_shippings 
                ? 'Gratis ' 
                : ((orden?.shipping_quote_cost?.price || 0) + (orden?.shipping_quote_cost?.servicePayment || 0))
                    .toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) 
            }
          </div>
      </div>
    );
  };
  