import { GoDeviceCameraVideo } from 'react-icons/go';
import { IoCallOutline, IoChevronBackOutline } from 'react-icons/io5';
import { SlOptions } from 'react-icons/sl';
import styles from './ChatHeader.module.css'; // Asegúrate de ajustar la ruta del archivo CSS
import { MESSAGESLIST } from '../../utils/CRMconstants';

const ChatHeader = ({onSelectMenuOption}) => {


  return (
    <div className={styles.chatHeader}>
        <button onClick={() => onSelectMenuOption(MESSAGESLIST)}className={styles.goBackBtnIcon}>
          <IoChevronBackOutline  />
        </button>
      <div className={styles.chatContactInfo}>
        <div className={styles.contactAvatar}>
          <img
            src="https://randomuser.me/api/portraits/lego/1.jpg"
            alt="contact"
          />
        </div>
        <div className={styles.contactInfo}>
          <p className={styles.chatTitle}>Cliente 1</p>
          <p className={styles.chatStatus}>escribiendo</p>
        </div>
      </div>

      <div className={styles.chatHeaderBtns}>
        <button>
          <GoDeviceCameraVideo className={styles.icon} />
        </button>
        <button>
          <IoCallOutline className={styles.icon} />
        </button>
        <button>
          <SlOptions className={styles.icon} />
        </button>
      </div>
    </div>
  );
};

export default ChatHeader;
