import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styles from "./DetailProductItemForm.module.css";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import { useEffect, useState } from "react";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import DeleteButton from "../DeleteButton/DeleteButton";
import MainButton from "../MainButton/MainButton";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog";
import { generateRandomId } from "../../utils/generateRandomId";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import CustomInputNumber from "../FormComponents/CustomInputNumber/CustomInputNumber";
import { getPaginatedProducts } from "../../utils/getPaginatedProducts";
import { arrayRemove, arrayUnion, collection, increment, query, where } from "firebase/firestore";
import toastFunction from "../../utils/toastFunction";
import formatCurrency from "../../utils/formatCurrency";
import { db } from "../../firebase/firebase.js";

// Componente de formulario que se muestra al seleccionar un item de la tabla del OverLayPanel, muestra el detalle del Producto que formará el Item para la Orden
const DetailProductItemForm = ({
  filteredSellerStores,
  productItemData,
  toast,
  onClose,
  selectedOrderRowData,
  orders,
  setSelectedOrderRowData,
}) => {
  const hasEdit = !!Object.keys(productItemData).length;
  const { t } = useTranslation("MyOrders");
  const {
    addToArray,
    updateArray,
    removeFromArray,
    updateDocument,
    getDocumentsByQuery,
    getAllDocumentsFromSubcollection
  } = useFirebaseCRUD();
  const { globalLoading, setGlobalLoading } = useLoaderContext();

  const [itemProducts, setItemProducts] = useState([]);
  const [itemProduct, setItemProduct] = useState(productItemData || null);
  const [productVariations, setProductVariations] = useState();
  const [productVariation, setProductVariation] = useState([]);
  const [productQuantity, setProductQuantity] = useState( productItemData?.quantity || 1 );
  const [unitaryProductPrice, setUnitaryProductPrice] = useState(  productItemData?.unitary_item_price || null);
  const [shopifySyncedProductName, setShopifySyncedProductName] = useState(null);
  const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
  const [hasProductSelect, setHasProductSelect] = useState(false);
  const [mainImg, setMainImg] = useState(null)

  const [syncedProducts, setSyncedProducts] = useState([]);
  // console.log("itemProduct", itemProduct)

  const itemProductValues = {
    id: productItemData?.id || generateRandomId(),
    is_downsell: null,
    is_crossell: null,
    is_upsell: null,
    is_subscription: null,
    item_is_confirmed: null,
    name: itemProduct?.product_name || productItemData?.name,
    product_id: itemProduct?.id || productItemData?.product_id,
    // ** REVISAR
    product_title: itemProduct?.product_name || productItemData?.product_title,
    // **
    quantity: productQuantity,
    related_order_id: selectedOrderRowData?.id, 
    related_seller_id: productItemData?.related_seller_id|| null,
    related_commercial_partner_id: itemProduct?.commercial_partner_id || productItemData?.related_commercial_partner_id,
    related_commercial_partner_label: itemProduct?.commercial_partner_label || productItemData?.related_commercial_partner_label ||null,
    synced_product_id: productItemData?.synced_product_id || 0,
    sku: productVariation?.sku || productItemData?.sku,
    sku_title: productVariation?.variation_label || productItemData?.sku_title,
    sku_img:  mainImg,

    total_item_price: unitaryProductPrice * productQuantity ,
    unit_discount: productItemData?.unit_discount || 0,
    // ** REVISAR
    unit_price: unitaryProductPrice,
    // **
    unitary_commercial_partner_VAT:
      itemProduct?.commercial_partner_VAT_value ||
      productItemData?.unitary_commercial_partner_VAT ||
      0,
    unitary_commercial_partner_charge_without_VAT:
      itemProduct?.commercial_partner_price_without_VAT ||
      productItemData.unitary_commercial_partner_charge_without_VAT,
    unitary_intermediary_charge:
      itemProduct?.service_fee ||
      productItemData?.unitary_intermediary_charge ||
      0,
    unitary_item_price: productItemData?.unitary_item_price || unitaryProductPrice,
    synced_product_names: itemProduct?.synced_product_names || {
      shopify_product_name: shopifySyncedProductName || `NO VINCULADO`,
      shoppex_product_name: itemProduct?.product_name
    },
    product_success_match: true,
    variation_id:  productVariation?.sku || productItemData?.sku,

  };

// Función para obtener la cantidad de productos de las tiendas de un vendedor
const amountProductsOfSellerStores = async (filteredSellerStores) => {
  if (!filteredSellerStores) return [];
  console.log(filteredSellerStores)
  const filteredShoppexStore = filteredSellerStores.filter((store) => store?.platform?.code === 1 );
  // Utiliza un conjunto para asegurar que no haya duplicados en los IDs de productos sincronizados que luego se usarán en el fetch
  const syncedProductsIds = new Set();
  const syncedProductsData = [];


  // Usamos for...of para iterar de manera síncrona sobre las tiendas
  for (const store of filteredShoppexStore) {
    try {
      // Esperamos a obtener los documentos de la subcolección 'related_products'
      const productsFromSubcollections = await getAllDocumentsFromSubcollection("stores", store.id, "related_products");

      if (productsFromSubcollections && productsFromSubcollections.length > 0) {
        productsFromSubcollections.forEach((item) => {
          if (item?.product_id) {
            syncedProductsIds.add(item.product_id);
            syncedProductsData.push(item);
          }
        });
      } else {
        console.log(`No se encontraron productos relacionados para la tienda ${store.id}`);
      }
    } catch (error) {
      console.error(`Error al obtener productos para la tienda ${store.id}:`, error);
    }
  }

  console.log("syncedProductsIds", syncedProductsIds);
  setSyncedProducts(syncedProductsData);
  // Devuelve un objeto con los IDs únicos de los productos sincronizados
  return { syncedProductsIds: Array.from(syncedProductsIds) };
};


  // Función para obtener productos por IDs Desde la base de datos de 10X Booster Commercial Partners
  const getProductsFromIds = async (syncedProductsIds, type) => {
    setGlobalLoading(true);
    const body = {
      productsIds: syncedProductsIds,
      quantityDocuments: 20,
      type: type,
    };
    try {
      const response = await getPaginatedProducts(body);
      if (!response) {
        throw new Error("Error al obtener los productos");
      }
      setGlobalLoading(false);
      return response;
    } catch (error) {
      console.error("Error al obtener los productos:", error);
      setGlobalLoading(false);
      return false;
    }
  };

  // Función para manejar la selección de un producto
  const handleItemProduct = (product) => {
    console.log("product", product, selectedOrderRowData)
    setItemProduct(product);
    setUnitaryProductPrice(product.suggested_retail_price);
    setProductVariations(product.variations);
    setHasProductSelect(true);
    let shopifyProductName;
      // Buscamos en los productos vinculados el id del prdocuto para
      const syncedProduct = syncedProducts?.find((syncedProduct) => 
        ( syncedProduct.product_id === product.id && syncedProduct.store_id === selectedOrderRowData.store_id));
  
      if (syncedProduct) {
        shopifyProductName = syncedProduct?.custom_title;
        setShopifySyncedProductName(shopifyProductName)
      }
  
  };

  const handleItemVariations = (variation) => {
    setProductVariation(variation);
    let mainSkuImg;
    // Encontrar la imagen principal en la variación
    const mainSkuImgObject = variation.images?.find((img) => img.is_main_image);
  
    // Verificar si hay una imagen principal en las variaciones de la imagen principal
    if (mainSkuImgObject && mainSkuImgObject?.variations?.images?.length > 0) {
      mainSkuImg = mainSkuImgObject.variations.images.find((img) => img.is_main_image);
    } else {
      // Si no se encuentra, tomar la imagen principal del producto
      mainSkuImg = itemProduct?.main_img?.url || null;
    }
    setMainImg(mainSkuImg)
    itemProductValues.sku_img = mainSkuImg;
  };
  
  // Función para manejar la adición de un item a line_items
  const handleAddItem = async (e) => {
    e.preventDefault();
    if(unitaryProductPrice < itemProduct?.commercial_partner_price_with_VAT) {
      toastFunction(toast, "warn", "No puedes asignarle un valor inferior al dispuesto por el vendedor", "", 3000);
      return 
    }
    if (hasEdit) {
      // Obtiene el artículo seleccionado actualmente para editar
      const selectedProductItem = selectedOrderRowData?.line_items.find(
        (item) => {
          return item?.id === itemProductValues?.id;
        }
      );
      // Actualiza el array en la base de datos con el nuevo valor del item
      const resultUpdateArray = await updateArray( "orders", selectedOrderRowData.id, "line_items", selectedProductItem, itemProductValues);
      // Actualiza la lista de artículos en la fila seleccionada
      const filteredOrderItems = selectedOrderRowData?.line_items?.filter(
        (item) => {
          return item?.id !== itemProductValues?.id;
        }
      );
      filteredOrderItems.push(itemProductValues);
      const newSelectedRowData = {
        ...selectedOrderRowData,
        line_items: filteredOrderItems,
      };
    
      const valuesToRemove = {
        line_items_products_ids: arrayRemove(selectedProductItem.product_id),
        line_items_skus: arrayRemove(selectedProductItem.sku),
      };
      const valuesToUpdate = {
        ...newSelectedRowData,
        total_order_price: increment(itemProductValues.total_item_price - selectedProductItem.total_item_price),
        line_items_products_ids: arrayUnion(itemProductValues.product_id),
        line_items_skus: arrayUnion(itemProductValues.sku),
      };
      const resultUpdateDocumentRemove = await updateDocument("orders", selectedOrderRowData.id, valuesToRemove)
      const resultUpdateDocument = await updateDocument("orders", selectedOrderRowData.id, valuesToUpdate)
      setSelectedOrderRowData(newSelectedRowData);
      if (resultUpdateArray && resultUpdateDocument && resultUpdateDocumentRemove) {
        toastFunction( "success", t("toast-summary.orderUpdateSuccess"),  "", 3000);
      } else {
        toastFunction( "error", t("toast-summary.orderUpdateError"),  "", 3000);
      }
      onClose();
    } else {
      // Si no se está editando, añade un nuevo artículo a la orden
      const resultUnion = await addToArray("orders", selectedOrderRowData.id, "line_items", itemProductValues);

      // Actualiza la lista de artículos en la fila seleccionada
      const newLineItems = [
        ...selectedOrderRowData.line_items,
        itemProductValues,
      ];
      const newSelectedRowData = {
        ...selectedOrderRowData,
        line_items: newLineItems,
      };
      const valuesToUpdate = {
        ...newSelectedRowData,
        total_order_price: increment(itemProductValues.total_item_price),
        line_items_products_ids: arrayUnion(itemProductValues.product_id),
        line_items_skus: arrayUnion(itemProductValues.sku),
        related_suppliers_ids: arrayUnion(itemProductValues.related_commercial_partner_id),
      };
      const resultUpdateDocument = await updateDocument("orders", selectedOrderRowData.id, valuesToUpdate)

      if (resultUnion && resultUpdateDocument) {
        setSelectedOrderRowData(newSelectedRowData);
        toastFunction( "success", t("toast-summary.orderUnionSuccess"),  "", 3000);
      } else {
        toastFunction( "error", t("toast-summary.orderUnionError"),  "", 3000);
      }
      onClose();
    }
  };
  // Función para manejar la eliminación de un artículo
  const handleDeleteProductItem = async () => {
    // Obtiene el item seleccionado actualmente para eliminar
    const selectedProductItem = selectedOrderRowData.line_items.find((item) => {
      return item?.id === itemProductValues?.id;
    });
    // Filtra la lista de items para excluir el item seleccionado
    const filteredOrderItems = selectedOrderRowData.line_items.filter(
      (item) => {
        return item.id !== itemProductValues.id;
      }
    );
    const filteredNoSyncedItems = selectedOrderRowData.no_synced_items_ids.filter(
      (item) => {
        return item !== selectedProductItem.id;
    })
    console.log("filteredNoSyncedItems", filteredNoSyncedItems, filteredNoSyncedItems.length);
    let orderWithNoSyncedItems = true;
    if (filteredNoSyncedItems.length === 0){
      orderWithNoSyncedItems = false;
    }

    // Actualiza la lista de artículos en la fila seleccionada

    const newSelectedRowData = {
      ...selectedOrderRowData,
      line_items: filteredOrderItems,
    };
    // Elimina el item de line_items y la base de datos
    
    const resultRemoveItem = await removeFromArray("orders", selectedOrderRowData.id, "line_items", selectedProductItem );
  
    if (resultRemoveItem) {
      let valuesToRemove = {
        total_order_price: increment(-selectedProductItem.total_item_price),
        line_items_products_ids: arrayRemove(selectedProductItem.product_id),
        line_items_skus: arrayRemove(selectedProductItem.sku),
        related_suppliers_ids: arrayRemove(selectedProductItem.related_commercial_partner_id),
        order_with_no_synced_items: orderWithNoSyncedItems,
        no_synced_items_ids: arrayRemove(selectedProductItem.id),
      };
      const hasItemWithBusinessId = newSelectedRowData.line_items.some((item) => {
        return selectedOrderRowData.related_suppliers_ids.includes(item.related_commercial_partner_id);
      });

      if (hasItemWithBusinessId) {
        valuesToRemove = {
          total_order_price: increment(-selectedProductItem.total_item_price),
          line_items_products_ids: arrayRemove(selectedProductItem.product_id),
          line_items_skus: arrayRemove(selectedProductItem.sku),
          order_with_no_synced_items: orderWithNoSyncedItems,
        };
      }
      const resultUpdateDocument = await updateDocument("orders", selectedOrderRowData.id, valuesToRemove);
      console.log("hay por lo menos un item con el ID de business", hasItemWithBusinessId);
      console.log("Resultado actualizacion", resultUpdateDocument);
      setSelectedOrderRowData(newSelectedRowData);
      toastFunction(toast, "success", t("toast-summary.deleteItemSuccess"),  "", 2000,);
    } else {
      toastFunction(toast, "error", t("toast-summary.deleteItemError"),  "", 4000,);
    }
    onClose();
  };

  const fetchData = async () => {
    setGlobalLoading(true);

    let products = [];
    // console.log("validacion useffects", selectedOrderRowData?.store_default, selectedOrderRowData)
    // Si la orden Seleccionada proviene de una tienda default, obtenemos todos los productos de 10x para poder ehacer adiciones o ediciones
    if( selectedOrderRowData?.store_default) {
      const productsCol = collection(db, "products");
      const q = query(productsCol, 
        where("product_is_active", "==", true),
        where("show_product_to_other_sellers", "==", true),
        )
      products = await getDocumentsByQuery(q);
      // Filtramos los productos que tengan la misma direccion de despacho de la orden para evitar multiples origenes
  
    } else {  // Si no entonces buscamos los productos apartir de las tiendas y productos vinculados
        const { syncedProductsIds } = await amountProductsOfSellerStores(filteredSellerStores);
        // Obtiene los productos correspondientes a los IDs sincronizados
        products = await getProductsFromIds(syncedProductsIds, "onlyProducts");
    }

    if (hasEdit) {  // Si estamos Editando buscamos el producto y la variacion
          // Busca el producto seleccionado en los productos obtenidos
       const  selectedProductItem = products.find((fetchProduct) => {
          return fetchProduct.id === productItemData.product_id;
        });
        // Busca la variación del producto seleccionado
        const selectedProductItemVariation =
          selectedProductItem?.variations.find((variation) => {
            return variation?.sku === productItemData?.sku;
          });
          setItemProduct(selectedProductItem);
          setProductVariations(selectedProductItem?.variations);
          setProductVariation(selectedProductItemVariation);
          setProductQuantity(productItemData.quantity);
          setHasProductSelect(true);
    }
    console.log("products", products)

    
    // filtramos los productos que tienen el mismo origen
    const filteredProducts = products.filter((product) => product?.related_shipping_address_id === selectedOrderRowData?.related_seller_shipping_dispatch_address_id)
    if (filteredProducts.length === 0 &&  !selectedOrderRowData?.store_default) {
      toastFunction(toast, "warn", "No hay productos vinculados que tengan el mismo origen")
    }
    setItemProducts(filteredProducts);
    setGlobalLoading(false);

  };

  useEffect(function loadIntialData() {
    // Función asíncrona para realizar la lógica de la carga de datos
    // Llama a la función fetchData al montar el componente o cuando la dependencia 'orders' cambia
    fetchData();
  }, [orders]);

  return (
    <form onSubmit={handleAddItem} className={styles.containerInputs}>
      <CustomSelect
        floatLabel={true}
        optionLabel={"product_name"}
        options={itemProducts}
        value={itemProduct}
        placeholder={t("input-placeholders.productName")}
        onChange={(e) => handleItemProduct(e.target.value)}
        required={true}
      />
      <CustomSelect
        floatLabel={true}
        optionLabel={"variation_label"}
        options={productVariations}
        value={productVariation}
        placeholder={t("input-placeholders.productVariation")}
        onChange={(e) => handleItemVariations(e.target.value)}
        disabled={!hasProductSelect}
        required={true}
      />
      <CustomInputNumber
        floatLabel={true}
        value={productQuantity}
        min={0}
        max={100}
        placeholder={t("input-placeholders.productQuantity")}
        onChange={(e) => setProductQuantity(e.value)}
        disabled={!hasProductSelect}
      />
      <CustomInputNumber
        value={unitaryProductPrice}
        floatLabel={true}
        min={0}
        onChange={(e) => setUnitaryProductPrice(e.value)}
        placeholder={t("input-placeholders.unitProductPrice")}
        className="p-button-rounded p-button-outlined"
        disabled={!hasProductSelect}

      />
     { itemProduct && (
       <div>
        <h5 className="mt-5">Precio minimo del vendedor : {formatCurrency(itemProduct?.commercial_partner_price_with_VAT) }</h5>
        <h5>Precio sugerido por el vendedor : {formatCurrency(itemProduct?.suggested_retail_price)}</h5>
       </div>
      )}
      <div className={styles.containerBtns}>
        {hasEdit && (
          <DeleteButton
            type={"button"}
            label={t("labelDelete")}
            loading={globalLoading}
            onClick={() => setVisibleConfirmDialog(true)}
          />
        )}
        <MainButton
          type={"onSubmit"}
          label={hasEdit ? t("updateItem") : t("labelSuccess")}
          loading={globalLoading}
          disabled={unitaryProductPrice === 0 || productQuantity === 0}
          autoFocus
        />
      </div>
      <div>
        <CustomConfirmDialog
          visible={visibleConfirmDialog}
          onHide={() => setVisibleConfirmDialog(false)}
          header={t("confirmDialog-deleteItem.header")}
          message={t("confirmDialog-deleteItem.message")}
          icon="pi pi-question-circle"
          accept={(e) => handleDeleteProductItem(e)}
          reject={() => setVisibleConfirmDialog(false)}
          acceptLabel={t("confirmDialog-deleteItem.acceptLabel")}
          rejectLabel={t("confirmDialog-deleteItem.rejectLabel")}
        />
      </div>
    </form>
  );
};
DetailProductItemForm.propTypes = {
  filteredSellerStores: PropTypes.array,
  productItemData: PropTypes.object,
  toast: PropTypes.object,
  onClose: PropTypes.func,
  selectedOrderRowData: PropTypes.object,
  setSelectedOrderRowData: PropTypes.func,
  orders: PropTypes.array,
};
export default DetailProductItemForm;
