import  styles  from "./Inventary.module.css"
import { useTranslation } from "react-i18next";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";

import CustomTable from "../../Components/CustomTable/CustomTable";
import { BiCaretDown, BiCaretUp } from "react-icons/bi";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext";
import { collection, onSnapshot,  query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import CustomSelect from "../../Components/FormComponents/CustomSelect/CustomSelect";
import { ToggleButton } from "primereact/togglebutton";
import toastFunction from "../../utils/toastFunction";
import useAuth from "../../hooks/useAuth";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { generateStockUpdates } from "../MyOrders/scripts/stockRecord";
import MainButton from "../../Components/MainButton/MainButton";
import CustomModal from "../../Components/CustomModal/CustomModal";
import DetailUpdateStockExcelTable from "../../Components/DetailUpdateStockExcelTable/DetailUpdateStockExcelTable.jsx"
import CustomConfirmDialog from "../../Components/OverlayComponents/CustomConfirmDialog/CustomConfirmDialog.jsx";
import { InputNumber } from "primereact/inputnumber";

export default function Inventary() {
    const { t } = useTranslation("Inventary");
    const { globalLoading, setGlobalLoading} = useLoaderContext();
    const { toast } = useAuth();

    const { userData, userSellerOwnerData, isCoworker, isInsider } = useAuthContext();
    const [productos, setProductos] = useState();
    // eslint-disable-next-line no-unused-vars
    const [unsubscribeProducts, setUnsubscribeProducts] = useState();
    const [unsubscribeStockItems, setUnsubscribeStockItems] = useState();
    const [unsubscribeStockRecords, setUnsubscribeStockRecords] = useState();
    const [selectedProduct, setSelectedProduct] = useState();

    const [stockItems, setStockItems] = useState();
    const [stockItemsCopy, setStockItemsCopy] = useState([]);

    const [stockRecords, setStockRecords] = useState();
    const [selectedInventaryMode, setSelectedInventaryMode] = useState();

    const [visibleExcelTableModal, setVisibleExcelTableModal] = useState(false);
    const [visibleConfirmUpdateStockDialog, setVisibleConfirmUpdateStockDialog ] = useState(false);


    let userOwnerData;
      
    // Determinar si el usuario es "coworker" o "insider"
    if ((isCoworker && userSellerOwnerData) || (isInsider && userSellerOwnerData)) {
      userOwnerData = userSellerOwnerData;
    } else {
      userOwnerData = userData;
    }
    
    const decodifiedStockRecordDirectionField = (cellValueCodified, cellField) => {
    const recordTypes = {
      "1": t("recordTypes.ingreso_ajuste_positivo"),
      "2": t("recordTypes.ingreso_futura_recepcion_producto"),
      "3": t("recordTypes.ingreso_recepcion_producto"),
      "4": t("recordTypes.ingreso_producto_devuelto"),
      "5": t("recordTypes.ingreso_pqr"),
      "6": t("recordTypes.salida_ajuste_negativo"),
      "7": t("recordTypes.salida_reserva_producto"),
      "8": t("recordTypes.salida_producto_despachado"),
      "9": t("recordTypes.salida_pqr"),
      "10": t("recordTypes.ingreso_por_cancelacion"),
      "11": t("recordTypes.ajuste_manual_disponible"),
      "12": t("recordTypes.ajuste_manual_en_transito"),
      "13": t("recordTypes.ajuste_manual_reservado"),

    };
    const transactionStates = {
      "1": t("transactionStates.pending"),
      "2": t("transactionStates.complete"),
      "3": t("transactionStates.reject"),
    };
  
    // Determinar el objeto correcto basado en el argumento 'cellField'
    const stockRecordObject = cellField === 'record_state' ? transactionStates : recordTypes;
        return (
        <span>
            {stockRecordObject[cellValueCodified] || t("Tipo de transaccion desconocida")}
        </span>
        );
    };

    const handleInputBalanceStock = (sku, fieldName, value) => {
        // Actualizar el estado solo si el valor es válido
        setStockItems((prevStockItems) =>
          prevStockItems.map((item) =>
            item.sku === sku ? { ...item, [fieldName]: parseInt(value) } : item
          )
        );
    };
    
      
      
    const handleProductSelect = async (product) => {
        console.log("product", product)
        setGlobalLoading(true);
        setSelectedProduct(product);
        setSelectedInventaryMode(false)
        const stockCollection = collection(db, 'stock');
        const stockRecordsCollection = collection(db, 'stock_records');
        
        const stockItemsQuery = query(stockCollection, where('related_product_id', '==', product.id));
        const stockRecordsQuery = query(stockRecordsCollection, where('related_product_id', '==', product.id));
        
        // Desuscribirse del snapshot anterior para stock items si es necesario
        if (unsubscribeStockItems) {
            unsubscribeStockItems();  // Llama la función de desuscripción previa
        }
    
        // Desuscribirse del snapshot anterior para stock records si es necesario
        if (unsubscribeStockRecords) {
            unsubscribeStockRecords();  // Llama la función de desuscripción previa
        }
    
        // Escuchar cambios en la consulta de stock items
        const unsubscribeStockItemsSnapshot = onSnapshot(stockItemsQuery, (snapshot) => {
            const stockItemsDocuments = [];
            snapshot.forEach((doc) => {
                stockItemsDocuments.push(doc.data());
            });
    
            // Actualiza el estado con los productos encontrados o un array vacío
            if (stockItemsDocuments.length > 0) {
               
                setStockItems(stockItemsDocuments);
                setStockItemsCopy(stockItemsDocuments);

            } else {
                toastFunction(toast, "warn", "No hay documentos de stock para el producto seleccionado", product?.product_name)
                setStockItems([]);
                setStockItemsCopy([]);
            }
            setGlobalLoading(false); // Detener el loading después de obtener los datos
        }, (error) => {
            console.error("Error fetching stock items: ", error);
            setGlobalLoading(false); // Detener el loading en caso de error
        });
        
        // Guardar la función de desactivación del listener para stock items
        setUnsubscribeStockItems(() => unsubscribeStockItemsSnapshot);
        
        // Escuchar cambios en la consulta de stock records
        const unsubscribeStockRecordsSnapshot = onSnapshot(stockRecordsQuery, (snapshot) => {
            const stockRecordsDocuments = [];
            snapshot.forEach((doc) => {
                stockRecordsDocuments.push(doc.data());
            });
    
            // Actualiza el estado con los registros encontrados o un array vacío
            if (stockRecordsDocuments.length > 0 ) {
                setStockRecords(stockRecordsDocuments);
            } else {
                toastFunction(toast, "warn", "No hay transacciones de stock para el producto seleccionado", product.product_name)
                setStockRecords([]);
            }
            setGlobalLoading(false); // Detener el loading después de obtener los datos
        }, (error) => {
            console.error("Error fetching stock records: ", error);
            setGlobalLoading(false); // Detener el loading en caso de error
        });
        
        // Guardar la función de desactivación del listener para stock records
        setUnsubscribeStockRecords(() => unsubscribeStockRecordsSnapshot);
    };

    const handleToggleStockMode = (mode) => {
        setSelectedInventaryMode(mode)
        console.log("modo", mode, stockItemsCopy)
        if(mode){
            setEmptyValuesToUpdateStock(stockItemsCopy)
        }else {
            setStockItems(stockItemsCopy)
        }
        
    };

    const setEmptyValuesToUpdateStock= (stockItemsDocuments) => {
        const emptyBalanceOfStock = stockItemsDocuments.map((stockItem) => { 
            return {
            ...stockItem,
            balance_disponible: 0,
            balance_en_transito: 0,
            balance_reservado: 0,}
        })
        setStockItems(emptyBalanceOfStock);
    }

  console.log("stockItems", stockItems)
  console.log("stockItemsCopy", stockItemsCopy)
  console.log("modo seleccionaod", selectedInventaryMode)
    const updateStock = async () => {
        try {
            handleToggleStockMode(false)   
            await generateStockUpdates(stockItems);
        } catch (error) {
            console.error("Error al actualizar el stock", error);
        }
    }

    useEffect(() => {
        let unsubscribe;
        if (!userOwnerData) {
            setGlobalLoading(false);
            return;
            }
        
        const productsCollection = collection(db, 'products');
        
        // Si el usuario no es insider tra los productos  relacionaods
        if (!isInsider) {
            const productsQuery = query(productsCollection, where('commercial_partner_id', '==', userOwnerData.business_id));
        
            unsubscribe = onSnapshot(productsQuery, (snapshot) => {
            const products = [];
            snapshot.forEach((doc) => {
                const producto = doc.data();
                products.push(producto);
            });
            setProductos(products);
            });
        
        } else {
            // Si es "insider", traer todos los productos sin filtro
            unsubscribe = onSnapshot(productsCollection, (snapshot) => {
            const products = [];
            snapshot.forEach((doc) => {
                const producto = doc.data();
                products.push(producto);
            });
            setProductos(products);
            });
        }
        
        // Guardar la función de desactivación del listener
        setUnsubscribeProducts(() => unsubscribe);
    
    }, [userData]); // Dependencias para ejecutar el efecto cuando cambian
    

    const stockItemsColumns = [
        {
            field: 'created_date',
            header: t("table-headers.createdAt"),
            filterable: false,
            // Columna para el campo 'created_date' con encabezado traducido y cuerpo personalizado para formatear la fecha
            body: (rowData) => {
              const createdAt = rowData?.created_date
                ? rowData?.created_date?.toDate()?.toLocaleString()
                : t("table-headers.invalidDateFormat");
              return <span>{createdAt}</span>;
            }, 
        },
        { 
          field: "sku",
          header: t("table-headers.sku"),
          filterable: true,
          sortable: false, 
        },
        { 
            field: "variation_label",
            header: t("table-headers.variationLabel"),
            filterable: false,
            sortable: false, 
            body: (rowData) => (
                <>
                    <span>
                        {rowData.variation_label ? rowData.variation_label : "Nombre no disponible"}
                    </span>
                </>
            ) 
        },
        { 
          field: "balance_disponible",
          header: t("table-headers.balanceDisponible"),
          filterable: false,
          sortable: false,
          body: (rowData) => (
            <>
               <InputNumber
                        mode="decimal"
                        useGrouping={false}        // Desactivar el uso de comas para agrupar miles

                        placeholder={t("input-placeholders.balanceDisponible")}
                        disabled={!selectedInventaryMode}
                        value={(rowData?.balance_disponible)}
                        onValueChange={(e) => handleInputBalanceStock(rowData.sku, "balance_disponible", e.value)}
                    />
             
            </>
          ) 
        },
        { 
            field: "balance_en_transito",
            header: t("table-headers.balanceEnTransito"),
            filterable: false,
            sortable: false,
            body: (rowData) => (
                <>
                    <InputNumber
                    mode="decimal"
                    useGrouping={false}        // Desactivar el uso de comas para agrupar miles

                        placeholder={t("input-placeholders.balanceEnTransito")}
                        disabled={!selectedInventaryMode}
                        value={(rowData?.balance_en_transito)}
                        onValueChange={(e) => handleInputBalanceStock(rowData.sku, "balance_en_transito", e.value)}
                    />
                  
                </>
              ) 
        },
        { 
            field: "balance_reservado",
            header: t("table-headers.balanceReservado"),
            filterable: false,
            sortable: false,
            body: (rowData) => (
                <>
                    <InputNumber
                    mode="decimal"
                    useGrouping={false}        // Desactivar el uso de comas para agrupar miles

                        placeholder={t("input-placeholders.balanceReservado")}
                        disabled={!selectedInventaryMode}
                        value={rowData?.balance_reservado} 
                        onValueChange={(e) => handleInputBalanceStock(rowData.sku, "balance_reservado", e.value)}
                    /> 
                </>
              ) 
        },
       
    ];
    const stockRecordsColumns = [
    {
        field: 'created_date',
        header: t("table-headers.createdAt"),
        filterable: false,
        // Columna para el campo 'created_date' con encabezado traducido y cuerpo personalizado para formatear la fecha
        body: (rowData) => {
            const createdAt = rowData?.created_date
            ? rowData?.created_date?.toDate()?.toLocaleString()
            : t("table-headers.invalidDateFormat");
            return <span>{createdAt}</span>;
        }, 
    },
    
    {
        field: "transaction_direction",
        filterable: false,
        body: (rowData) => {
            return rowData.transaction_direction == 1 ? <BiCaretUp  className="text-green-500 size-10"/> : <BiCaretDown  className="text-red-500 size-10"/>;
        }
    },
    { 
        field: "sku",
        header: t("table-headers.sku"),
        filterable: true,
        sortable: false, 
    },
    { 
        field: "value_of_detail",
        header: t("table-headers.valueOfDetail"),
        filterable: false,
        sortable: false, 
    },
    {
        field: "record_direction_type",
        header: t("table-headers.recordDirectionType"),      
        filterable: false,
        sortable: false, 
        body: (rowData) => decodifiedStockRecordDirectionField(rowData.record_direction_type, "transaction_type")
      },
    { 
        field: "record_qty",
        header: t("table-headers.recordQty"),
        filterable: false,
        sortable: false, 
    },
    { 
        field: "record_note",
        header: t("table-headers.recordNote"),
        filterable: false,
        sortable: false, 
    },
    { 
        field: "record_state",
        header: t("table-headers.recordState"),
        filterable: false,
        sortable: false, 
    },
    { 
        field: "type_of_detail",
        header: t("table-headers.typeOfDetail"),
        filterable: false,
        sortable: false, 
    },
    
   
    ];

    return (
        <div className="grid gap-9 grid-cols-1 mb-14" >
            <CustomSelect
                floatLabel={true}
                optionLabel={"product_name"}
                options={productos}
                valueTemplate={""}
                itemTemplate={""}
                value={selectedProduct}
                placeholder={t("input-placeholders.productName")}
                onChange={(e) => handleProductSelect(e.target.value)}
                disabled={globalLoading}
            />

            <div className="grid grid-cols-1">
                <ToggleButton 
                    checked={selectedInventaryMode}
                    onChange={(e) => handleToggleStockMode(e.value)}
                    onLabel="Ver stock"
                    offLabel="Actualizar stock"
                    disabled={!selectedProduct}
                />
                <h5 className="mt-2" >{!selectedInventaryMode  ? "Viendo stock de productos" : "Estas actualizando tu stock"}</h5>
            </div>
            <div>
                <CustomTable
                    className="custom-table"
                    columns={stockItemsColumns}
                    data={stockItems}
                    action={() => {}}
                />
                <Button
                    className={styles.secondaryButton}
                    type={"button"}
                    label={t("updateStock")}
                    loading={globalLoading}
                    onClick={() => setVisibleConfirmUpdateStockDialog(true)}
                    disabled={!selectedProduct || !selectedInventaryMode}
                />
            </div>
            <div className="mt-12">
                <CustomTable
                    className="custom-table"
                    columns={stockRecordsColumns}
                    data={stockRecords}
                    action={() => {}}
                />
            </div>
            { selectedInventaryMode && (
              <div
                htmlFor="chargeSKUsStock"
                className="fixed bottom-2 w-full">
                <MainButton 
                    onClick={() => setVisibleExcelTableModal(true)}
                    type={"button"}
                    label={t("uploadExcel")}
                    loading={globalLoading}
                />
              </div>
            )}
           
          <Toast ref={toast} />
          <div>
            <CustomModal
                visible={visibleExcelTableModal}
                setVisible={setVisibleExcelTableModal}
                // object={selectedShippingAddress}
                editMode={t("updateShippingAddress")}
                createMode={t("generateStockRecordsByExcelTable")}
                content={
                    <DetailUpdateStockExcelTable
                        toast={toast}
                        t={t}
                        selectedProduct={selectedProduct}
                        onClose={() => setVisibleExcelTableModal(false)}
                        handleToggleStockMode={handleToggleStockMode}
                    />
                }
                onClose={() => setVisibleExcelTableModal(false)}
                />
            <CustomConfirmDialog
                visible={visibleConfirmUpdateStockDialog}
                onHide={() => setVisibleConfirmUpdateStockDialog(false)}
                header={t("confirmDialog-updateStock.header")}
                message={t("confirmDialog-updateStock.message")}
                icon="pi pi-question-circle"
                accept={() => updateStock()}
                reject={() => setVisibleConfirmUpdateStockDialog(false)}
                acceptLabel={t("confirmDialog-updateStock.acceptLabel")}
                rejectLabel={t("confirmDialog-updateStock.rejectLabel")}
            />
          </div>
        </div>
    );
}