import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { base64ToBlob, isBase64 } from "./utilsFunctions.js";
import { storage } from "../firebase/firebase.js";

const processMediaArray = async (array) => {
    // Función para procesar cada objeto del array
    const processMediaObject = async (obj) => {
      // Procesar media_src
      if (isBase64(obj.media_src)) {
        obj.media_src = await processBase64Media(obj.media_path, obj.media_src, obj.media_type);
      }

      // Procesar media_mobile_src
      if (isBase64(obj.media_mobile_src)) {
        obj.media_mobile_src = await processBase64Media(obj.media_mobile_path, obj.media_mobile_src, obj.media_type);
      }

      // Procesar collection_banner_media
      const collectionBannerMedia = obj.collection_banner_media?.[0];
      if (collectionBannerMedia) {
        if (isBase64(collectionBannerMedia.media_src)) {
          collectionBannerMedia.media_src = await processBase64Media(collectionBannerMedia.media_path, collectionBannerMedia.media_src, collectionBannerMedia.media_type);
        }
        if (isBase64(collectionBannerMedia.media_mobile_src)) {
          collectionBannerMedia.media_mobile_src = await processBase64Media(collectionBannerMedia.media_mobile_path, collectionBannerMedia.media_mobile_src, collectionBannerMedia.media_type);
        }
      }
    };

  // Mapear y procesar cada objeto en el array
  await Promise.all(array.map(processMediaObject));

  return array;
};
export const processBase64Media = async (mediaPath, mediaSrc, mediaType) => {
  let downloadURL = null;
  if (mediaType === "img") {
    const blob = await fetch(mediaSrc).then((res) => res.blob());
    const storageRef = ref(storage, mediaPath);
    await uploadBytes(storageRef, blob);

    // Obtener la URL de descarga
    downloadURL = await getDownloadURL(storageRef);
  } else if (mediaType === "video") {
    const videoBlob = base64ToBlob(mediaSrc, 'video/mp4');
    const storageRefOriginal = ref(storage, mediaPath);
    await uploadBytes(storageRefOriginal, videoBlob);
    downloadURL = await getDownloadURL(storageRefOriginal);
  }
  return downloadURL;
};

export default processMediaArray;
