import * as XLSX from 'xlsx';
import { generateRandomId } from './generateRandomId';


// Inicializar Firestore

export const updateSKUsStock = async (file, setFunction) => {
  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data, { type: 'buffer' });
  const sheetName = workbook.SheetNames[0];
  const worksheet = workbook.Sheets[sheetName];
  const json = XLSX.utils.sheet_to_json(worksheet);
  console.log("RESPUESTA JSON", json)
  const jsonWithIds = json?.map((row) => ({ ...row, id: generateRandomId() }));
  setFunction(jsonWithIds)

  return jsonWithIds;
};


// import * as XLSX from 'xlsx';
// import firebase from 'firebase/app';

// export const updateSKUsStock = async (file) => {
//   const data = await file.arrayBuffer();
//   const workbook = XLSX.read(data, { type: 'buffer' });
//   const sheetName = workbook.SheetNames[0];
//   const worksheet = workbook.Sheets[sheetName];
//   const json = XLSX.utils.sheet_to_json(worksheet);
//   await updateStockInFirestore(json); // Asegúrate de esperar a que esta promesa se resuelva
// };

// const updateStockInFirestore = async (data) => {
//   const stockRef = firebase.firestore().collection('products_stock');
//   const BATCH_SIZE = 499;
//   let batch = firebase.firestore().batch();
//   let operationsCount = 0;

//   for (const item of data) {
//     const querySnapshot = await stockRef.where('related_skus', 'array-contains', item.sku).get();
//     let doc = querySnapshot.docs[0]
//     if (operationsCount >= BATCH_SIZE) {
//         await batch.commit();
//         batch = firebase.firestore().batch();
//         operationsCount = 0;
//     }
//     const docRef = stockRef.doc(doc.id);
//     const updatedSkus = doc.data().skus.map(sku => sku.sku === item.sku ? { ...sku, qty: parseInt(item.qty, 10) } : sku);
//     const availableSizes = calculateAvailableSizes(updatedSkus);
//     batch.update(docRef, { skus: updatedSkus, availableSizes });
//     operationsCount++;
//   }

//   if (operationsCount > 0) {
//     await batch.commit(); // Commit de cualquier operación restante
//   }
// };

// const calculateAvailableSizes = (skus) => {
//   const availableSizes = {};
//   skus.forEach(sku => {
//     const size = sku.related_options[1];
//     availableSizes[size] = availableSizes[size] || sku.qty > 0;
//   });
//   return availableSizes;
// };