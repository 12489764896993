
import PropTypes from 'prop-types';
// import { useState, useEffect } from 'react';
import styles from './ProductGridCard.module.css';
import { useTranslation } from 'react-i18next';
import numeral from 'numeral';
import { LiaHeart, LiaHeartSolid } from "react-icons/lia";
import SecondaryButton from '../SecondaryButton/SecondaryButton.jsx';
import { useAuthContext } from '../../contexts/Authcontext/Authcontext.jsx';
// import { useEffect } from 'react';
import { InputSwitch } from 'primereact/inputswitch';

const  ProductGridCard = ({ user_data, product_data, 
  // eslint-disable-next-line no-unused-vars
  img_placeholder, 
  onClick,
  onClickAddToCart,
  route,
  isCatalog,
  isCatalogOwner,
  key,
  disabledAddToCartBtn,
  handleToggleHiddenProduct,
  catalogData
 }) =>  {
  const { t } = useTranslation('ProductGridCard');
  const disableOpacity = 0.8;
  const discount = ((product_data?.suggested_retail_price - product_data?.commercial_partner_price_with_VAT) / product_data?.suggested_retail_price) * 100;
  
  const visibleProducts = catalogData?.visible_products || [];
  // Verifica si el producto está en la lista de productos ocultos
  const productVisible = (isCatalog && !isCatalogOwner && product_data?.id && visibleProducts[product_data.id]) || // Si es un catálogo, no es el dueño, y el producto está visible
    catalogData?.show_all_productos ||  // Si está habilitada la opción para mostrar todos los productos
    isCatalogOwner || !isCatalog; // Si es el dueño del catálogo

  // const [image, setImage] = useState(product_data?.main_img?.url);

// useEffect(() => {
//   if (product_data?.main_img) {
//     const img = new Image();
//     const productDataCopy = { ...product_data };

//     img.src = productDataCopy.main_img;
//     img.onload = () => {
//       // setImage(productDataCopy.main_img);
//     };
//   }
// }, [product_data]);

  return (
    <div key={key} 
      className={styles.card}
      style={productVisible ? {}: {display: "none"}}
     >
      <div className={styles.imageContainer} >
        <img src={product_data?.main_img?.url} alt={product_data?.product_name}  onClick={onClick}/>
        {!isCatalog || isCatalogOwner && (<DiscountBadge discount={discount} discountLabel={t("discountLabel")}/>)}
        <FavoriteIconWidget user_data={user_data} product_data={product_data}/>
        { (catalogData?.id === user_data?.related_catalog_id && isCatalog && isCatalogOwner) && (
            <SelectProductCardIconWidget  
              isCatalog={isCatalog}
              user_data={user_data}
              product_data={product_data}
              onToggleHiddenProduct={handleToggleHiddenProduct}
              catalogData={catalogData}
            />
          )}
        
      </div>
        <MainTitle  user_data={user_data} product_data={product_data}/>
      <div  onClick={onClick}>
        
        <div className={styles.card_footer}>
          <ProductComparativePriceWidget isCatalogOwner ={isCatalogOwner} user_data={user_data} product_data={product_data} disableOpacity={disableOpacity} isCatalog={isCatalog}/>
        </div>
      </div>

        <div className={styles.addToCartBtnContainer}>
           {route === "products" && (
            <AddProductToCartBtn 
              onClickAddToCart={onClickAddToCart} 
              isCatalog={isCatalog}
              isCatalogOwner={isCatalogOwner} 
              user_data={user_data}
              disabledAddToCartBtn={disabledAddToCartBtn}
            />
          )}
        </div>
    </div>
  );
}
ProductGridCard.propTypes = {
  user_data: PropTypes.object.isRequired,
  product_data: PropTypes.object.isRequired,
  img_placeholder: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

DiscountBadge.propTypes = {
  discount: PropTypes.number.isRequired,
  discountLabel: PropTypes.string.isRequired,
};

FavoriteIconWidget.propTypes = {
  user_data: PropTypes.object.isRequired,
  product_data: PropTypes.object.isRequired,
};

ProductComparativePriceWidget.propTypes = {
  user_data: PropTypes.object.isRequired,
  product_data: PropTypes.object.isRequired,
  disableOpacity: PropTypes.number,
};

PremiumPrice.propTypes = {
  user_data: PropTypes.object.isRequired,
  product_data: PropTypes.object.isRequired,
};

MainTitle.propTypes = {
  user_data: PropTypes.object.isRequired,
  product_data: PropTypes.object.isRequired,
};

StoresPrice.propTypes = {
  user_data: PropTypes.object.isRequired,
  product_data: PropTypes.object.isRequired,
  disableOpacity: PropTypes.number,
};
export default ProductGridCard;

export function DiscountBadge({discount, discountLabel}) {
  return (
    <div className={styles.discountBadge}>
      {Math.round(discount)}{discountLabel}
    </div>
  )
}

export function FavoriteIconWidget({user_data, product_data}) {
  return (
    <div className={styles.favoriteIcon}>
      {user_data?.favorites_products?.includes(product_data.id)?
        <div className={`clickable ${styles.likes}`}>
          <LiaHeartSolid className="icon"/>
          <span>{product_data?.likes}</span>
        </div>
        :
        <div className={`clickable ${styles.likes}`}>
          <LiaHeart className="icon" />
          <span>{product_data?.likes}</span>
        </div>
      }
    </div>
  )
}
export function SelectProductCardIconWidget({ catalogData, isCatalog, product_data, onToggleHiddenProduct }) {
  // Asegúrate de que catalogData.visible_products sea un array
  const visibleProducts = catalogData?.visible_products || [];

  const productVisible = isCatalog && product_data?.id && visibleProducts[product_data.id];

  const handleCheckboxChange = () => {
    if (onToggleHiddenProduct && product_data?.id) {
      onToggleHiddenProduct(product_data.id);
    }
  };

  return (
    <div className={styles.selectIcon}>
      <div className={`clickable ${styles.selectIcon}`}>
        <InputSwitch
          onChange={handleCheckboxChange}
          checked={productVisible}
        />
      </div>
    </div>
  );
}


export function ProductComparativePriceWidget({ isCatalogOwner, user_data, product_data, disableOpacity = 0.8 , isCatalog=false}) {
  return (
    <div className=''>
      <PremiumPrice 
        user_data={user_data}
        product_data={product_data}
        disableOpacity={disableOpacity}
        isCatalogOwner={isCatalogOwner}
        isCatalog={isCatalog}
      />

      {(isCatalogOwner || !isCatalog) && (
          <StoresPrice 
            isCatalog={isCatalog}
            user_data={user_data}
            product_data={product_data}
            disableOpacity={disableOpacity}
            isCatalogOwner={isCatalogOwner}
            />
        )}
      
      
      <ProductOwner isOwner={user_data?.business_id === product_data?.commercial_partner_id} />
      {product_data?.related_synced_id && !isCatalog && (<VinculationName  user_data={user_data} product_data={product_data}/>)}
    </div>
  )
}

export function ProductOwner({isOwner}) {
  const { t } = useTranslation('ProductGridCard');
  return (
    <span className={isOwner ? styles.is_product_owner : styles.not_product_owner}>
      <div className={`clickable `}>
        {
          isOwner ? t("productOwner") : ""
        }
      </div>
    </span>
  )
}
export function MainTitle({user_data, product_data}) {
// eslint-disable-next-line no-unused-vars
  const { t } = useTranslation('ProductGridCard');
  return (
      <h5 className={`${styles.head_title} main-gradient-text`} style={{ opacity: "1" , color:"var(--font-head-color)" }}>
          {product_data?.product_name}
      </h5>
  )
}
export function VinculationName({user_data, product_data}) {
// eslint-disable-next-line no-unused-vars
  const { t } = useTranslation('ProductGridCard');
  return (
    <span className={styles.headTitle}>
      <h5 className='main-gradient-text' style={{ opacity: "1" , color:"var(--font-head-color)" }}>
          Vinculado con : {product_data?.related_synced_name}
      </h5>
    </span>
  )
}
// eslint-disable-next-line no-unused-vars
export function AddProductToCartBtn({onClickAddToCart, isCatalog, isCatalogOwner,  disabledAddToCartBtn}) {

// eslint-disable-next-line no-unused-vars, react-hooks/rules-of-hooks
  // const { t } = useTranslation('ProductGridCard');
  return (
    <div className={styles.addToCartBtnContainer}>
    <SecondaryButton
    onClick={onClickAddToCart}
    label={isCatalog && isCatalogOwner ? "Configurar" : "Añadir al carrito"}
    disabled={!(isCatalog && isCatalogOwner) && disabledAddToCartBtn}
/>

    </div>
  )
}

// eslint-disable-next-line no-unused-vars
export function PremiumPrice({ user_data, product_data,  isCatalog }) {
  const { catalogData } = useAuthContext();
  const { t } = useTranslation('ProductGridCard');

  // Validar que catalogData y product_data existan y tengan los valores necesarios
  const hasCustomProfits = isCatalog && catalogData && catalogData.custom_profits && product_data?.id && catalogData.custom_profits[product_data.id] !== undefined;
  const commercialPartnerPrice = product_data?.commercial_partner_price_with_VAT_with_service_fee ? 
      product_data?.commercial_partner_price_with_VAT_with_service_fee :
      product_data?.commercial_partner_price_with_VAT !== undefined ? 
      (product_data?.commercial_partner_price_with_VAT + product_data?.service_fee) : 0;
  
      const suggestedRetailPrice = product_data?.suggested_retail_price !== undefined ? product_data?.suggested_retail_price : 0;
  // Calcular el precio que se mostrará
  const displayPrice = hasCustomProfits
      ? numeral(catalogData?.custom_profits[product_data?.id] + commercialPartnerPrice).format('$ 0,0[.]')
      :  product_data.fixed_price && product_data?.related_synced_id ?  numeral(product_data.fixed_price).format('$ 0,0[.]') : numeral(suggestedRetailPrice).format('$ 0,0[.]')

  // Calcular el texto que se mostrará en el badge

  const badgeText = "Tu precio de venta"

  return (
      <div className={styles.price}>
          <p
              className={`main-gradient-text md ${styles.premiun_price}`}
              style={{
                  opacity: 1,
                  fontWeight: "var(--font-weight-bold)"
              }}
          >
              {displayPrice}
          </p>
          <div>
              <p className={`clickable ${styles.premium_badget}`}>
                  {badgeText}
              </p>
          </div>
      </div>
  );
}
// eslint-disable-next-line no-unused-vars
export function StoresPrice({ user_data, product_data, disableOpacity = 0.8, isCatalog}) {
  const { catalogData } = useAuthContext();
  // const { t } = useTranslation('ProductGridCard');

  // Validar que los datos de precios existan antes de usarlos
  const commercialPartnerPrice =  product_data.commercial_partner_price_with_VAT_with_service_fee;
  const suggestedRetailPrice = product_data.suggested_retail_price
  const defaultProfit = suggestedRetailPrice - commercialPartnerPrice;
  const hasCustomProfits = isCatalog && catalogData && catalogData?.custom_profits && product_data?.id && catalogData.custom_profits[product_data.id] !== undefined;
  // Calcular el precio que se mostrará
  const displayPrice =  hasCustomProfits
      ? numeral(catalogData.custom_profits[product_data.id]).format('$ 0,0[.]') :
      product_data.fixed_price && product_data?.related_synced_id  ? numeral(product_data.fixed_price - commercialPartnerPrice).format("$ 0,0[.]")  :  numeral(defaultProfit).format("$ 0,0[.]") 

  return (
      <div className={styles.price}>
          <p
              style={{
                  color: "var(--dark-color)",
                  opacity: disableOpacity,
                  fontWeight: "var(--font-weight-bold)",
                  textDecoration:  "none"
                }}
          >
              {displayPrice}
          </p>
          <div>
              <p className={styles.stores_badget}>
                  {"Tu ganancia"}
              </p>
          </div>
      </div>
  );
}

