import PropTypes from 'prop-types';

import { useTranslation } from "react-i18next";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import CustomInputNumber from "../FormComponents/CustomInputNumber/CustomInputNumber";
import { useEffect, useState } from "react";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext";
import MainButton from "../MainButton/MainButton";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import toastFunction from "../../utils/toastFunction";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext";
import SecondaryButton from "../SecondaryButton/SecondaryButton";

const DetailCatalogProductProfitForm = ({ productData, toast, onClose}) => {
    const { globalLoading } = useLoaderContext();
    const { t } = useTranslation("Catalog");
    const { waUrlHook } = useGlobalContext();

    const { catalogData, userData, userSellerOwnerData } = useAuthContext();
    const [profitValues, setProfitValues] = useState(catalogData?.custom_profits || {});
    const [sellerPrice, setSellerPrice] = useState(null);
    const { updateDocument } = useFirebaseCRUD();

    const userOwnerData = userSellerOwnerData || userData;

    const handleSaveCustomProfit = async (e) => {
        e.preventDefault();
        console.log("Antes de actualizar", profitValues, userOwnerData.related_catalog_id, catalogData.id);
        const updateResult = await updateDocument("catalogs", userOwnerData.related_catalog_id, { custom_profits: profitValues });
        if (updateResult) {
            onClose()
            toastFunction(toast, "success", "Catálogo actualizado", "", 3000);
        } else {
            toastFunction(toast, "error", "Hubo un error al actualizar tu catálogo", "", 3000);
        }
    };

    const sellerCatalogUrl = `https://supplaii.com/catalogProductPage/?catalogId=${catalogData?.id}&productId=0DAuAbxFNqCi4ZxPvtEq`;

    const shareProduct = async () => {
        const message = `Hola, te comparto este producto *${productData?.product_name}* \n\n *${sellerCatalogUrl}*`;
        waUrlHook("withoutPhone", null, message);
    }
    // console.log("catalogData", catalogData)
    // console.log("productData", productData)
    // console.log("catalogData?.custom_profits?.[productData.id]", catalogData?.custom_profits?.[productData.id])
    useEffect(() => {
        if (catalogData?.custom_profits?.[productData.id]) {
            setSellerPrice(catalogData.custom_profits[productData.id] + productData.commercial_partner_price_with_VAT_with_service_fee);
        } else {
            setSellerPrice(productData.suggested_retail_price);
        }
    }, []);


    return (
        <form onSubmit={handleSaveCustomProfit}>
            <h4>¿Cuánto ganarás?</h4>
            <CustomInputNumber
                required={true}
                floatLabel={true}
                value={sellerPrice}
                prefix={"$ "}
                placeholder={t("input-placeholders.sellerPrice")}
                onChange={(e) => {
                    const priceWithServiceFee = productData?.commercial_partner_price_with_VAT_with_service_fee 
                    const profitValue = e.value - priceWithServiceFee;
                    setSellerPrice(e.value);
                    setProfitValues((prevValues) => ({
                        ...prevValues,
                        [productData.id]: profitValue,
                    }));
                }}
                disabled={globalLoading}
            />
            <CustomInputNumber
                required={true}
                floatLabel={true}
                value={productData?.commercial_partner_price_with_VAT_with_service_fee}
                min={0}
                prefix={"$ "}
                placeholder={t("input-placeholders.productCost")}
                disabled={true}
            />
            <CustomInputNumber
                required={true}
                floatLabel={true}
                value={profitValues[productData.id] || productData?.suggested_retail_price - productData.commercial_partner_price_with_VAT}
                min={0}
                prefix={"$ "}
                placeholder={t("input-placeholders.profit")}
                disabled={true}
            />
            <div className="grid gap-3">
                <SecondaryButton
                        type={"button"}
                        label={t("shareProduct")}
                        onClick={shareProduct}
                        disabled={globalLoading}
                        loading={globalLoading}
                    />
                <MainButton loading={false} label={"GUARDAR"} type={"submit"} />

            </div>
        </form>
    );
};


DetailCatalogProductProfitForm.propTypes = {
    productData: PropTypes.object, // Asegura que el productData sea pasado y sea del tipo especificado
    toast: PropTypes.func, // Función de traducción
    onClose: PropTypes.func, // Función de traducción
  };
export default DetailCatalogProductProfitForm;
