import styles from "./ChatWindow.module.css"

import { ChatAudioMessage, ChatImageMessage, ChatMessageText, ChatVideoMessage } from "./ChatMessagges/ChatMessages";
import ChatInputMessage from "./ChatInputMessage/ChatInputMessage";
import ChatHeader from "./ChatHeader/ChatHeader";
import { useChatMenu } from "./ChatMenuContext";
import { useEffect, useRef } from "react";

export default function ChatWindow({onSelectMenuOption}) {

  const { chatMessagesExample } = useChatMenu();
  const messagesEndRef = useRef(null);

  // useEffect para hacer scroll hacia abajo al montar el componente
  // useEffect(() => {
  //   const scrollToBottom = () => {
  //     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  //   };

  //   scrollToBottom(); // Scroll inicial
    
  //   const intervalId = setInterval(() => {
  //     scrollToBottom();
  //   }, 100); // Intentar hacer scroll cada 100 ms

  //   // Limpiar el intervalo después de 1 segundo
  //   setTimeout(() => {
  //     clearInterval(intervalId);
  //   }, 1000);

  // }, [chatMessagesExample]);
  
  
  return (
    <div className={styles.chatWindowContainer}>
      <ChatHeader onSelectMenuOption={onSelectMenuOption}/>
      <div className={styles.chatMessagesList}>


      <div className={styles.chatDate}>
        <p>Hoy, octubre 11</p>
      </div>


      {chatMessagesExample.map((message) => {
        switch (message.type) {
          case "text":
            return (
              <ChatMessageText
                key={message.id}
                messageId={message.id}
                avatarSrc={message.avatarSrc}
                username={message.username}
                time={message.time}
                type={message.type}
                messageText={message.messageText}
                isSent={message.isSent}
                isRepliying={message.isRepliying}
                typeRepliying={message.typeRepliying}
                messageTextRepliying={message.messageTextRepliying}
                imgSrcRepliying={message.imgSrcRepliying}
                videoSrcRepliying={message.videoSrcRepliying}
                idMessageRepliying={message.idMessageRepliying}
              />
            );
          case "image":
            return (
              <ChatImageMessage
                key={message.id}
                messageId={message.id}
                avatarSrc={message.avatarSrc}
                username={message.username}
                time={message.time}
                messageText={message.messageText}
                imageSrc={message.imageSrc}
                type={message.type}
                isSent={message.isSent}
                isRepliying={message.isRepliying}
                typeRepliying={message.typeRepliying}
                imgSrcRepliying={message.imgSrcRepliying}
                videoSrcRepliying={message.videoSrcRepliying}
                messageTextRepliying={message.messageTextRepliying}
                idMessageRepliying={message.idMessageRepliying}


              />
            );
          case "audio":
            return (
              <ChatAudioMessage
                key={message.id}
                messageId={message.id}
                avatarSrc={message.avatarSrc}
                username={message.username}
                type={message.type}
                time={message.time}
                audioSrc={message.audioSrc}
                isSent={message.isSent}
                messageTextRepliying={message.messageTextRepliying}
                idMessageRepliying={message.idMessageRepliying}

              />
            );
          case "video":
            return (
              <ChatVideoMessage
                key={message.id}
                messageId={message.id}
                avatarSrc={message.avatarSrc}
                username={message.username}
                time={message.time}
                messageText={message.messageText}
                type={message.type}
                videoSrc={message.videoSrc}
                isSent={message.isSent}
                isRepliying={message.isRepliying}
                typeRepliying={message.typeRepliying}
                videoSrcRepliying={message.videoSrcRepliying}
                imgSrcRepliying={message.imgSrcRepliying}
                messageTextRepliying={message.messageTextRepliying}
                idMessageRepliying={message.idMessageRepliying}
              
              />
            );
          default:
            return null;
        }
      })}

      {/* Este elemento invisible se utiliza para hacer scroll hasta el final */}          
      <div ref={messagesEndRef}  />
     </div>
      

        <ChatInputMessage/>

      </div>
    );
  }

