// import { useRef } from "react";
import SecondaryButton from "/src/Components/SecondaryButton/SecondaryButton";
import { TieredMenu } from "primereact/tieredmenu";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import AuxStatusBadget from "../../../Components/AuxStatusBadget/AuxStatusBadget";
import CustomInputText from "../../../Components/FormComponents/CustomInputText/CustomInputText";
import CustomSelect from "../../../Components/FormComponents/CustomSelect/CustomSelect";
import { ToggleButton } from "primereact/togglebutton";
import { BiCheckCircle } from "react-icons/bi";
import { IoWarning } from "react-icons/io5";
import LoaderSpinner from "../../../Components/LoaderSpinner/LoaderSpinner";

// import DeleteButton from "../../../Components/DeleteButton/DeleteButton";
import formatCurrency from "../../../utils/formatCurrency";
import formatDate from "../../../utils/formatDate.js";
import { defProvinces } from "../../../utils/defProvinces.js";
import CustomInputNumber from "../../../Components/FormComponents/CustomInputNumber/CustomInputNumber.jsx";
  
export const OrderTableColumns = ({
  t,
  orderStates,
  stateColors,
  stateLabelColors,
  handleRowChange,
  openItemOverlayPanel,
  rowEditStates,
  shippingCompanies,
  handleConfirmOrderModal,
  toggleEdit,
  updateRowOrder,
  zones,
  rowCheckBox,
  onRowCheckBoxChange,
  onSelectAllRowsChange,
  selectAllRows,
  shippingAddresTypes,
  openLogsOverlayPanel,
  rowLoading,
  menu,
  menuItems,
  globalLoading,
  optionMenu,
  openOrderStatusOverlayPanel,
  orderPaymentStatus,
  isMobile,
  opItems,
  closeOverlayPanel,
}) => {


  const  getCitiesByProvince = (provinceCode, zones) => {
    if (!provinceCode || !zones) {
      return [];
    }
  
    // Filtrar las zonas basadas en el province_code
    const cities = zones.filter(zone => zone?.province_code === provinceCode)
                        .map(zone => ({
                          city_code: zone.city_code,
                          city_name: zone.city_name
                        }));

    return cities.length > 0 ? cities : [];
  }
  

  return [
    {
      field: "selectAllRows",
      header: () => (
        <Checkbox
          inputId="selectAllRows"
          onChange={onSelectAllRowsChange}
          checked={selectAllRows}
          style={{ display: "flex", width: "30px", alignItems: "center" }}
        />
      ),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <Checkbox
          inputId={`selectOneRow-${rowData.id}`}
          value={rowData}
          onChange={(e) => onRowCheckBoxChange(e, rowData)}
          checked={rowCheckBox.includes(rowData.id)}
          style={{ display: "flex", width: "30px", alignItems: "center" }}
        />
      ),
    },
    {
      field: "edit",
      header: t("table-headers.edit"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <>
          {!rowEditStates[rowData.id] ? (
            <Button
              icon="pi pi-pencil"
              onClick={() => toggleEdit(rowData)}
              className="p-button-rounded p-button-outlined"
              disabled={rowData?.order_is_cancelled || rowData?.shipping_guide_number}
            />
          ) : (
            <Button
              icon="pi pi-database"
              onClick={() => updateRowOrder(rowData.id)}
              className="p-button-rounded p-button-outlined"
            />
          )}
        </>
      ),
    },
    {
      field: "states",
      header: t("table-headers.state"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <>
          <div className={"grid"}>
            <span>{formatDate(rowData?.created_at)}</span>
            <span>
              <AuxStatusBadget
                onClick={(e) => openOrderStatusOverlayPanel(rowData, e)}
                statusLabel={orderStates
                  .filter((state) => state?.code === rowData?.last_state)
                  .map((matchingState, index) => (
                    <span
                      key={index}
                      style={{
                        backgroundColor: stateColors[matchingState.code],
                        color: stateLabelColors[matchingState.code],
                        fontWeight: "bold",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        margin: "4px 0",
                        display: "flex",
                      }}
                    >
                      {matchingState?.name}
                    </span>
                  ))}
              />
            </span>
          </div>
        </>
      ),
    },
    {
      field: "payment_status",
      header: t("table-headers.order"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <>
          <div className={""}>
            <span>{rowData?.store_name}</span>
            <span
              style={{
                margin: "4px 0",
                display: "flex",
              }}
            >
              {rowData?.suffix_order}
            </span>
            {rowData?.payment_method === 2 && (
              <span>
                <AuxStatusBadget
                  statusLabel={orderPaymentStatus
                    ?.filter((state) => state.code === rowData?.online_payment_status)
                    ?.map((matchingState, index) => (
                      <span
                        key={index}
                        style={{
                          backgroundColor: stateColors[matchingState.code],
                          color: "white",
                          padding: "4px 8px",
                          borderRadius: "4px",
                          margin: "4px 0",
                          display: "flex",
                        }}
                      >
                        {matchingState?.name}
                      </span>
                    ))}
                />
              </span>
            )}
          </div>
        </>
      ),
    },
    {
      field: "customer_full_name",
      header: t("table-headers.customer"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div className="">
          <CustomInputText
            value={rowData.customer_full_name}
            onChange={(e) =>
              handleRowChange(rowData, "customer_full_name", e.target.value)
            }
            disabled={!rowEditStates[rowData.id]}
            placeholder={t("input-placeholders.customerFullName")}
            inColumTable={true}
          />
          <CustomInputText
            value={rowData.customer_phone_number || ""}
            onChange={(e) =>
              handleRowChange(rowData, "customer_phone_number", e.target.value)
            }
            disabled={!rowEditStates[rowData.id]}
            placeholder={t("input-placeholders.customerPhone")}
            inColumTable={true}
          />
        </div>
      ),
    },
    {
      field: "shipping_destination",
      header: t("table-headers.destination"),
      filterable: false,
      sortable: false,
      body: (rowData, rowState, handleRowProvinceAndCity) => (
          <>
             <CustomInputText
              value={rowData.customer_shipping_address}
              onChange={(e) =>
                handleRowChange(rowData, "customer_shipping_address", e.target.value)
              }
              disabled={!rowEditStates[rowData.id]}
              placeholder={t("input-placeholders.destinyShippingAddress")}
              inColumTable={true}
            />
              <CustomSelect
                floatLabel={true}
                optionLabel={"province_name"}
                options={defProvinces(zones || [])}
                value={rowState?.province || {
                  province_code: rowData?.shipping_destination?.province_code,
                  province_name: rowData?.shipping_destination?.province_name
                }}
                placeholder={t("input-placeholders.destinyShippingAddress")}

                onChange={(e) => handleRowProvinceAndCity(rowData.id, "province", e.value)}
              disabled={!rowEditStates[rowData.id] || globalLoading}

              />

              <CustomSelect
                floatLabel={true}
                optionLabel={"city_name"}
                options={getCitiesByProvince((rowState?.province?.province_code || rowData?.shipping_destination?.province_code), zones)}
                value={rowState?.city || {
                  city_code: rowData?.shipping_destination?.city_code,
                  city_name: rowData?.shipping_destination?.city_name}}
                placeholder={t("input-placeholders.city")}
                onChange={(e) => handleRowProvinceAndCity(rowData.id, "city", e.target.value)}
                disabled={!rowEditStates[rowData.id] || globalLoading}

              />
          </>
        )
    },
    {
      field: "line_items",
      header: t("table-headers.items"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <>
          <Button
            type="button"
            icon="pi pi-search"
            label={rowData.line_items.length}
            onClick={(e) => openItemOverlayPanel(rowData, e)}
            onMouseEnter={(e) => openItemOverlayPanel(rowData, e)}
            onMouseLeave={(e) => {
              // Cierra el overlay si el ratón sale del área del botón
              if (opItems.current && !opItems.current.container.contains(e.relatedTarget)) {
                closeOverlayPanel();
              }
            }}
          />
        </>
      ),
    },
    {
      field: "total_order_price",
      header: t("table-headers.totalOrder"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <span>{formatCurrency(rowData.total_order_price)}</span>
      ),
    },
    {
      field: "dispatch",
      header: t("table-headers.dispatch"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <>
          <CustomInputNumber
              floatLabel={true}
              prefix={"$"}
              value={(rowData?.shipping_quote_cost?.price + rowData?.shipping_quote_cost?.servicePayment)}
              disabled={true}
              placeholder={t("input-placeholders.quoteShippingCost")}
              inColumTable={true}
            />
           {rowData?.shipping_cost && (<CustomInputNumber
              floatLabel={true}
              prefix={"$"}
              value={(rowData?.shipping_cost?.price + rowData?.shipping_cost?.servicePayment)}
              disabled={true}
              placeholder={t("input-placeholders.shippingCost")}
              inColumTable={true}
            />)}
          <CustomSelect
            optionLabel={"name"}
            options={shippingCompanies}
            value={rowData.shipping_company_details}
            onChange={(e) => handleRowChange(rowData, "shipping_company_details", e.value)}
            placeholder={t("input-placeholders.shippingCompany")}
            disabled={!rowEditStates[rowData.id]}
            inColumTable={true}
          />
          <CustomInputText
            value={rowData?.shipping_guide_number || ""}
            placeholder={t("input-placeholders.shippingGuideNumber")}
            disabled={true}
            inColumTable={true}
          />
        </>
      ),
    },
    {
      field: "shipping_address_type",
      header: t("table-headers.shippingAddressType"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <CustomSelect
          optionLabel={"name"}
          options={shippingAddresTypes}
          value={rowData.shipping_address_type}
          onChange={(e) => handleRowChange(rowData, "shipping_address_type", e.value)}
          placeholder={t("input-placeholders.shippingAddressType")}
          disabled={!rowEditStates[rowData.id]}
          inColumTable={true}
        />
      ),
    },
    {
      field: "payment_method",
      header: t("table-headers.paymentMethod"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div
          style={{
            backgroundColor: "green",
            justifyContent: "center",
            color: "white",
            padding: "4px 8px",
            fontWeight: "bold",
            borderRadius: "4px",
            margin: "4px 0",
            display: "flex",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          {rowData?.payment_method === 1 ? "COD" : "ONLINE"}
        </div>
      ),
    },
    {
      field: "order_is_confirmed",
      header: t("table-headers.orderConfirm"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div className="card flex justify-content-center">
          <ToggleButton
            checked={!rowData.order_is_confirmed}
            onChange={() => handleConfirmOrderModal(rowData)}
            className="w-8rem"
            onLabel="Confirmar"
            offLabel="Revertir Confirmacion"
            disabled={
              rowData.order_is_cancelled || 
              rowData?.last_state === 4 ||
              rowData?.last_state === 5 ||
              rowData?.last_state === 6 ||
              rowData?.last_state === 7 ||
              rowData?.last_state === 8 ||
              rowData?.last_state === 9}
          />
        </div>
      ),
    },
    {
      field: "guide_log",
      header: t("table-headers.guideLog"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div className="flex gap-2">
          <Button
            type="button"
            icon="pi pi-search"
            label={rowData.guide_generation_msgs.length}
            onClick={(e) => openLogsOverlayPanel(rowData, e)}
          />
          <span>
            {rowData.shipping_guide_number ? (
              <BiCheckCircle style={{ fontSize: "2.5em" }} />
            ) : (
              <IoWarning style={{ fontSize: "2.5em" }} />
            )}
          </span>
        </div>
      ),
    },
    {
      field: "spinner",
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div className="card flex justify-content-center">
          {rowLoading.includes(rowData.id) && <LoaderSpinner />}
        </div>
      ),
    },
    {
      field: "order_note",
      header: t("table-headers.note"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div>
          <CustomInputText
            value={rowData.note || ""}
            onChange={(e) => handleRowChange(rowData, "note", e.target.value)}
            placeholder={t("input-placeholders.orderNote")}
            disabled={!rowEditStates[rowData.id]}
            inColumTable={true}
          />
        </div>
      ),
    },
    {
      field: "options",
      header: t("table-headers.options"),
      filterable: false,
      sortable: false,
      frozen: true,
      style: {
        backgroundColor: '#f9f9f9', // Optional for better visibility
      },
      body: (rowData) => (
        <div className="card flex justify-content-center">
          <TieredMenu model={menuItems} popup ref={menu} breakpoint="767px" />
          <SecondaryButton
            loading={globalLoading}
            label={isMobile ? "..." : t("options")}
            onClick={(e) => optionMenu(e, rowData)}
          />
        </div>
      ),
    },
  ];
};

export const OrderitemsTableColumns = (t, handleEdit, selectedOrderRowData) => [
  {
    field: "synced_product_names.shopify_product_name",
    header: t("dataTable-headers.shopifyProductName"),
    filterable: false,
    sortable: false,
  },
  {
    field: "synced_product_names.shoppex_product_name",
    header: t("dataTable-headers.shoppexProductName"),
    filterable: false,
    sortable: false,
  },
  {
    header: t("skuImg"),
    filterable: false,
    sortable: false,
    body: (rowData) => (
      <img
        src={rowData.sku_img}
        alt="SKU"
        style={{
          width: '100px', // Ajusta el tamaño según tus necesidades
          height: '100px', // Ajusta el tamaño según tus necesidades
          objectFit: 'cover', // Ajusta la imagen para cubrir el área del contenedor
          borderRadius: '50%', // Redondea la imagen
        }}
      />
    )
  },
  {
    field: "sku",
    header: t("sku"),
    filterable: false,
    sortable: false,
  },
  {
    field: "sku_title",
    header: t("variation"),
    filterable: false,
    sortable: false,
  },

  {
    field: "quantity",
    header: t("dataTable-headers.qty"),
    filterable: false,
    sortable: false,
  },
  {
    field: "unit_price",
    header: t("dataTable-headers.unitProductPrice"),
    filterable: false,
    sortable: false,
    body: (rowData) => (
      <>
        {formatCurrency(rowData?.unit_price)}
      </>
    ),
  },
  {
    field: "total_item_price",
    header: t("dataTable-headers.totalItemPrice"),
    filterable: false,
    sortable: false,
    body: (rowData) => (
      <>
        {formatCurrency(rowData?.total_item_price)}
      </>
    ),
  },
  {
    field: "edit",
    header: t("dataTable-headers.edit"),
    filterable: false,
    sortable: false,
    body: (rowData) => (
      <>
        <Button
          icon="pi pi-pencil"
          onClick={() => handleEdit(rowData)}
          className="p-button-rounded p-button-outlined"
          disabled={selectedOrderRowData?.order_is_cancelled || selectedOrderRowData?.shipping_guide_number}
        />
      </>
    ),
  },
  
]
export const OrderGuideLogsTableColumns = (t, selectedOrderRowData ) => [
  {
    field: "icon",
    filterable: false,
    sortable: false,
    style:{width: "10%", maxWidth: "150px"},

    body:() =>
      selectedOrderRowData?.shipping_guide_number ? (
        <BiCheckCircle style={{ fontSize: "2.5em" }} />
      ) : (
        <IoWarning style={{ fontSize: "2.5em" }} />
      )
  },
  {
    field: "msg",
    filterable: false,
    sortable: false,
    style:{ maxWidth: "150px" },
    body:
      (msg) =>  
        <span className="w-12">{msg}</span>
  },
]
export const MenuItems = ({  
  t,
  selectedOrderRowData,
  handleGenerateIndividualGuide,
  handleTransformAndPrintGuide,
  handleConfirmFullfilmentModal,
  handleCancelAnOrderModal,
  setVisibleConfirmReactivateOrderDialog,
  setVisibleConfirmCancelShippingGuideDialog
}) =>  [
    {
      label: t("generateGuide"),
      icon: "pi pi-fw pi-file",
      command: () => {
        handleGenerateIndividualGuide(selectedOrderRowData);
      },
      disabled:
        selectedOrderRowData?.order_is_confirmed &&
        !selectedOrderRowData?.guide_generated &&
        selectedOrderRowData?.shipping_destination &&
        selectedOrderRowData?.shipping_destination?.city_code
          ? false
          : true,
    },
    {
      label: t("downloadPdf"),
      icon: "pi pi-fw pi-file",
      disabled: selectedOrderRowData?.shipping_guide_pdf ? false : true,
      command: () => {
        handleTransformAndPrintGuide(selectedOrderRowData);
      },
    },
    {
      label: t("Empacar Pedido"),
      icon: "pi pi-fw pi-file",
      disabled:
        selectedOrderRowData?.order_is_confirmed &&
        selectedOrderRowData?.shipping_guide_pdf &&
        selectedOrderRowData?.guide_generated &&
        selectedOrderRowData?.last_state !== 3 &&
        selectedOrderRowData?.last_state !== 4 &&
        selectedOrderRowData?.last_state !== 8 &&
        selectedOrderRowData?.last_state !== 5 &&
        selectedOrderRowData?.last_state !== 6 &&
        selectedOrderRowData?.last_state !== 7 &&
        selectedOrderRowData?.last_state !== 8 
          ? false
          : true,
      command: () => {
        handleConfirmFullfilmentModal(selectedOrderRowData);
      },
    },
    {
      label: t("Cancelar Orden"),
      icon: "pi pi-times",
      disabled: selectedOrderRowData.last_state === 8 || selectedOrderRowData.last_state === 4,
      command: () => {
        handleCancelAnOrderModal(selectedOrderRowData);
      },
    },
    {
      label: t("Reactivar pedido"),
      icon: "pi pi-fw pi-file",
      disabled: selectedOrderRowData.last_state !== 8,
      command: () => {
        setVisibleConfirmReactivateOrderDialog(true);
      },
    },
    {
      label: t("Cancelar guia"),
      icon: "pi pi-times",
      disabled: 
        (!selectedOrderRowData?.guide_generated && 
        !selectedOrderRowData?.shipping_guide_number && 
        !selectedOrderRowData?.shipping_guide_pdf ) ||
        selectedOrderRowData.last_state > 2,
      command: () => setVisibleConfirmCancelShippingGuideDialog(true),
    },
];
