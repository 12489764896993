import {
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  setDoc,
  deleteDoc,
  writeBatch,
  onSnapshot,
  getDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { db, storage } from "../firebase/firebase";
import { deleteObject, listAll, ref } from "firebase/storage";

const useFirebaseCRUD = () => {

  const updateDocument = async (collectionName, documentId, updatedData) => {
    try {
      if (documentId) {
        const docRef = doc(db, collectionName, documentId);
        await updateDoc(docRef, updatedData);
        return true;
      }
    } catch (error) {
      console.error("Error en la actualizacion:", error);
      return false;
    }
  };
  const addToArray = async (collectionName, documentId, arrayKey, newValue) => {
    try {
      const docRef = doc(db, collectionName, documentId);
      await updateDoc(docRef, {
        [arrayKey]: arrayUnion(newValue),
      });
      return true;
    } catch (error) {
      console.error("Error al agregar al array:", error);
      return false;
    }
  };
  const removeFromArray = async (
    collectionName,
    documentId,
    arrayKey,
    valueToRemove
  ) => {
    try {
      const docRef = doc(db, collectionName, documentId);
      await updateDoc(docRef, {
        [arrayKey]: arrayRemove(valueToRemove),
      });
      return true;
    } catch (error) {
      console.error("Error al eliminar del array:", error);
      return false;
    }
  };
  const createDocument = async (collectionName, documentId, documentData) => {
    const documentRef = doc(db, collectionName, documentId);
    try {
      await setDoc(documentRef, documentData);
      // console.log(
      //   `Documento creado en la colección ${collectionName} con ID: ${documentId}`
      // );
      return true;
    } catch (error) {
      console.error("Error al crear el documento:", error);
      return false;
    }
  };
  const deleteDocument = async (collectionName, documentId) => {
    const documentRef = doc(db, collectionName, documentId);
    try {
      await deleteDoc(documentRef);
      // console.log(
      //   `Documento con ID: ${documentId} eliminado de la colección ${collectionName}`
      // );
      return true;
    } catch (error) {
      console.error("Error al eliminar el documento:", error);
      return false;
    }
  };
  const updateArray = async (collectionName, documentId, arrayKey, arrayToRemove, arrayAdd) => {
    const batch = writeBatch(db);
    const docRef = doc(db, collectionName, documentId);
    try {
        batch.update(docRef, {
            [arrayKey]: arrayRemove(arrayToRemove)
        });
        batch.update(docRef, {
            [arrayKey]: arrayUnion(arrayAdd)
        });
        await batch.commit();
        return true;
    } catch (error) {
        console.error('Error al actualizar el array:', error);
        return false;
    }
  };
const getDocumentsByQuery = (query) => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onSnapshot(query, (snapshot) => {
      const documents = [];
      snapshot.forEach((doc) => {
        documents.push(doc.data());
      });
      unsubscribe(); // Cancelamos la suscripción una vez que recibimos los datos
      resolve(documents); // Resolvemos la promesa con los datos recibidos
    }, (error) => {
      reject(error); // En caso de error, rechazamos la promesa
    });
  });
};
const deleteFolderStorageAndContents = async (folderPath)  => { 
  const storageRef = ref(storage, folderPath);
  try {
    // Obtener la lista de elementos dentro de la carpeta
    const { items, prefixes } = await listAll(storageRef);
    // Eliminar todos los elementos dentro de la carpeta
    await Promise.all(items.map(itemRef => deleteObject(itemRef)));
    // Recorrer y eliminar todas las subcarpetas
    await Promise.all(prefixes.map(prefix => deleteFolderStorageAndContents(prefix.fullPath)));

    // console.log(`Carpeta ${folderPath} y su contenido eliminados del almacenamiento.`);
  } catch (error) {
    console.error(`Error al intentar eliminar la carpeta ${folderPath} y su contenido del almacenamiento:`, error);
  }
}
  const getDocumentById = async (collectionName, documentId) => {
    try {
        // Construye la referencia al documento utilizando su ID
        const documentRef = doc(db, collectionName, documentId); // Reemplaza 'nombre_coleccion' con el nombre de tu colección
        // Obtiene el documento
        const documentSnapshot = await getDoc(documentRef);

        if (documentSnapshot.exists()) {
            // Devuelve los datos del documento si existe
            return documentSnapshot.data();
        }
    } catch (error) {
        console.error('Error al obtener el documento:', error);
        throw error;
    }
};

const getDocumentFromSubcollection = async (mainCollection, documentId, subcollectionName, subdocumentId) => {
  try {
      // Construye la referencia al documento de la subcolección
      const subdocumentRef = doc(db, mainCollection, documentId, subcollectionName, subdocumentId);
      
      // Obtiene el documento de la subcolección
      const subdocumentSnapshot = await getDoc(subdocumentRef);

      if (subdocumentSnapshot.exists()) {
          // Devuelve los datos del documento si existe
          return subdocumentSnapshot.data();
      } else {
          throw new Error('El documento no existe.');
      }
  } catch (error) {
      console.error('Error al obtener el documento de la subcolección:', error);
      throw error;
  }
};

const getAllDocumentsFromSubcollection = async (mainCollection, documentId, subcollectionName) => {
  try {
      // Construye la referencia a la subcolección
      const subcollectionRef = collection(db, mainCollection, documentId, subcollectionName);

      // Obtiene todos los documentos de la subcolección
      const subcollectionSnapshot = await getDocs(subcollectionRef);

      // Verifica si hay documentos y los mapea a un array de datos
      if (!subcollectionSnapshot.empty) {
          const documents = subcollectionSnapshot.docs.map(doc => ({
              ...doc.data()
          }));
          return documents;
      } else {
          console.error('No hay documentos en la subcolección.');
          return null
        }
  } catch (error) {
      console.error('Error al obtener los documentos de la subcolección:', error);
      return null;
  }
};

const updateSubcollectionDocument = async (collectionName, documentId, subcollectionName, subDocId, updatedData) => {
  try {
    if (documentId && subDocId) {
      // Construimos la referencia al documento de la subcolección
      const subDocRef = doc(db, collectionName, documentId, subcollectionName, subDocId);

      // Actualizamos el documento con los datos proporcionados
      await updateDoc(subDocRef, updatedData);
      return true;
    } else {
      console.error("DocumentId o subDocId no proporcionado");
      return false;
    }
  } catch (error) {
    console.error("Error al actualizar el documento en la subcolección:", error);
    return false;
  }
};


const createSubcollectionDocument = async (collectionName, documentId, subcollectionName, subDocId, data) => {
  try {
    if (documentId && subDocId) {
      // Construir la referencia al documento en la subcolección
      const subDocRef = doc(db, collectionName, documentId, subcollectionName, subDocId);
      
      // Crear o sobrescribir el documento en la subcolección
      await setDoc(subDocRef, data);
      
      return true;
    } else {
      console.error("DocumentId o subDocId no proporcionado");
      return false;
    }
  } catch (error) {
    console.error("Error al crear el documento en la subcolección:", error);
    return false;
  }
};

const deleteSubcollectionDocument = async (collectionName, documentId, subcollectionName, subDocId) => {
  try {
    if (documentId && subDocId) {
      // Construir la referencia al documento en la subcolección
      const subDocRef = doc(db, collectionName, documentId, subcollectionName, subDocId);

      // Eliminar el documento de la subcolección
      await deleteDoc(subDocRef);

      return true;
    } else {
      console.error("DocumentId o subDocId no proporcionado");
      return false;
    }
  } catch (error) {
    console.error("Error al eliminar el documento en la subcolección:", error);
    return false;
  }
};

const addToSubcollectionArray = async (collectionName, documentId, subcollectionName, subDocId, arrayKey, newValue) => {
  try {
    const subDocRef = doc(db, collectionName, documentId, subcollectionName, subDocId);
    await updateDoc(subDocRef, {
      [arrayKey]: arrayUnion(newValue),
    });
    return true;
  } catch (error) {
    console.error("Error al agregar al array en la subcolección:", error);
    return false;
  }
};
const removeFromSubcollectionArray = async (collectionName, documentId, subcollectionName, subDocId, arrayKey, valueToRemove) => {
  try {
    const subDocRef = doc(db, collectionName, documentId, subcollectionName, subDocId);
    await updateDoc(subDocRef, {
      [arrayKey]: arrayRemove(valueToRemove),
    });
    return true;
  } catch (error) {
    console.error("Error al eliminar del array en la subcolección:", error);
    return false;
  }
};

const updateArrayInSubcollection = async (collectionName, documentId, subcollectionName, subDocId, arrayKey, arrayToRemove, arrayAdd) => {
  const batch = writeBatch(db);
  const subDocRef = doc(db, collectionName, documentId, subcollectionName, subDocId);

  try {
    // Actualizamos el array eliminando el valor y luego agregando el nuevo
    batch.update(subDocRef, {
      [arrayKey]: arrayRemove(arrayToRemove),
    });
    batch.update(subDocRef, {
      [arrayKey]: arrayUnion(arrayAdd),
    });

    // Confirmamos las operaciones del batch
    await batch.commit();
    return true;
  } catch (error) {
    console.error('Error al actualizar el array en la subcolección:', error);
    return false;
  }
};



  return {
    updateDocument,
    addToArray,
    removeFromArray,
    createDocument,
    deleteDocument,
    updateArray,
    getDocumentsByQuery,
    deleteFolderStorageAndContents,
    getDocumentById,
    getDocumentFromSubcollection,
    getAllDocumentsFromSubcollection,
    updateSubcollectionDocument,
    createSubcollectionDocument,
    deleteSubcollectionDocument,
    addToSubcollectionArray,
    removeFromSubcollectionArray,
    updateArrayInSubcollection,
  };
};

export default useFirebaseCRUD;
