import { useEffect, useRef, useState } from 'react';
import { BsFillMicFill, BsSend } from 'react-icons/bs';
import styles from './ChatInputMessage.module.css'; // Ajusta la ruta de tu archivo CSS
import { TieredMenu } from 'primereact/tieredmenu';
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { Button } from 'primereact/button';
import VoiceMediaRecorderBtn from './VoiceMediaRecorderBtn';
import { useChatMenu } from '../ChatMenuContext';
import TextAreaInputMessage from './TextAreaInputMessage';
import { IoIosVolumeHigh } from 'react-icons/io';

const ChatInputMessage = () => {
  const [audioUrl, setAudioUrl] = useState(null); // Estado para almacenar la URL del audio grabado
  const menu = useRef(null);
  const [visibleMicrophoneHandler, setVisibleMicrophoneHandler] = useState();
  
  const {
    chatMessagesExample, 
    selectedMenuOption, 
    messageData, 
    scrollToElementById,
    setImagePreviewUrls,
    setDocumentFiles,
    setErrorMessage
  } = useChatMenu();
  
  const textAreaRef = useRef(null); 

  const [ responseMode, setResponseMode ] = useState(chatMessagesExample && selectedMenuOption?.option=== 1 ? true : false);
  
  
  const [selectedShortcut, setSelectedShortcut] = useState(false);

const [filteredShortcuts, setFilteredShortcuts] = useState([]);
const [showShortcuts, setShowShortcuts] = useState(false);
const [textValue, setTextValue] = useState('');


const shortcutListRef = useRef(null);



  // Función para manejar el menú de opciones para cada cliente en la tabla

  const optionMenu = (e) => {
    menu.current.toggle(e);
  };

  const handleAudioRecording = async (audioBlob) => {
    if (!audioBlob) {
      console.error("audioBlob is null or undefined");
      return;
    }

    const reader = new FileReader();
    console.log("Iniciando lectura del blob:", audioBlob);

    reader.onloadend = () => {
      const base64data = reader.result;
      console.log("Datos base64 obtenidos:", base64data);
      setAudioUrl(base64data);
    };

    reader.onerror = (error) => {
      console.error("Error al leer el blob:", error);
    };

    reader.readAsDataURL(audioBlob);
  };


  async function checkMicrophone() {
    try {
      console.log("Micrófono accesible.",  navigator);

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log("Micrófono accesible.");
      stream.getTracks().forEach(track => track.stop()); // Detener el stream inmediatamente
    } catch (error) {
      console.error("Error al acceder al micrófono:", error);
    }
  }

  const handleRecorVoiceMessage = () => {
    setVisibleMicrophoneHandler(true);
  }


  const handleCancelResponse = () => {
    // setSelectedMenuOption((prevState) =>  ({...prevState, option: null}));
    setResponseMode(false)
  } 
  const [isDragging, setIsDragging] = useState(false); 


  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true); 
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false); 
  };


  const handleClickOutside = (event) => {
    if (textAreaRef.current && !textAreaRef.current.contains(event.target)) {
      // Forzar blur del textarea
      textAreaRef.current.blur();
    }
    setIsDragging(false)
  };


  const handleShortcutSelect = (shortcut) => {
    setTextValue(`/${shortcut.content} `); // Agrega el atajo seleccionado al `textarea`
    setShowShortcuts(false); // Oculta la lista de atajos
  };
  

  // Hook para manejar clics fuera del textarea
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  
  useEffect(() => {
    if(chatMessagesExample && selectedMenuOption?.option=== 1) {
      setResponseMode(true);
    } else {
      setResponseMode(false);
    }
  }, [selectedMenuOption])




const selectDocuments = () => {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = '.pdf,.doc,.docx,.txt,.xls,.xlsx,.ppt,.pptx';
  input.onchange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (
        fileType === 'application/pdf' ||
        fileType.startsWith('application/vnd.openxmlformats-officedocument')
      ) {
        const newDocument = { name: file.name, url: URL.createObjectURL(file) };
        setDocumentFiles((prev) => [...prev, newDocument]);
        setErrorMessage('');
      } else {
        setErrorMessage('Tipo de archivo no admitido. Solo se permiten documentos PDF/DOCX.');
      }
    }
  };
  input.click();
};

// Función para seleccionar fotos o videos y actualizar el estado
const selectMedia = () => {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*,video/*';
  input.onchange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviewUrls((prev) => [...prev, reader.result]);
        };
        reader.readAsDataURL(file);
        setErrorMessage('');
      } else {
        setErrorMessage('Tipo de archivo no admitido. Solo se permiten imágenes y videos.');
      }
    }
  };
  input.click();
};

const menuItems = MenuItems({ 
  selectMedia,
  selectDocuments,
});

console.log("messageData", messageData)
  return (
    <div className={styles.chatInputMessageWrapper}>
      {/* ventana de atajos */}
      {showShortcuts && (
        <div 
            className={styles.shortcutListContainer} 
            ref={shortcutListRef}
            style={{ bottom: responseMode ? "212px" : "90px" }} // Cambia la posición de 'bottom' según el estado
        >
          {filteredShortcuts?.length > 0 ? (
            filteredShortcuts.map((shortcut, index) => (
              <div
                key={index}
                className="tooltip-item"
                onClick={() => handleShortcutSelect(shortcut)}
              >
                <span style={{ fontWeight: "bold" }}>{shortcut?.title}</span>: {shortcut.content}
              </div>
            ))
          ) : (
            <div className="tooltip-item">No hay atajos disponibles</div>
          )}
        </div>
      )}
      {/* modo de respuesta del contenedor */}
      {responseMode === true && (
          <div className={styles.chatInputContainerResponse} onClick={() => scrollToElementById(messageData?.messageId)}>
            <div className={styles.responseHeader} >
              <span className={styles.usernameResponse}>{messageData?.username}</span>

              <p className={styles.messageText}>
              {messageData?.messageText}
              {messageData?.audioSrc && (
                <div className={styles.audioIconContainer}>
                  <IoIosVolumeHigh className={styles.icon} /> {/* Ajusta el icono según tu preferencia */}
                </div>
              )}
            </p>
            </div>
            

            {(messageData?.videoSrc || messageData?.imageSrc) && (
              <div className={styles.mediaContainer}>
                {messageData?.videoSrc && (
                  <video className={styles.thumbnail} muted>
                  <source src={messageData.videoSrc} type="video/mp4" />
                  Tu navegador no soporta el elemento de video.
                </video>
                )}
                {messageData?.imageSrc && (
                  <img src={messageData.imageSrc} alt="Contenido" className={styles.thumbnail} />
                )}
              </div>
            )}

            < button onClick={handleCancelResponse} className={styles.closeBtnResponse}> &times;</button>
            </div>
            )}

      {/* contenedor principal de un mensaje */}
      <div className={styles.chatInputContainer}>
        <div className={styles.chatInput}>
          {!visibleMicrophoneHandler && (
            <div 
              style={{display:"flex", justifyContent: "between"}}
            >
                <TextAreaInputMessage
                  onDragLeave={handleDragLeave}
                  onDragOver={handleDragOver}
                  selectedShortcut={selectedShortcut}
                  setSelectedShortcut={setSelectedShortcut} 
                  isDragging={isDragging}
                  setIsDragging={setIsDragging}
                  textValue={textValue}
                  setTextValue={setTextValue}
                  filteredShortcuts={filteredShortcuts}
                  setFilteredShortcuts={setFilteredShortcuts}
                  showShortcuts={showShortcuts}setShowShortcuts={setShowShortcuts}
                />
              <div
                style={{display:"flex", margin: "5px 2px"}}
                >
                <button 
                    onClick={handleRecorVoiceMessage}
                  >
                    <BsFillMicFill 
                        style={{
                          cursor: "pointer",              
                          fontSize: "21px"
                        }}
                        className={styles.sendIcon} />
                </button>
            
                  <TieredMenu model={menuItems} popup ref={menu} breakpoint="767px" />
                  <Button
                    style={{
                      backgroundColor: "transparent",
                      color: "inherit",              
                      border: "none",                
                      boxShadow: "none",              
                      cursor: "pointer",              
                      fontSize: "25px"
                    }}
                    icon={<MdOutlineAddCircleOutline />}
                    onClick={(e, index) => optionMenu(e, index)}
                  />
              </div>
            </div>
        )}
          {/* Componente para grabar audio */}
        { visibleMicrophoneHandler && ( 
            <VoiceMediaRecorderBtn 
              onAudioRecorded={handleAudioRecording} 
              setVisibleMicrophoneHandler={setVisibleMicrophoneHandler}  
            />)}
        </div>

        {/* Botón de enviar mensaje */}

        <button className={styles.sendButton}
          onClick={() => checkMicrophone()}
        
        >
          <BsSend className={styles.sendIcon} />
        </button>



        {/* Reproductor de audio para escuchar la grabación */}
        {audioUrl && (
          <>
          <audio controls src={audioUrl} type="audio/webm" className="w-36" />
          </>
        )}
      </div>
    </div>
  );
};

export default ChatInputMessage;

export const MenuItems = ({
  selectMedia,
  selectDocuments,
}) => [
  {
    label: "Documento",
    icon: "pi pi-fw pi-image",
    command: () => {
      selectDocuments();
    },
  },
  {
    label: "Fotos y videos",
    icon: "pi pi-fw pi-file",
    command: () => {
      selectMedia();
    },
  },
];
