import PropTypes from "prop-types"
import styles from "./ShoppexStoreColors.module.css"

import { ColorPicker } from "primereact/colorpicker";
import { useEffect } from "react";
import convertColorHexToRgb from "../../utils/convertColorHexToRgb.js";
import { Divider } from "primereact/divider";

const ShoppexStoreColors = ({ storeColorsValues, setShoppexStoreValues}) => {
 const storeColorsInitialValues = {
  "main-color": storeColorsValues["main-color"] || "#AF65E8", // Pendiente
  "main-semilight-color": storeColorsValues["main-semilight-color"] || "#CEA2E9",
  "main-light-color": storeColorsValues["main-light-color"] || "#F6EBFC",
  "main-hard-color": storeColorsValues["main-hard-color"] || "#92278F",
  "main-light-color-rgb": storeColorsValues["main-light-color-rgb"] || [0, 0, 0.5],

  "secondary-color": storeColorsValues["secondary-color"] || "#3658EF", // Pendiente
  "secondary-light-color": storeColorsValues["secondary-light-color"] || "#DBE1FC",
  "secondary-hard-color": storeColorsValues["secondary-hard-color"] || "#0023A0",
  "secondary-semilight-color": storeColorsValues["secondary-semilight-color"] || "#6480EA",

  "third-color": storeColorsValues["third-color"] || "#42D3D8", // Pendiente
  "third-light-color": storeColorsValues["third-light-color"] || "#d4feff",
  "third-semilight-color": storeColorsValues["third-semilight-color"] || "#1CD0AB",
  "third-hard-color": storeColorsValues["third-hard-color"] || "#009B97",
};


  const handleInputChange = (storeId, color) => {

    if (storeId === "main-light-color") {
         const rgbColor = convertColorHexToRgb(color);
        setShoppexStoreValues(prevStyles => ({
          ...prevStyles,
          store_styles: {
            ...storeColorsValues,
            [storeId]: `#${color}`,
            "main-light-color-rgb": rgbColor,
          }
        }));
    } else {
      setShoppexStoreValues(prevStyles => ({
        ...prevStyles,
        store_styles:{
            ...storeColorsValues,
            [storeId]: `#${color}`,
          }
      }));
    }
  };

const customizeColorPickerPreviews = () => {
  const colorPickerPreviews = document.querySelectorAll('.p-colorpicker-preview');
  if (colorPickerPreviews) {
    const updateWidth = () => {
      const isSmallViewport = window.matchMedia("(min-width: 500px)").matches;
      const width = isSmallViewport ? '8rem' : '4rem';
      colorPickerPreviews.forEach(colorPickerPreview => {
        colorPickerPreview.style.width = width;
        colorPickerPreview.style.height = '4rem';
        colorPickerPreview.style.borderRadius = '15%';
      });
    };

    updateWidth(); 
    // Escuchar el evento resize y ejecutar la función
    window.addEventListener('resize', updateWidth);
  }
};

 useEffect(() => {
    customizeColorPickerPreviews();
     setShoppexStoreValues(prevStyles => ({
        ...prevStyles,
        store_styles:{
            ...storeColorsValues,
            ...storeColorsInitialValues,
          }
      }));
  }, []); 

  return (
    <div>
      <h4>Colores </h4>
        <Divider/>
      <div className="w-full grid grid-rows-4 grid-flow-col gap-4 md:justify-start justify-center mb-8 mt-5 lg:justify-center">
        {[
          { id: "main-color", label: "Principal" },
          { id: "main-hard-color", label: "Principal duro" },
          { id: "main-semilight-color", label: "Principal  Normal" },
          { id: "main-light-color", label: "Principal Claro" },
          
          { id: "secondary-color", label: "Secundario" },
          { id: "secondary-hard-color", label: "Secundario duro" },
          { id: "secondary-semilight-color", label: "Secundario Normal" },
          { id: "secondary-light-color", label: "Secundario Claro" },

          { id: "third-color", label: "Terciario" },
          { id: "third-hard-color", label: "Terciario duro" },
          { id: "third-semilight-color", label: "Terciario Normal" },
          { id: "third-light-color", label: "Terciario Claro" },

        ].map(color => (
          <div key={color?.id}>
            <ColorPicker
              value={storeColorsValues[color?.id]} // Asocia el valor del color picker con el valor correspondiente en stores_styles
              onChange={(e) => handleInputChange(color?.id, e.value)} // Actualiza el color en stores_styles
            />

            <p className={styles.colorPickerLabel}>{color?.label}</p>
          </div>
        ))}
      </div>

    </div>
  );
};

ShoppexStoreColors.propTypes = {
  storeColorsValues: PropTypes.object,
  setShoppexStoreValues: PropTypes.func,
}
export default ShoppexStoreColors;
