import PropTypes from "prop-types"
import { Dialog } from "primereact/dialog";
import styles from "./CustomModal.module.css";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext";

export default function CustomModal({ visible, setVisible, object, editMode, createMode, content, onHide, closable=true, position }) {

  // Importo la constante IsDesktop
    const { isDesktop } = useGlobalContext();

    let hasCreate = object
    if (object) { hasCreate = !!Object.keys(object)?.length }

    // Logica con el hook useResize que SI detecta cambios en la pantalla 
    // const [ isMobile, setIsMobile ] = useState(false);
    //   useResize(setIsMobile)
    //   useEffect(() => {
    //     if (isMobile) {
    //       console.log("Es mobile")
    //     }else {
    //       console.log("No es mobile")
    //     }
    //   }, [isMobile])

  return (
    <div className="card flex justify-content-center">
      <Dialog
        position={position}
        closable={closable}
        header={!hasCreate ?  createMode : editMode}
        visible={visible}
        className={`${styles.pDialogMask} `}

        
        style={!isDesktop  ? 
          { width: "95vw", background: "rgba(0, 0, 0, 1)" } : 
          { width: "80vw", background: "rgba(0, 0, 0, 1)" }}

        // className={`${styles.pDialogMask}  w-11/12 md:2/6`} <- Logica con tailwind


        onHide={() => { onHide ? onHide() : null ; setVisible(false) }}
      >
        {content}
        </Dialog>
    </div>
  );
}

CustomModal.propTypes = {
  content: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  object:  PropTypes.object,
  editMode:  PropTypes.string,
  createMode: PropTypes.string,
  onHide: PropTypes.func,
};
