import  { createContext, useState, useContext } from 'react';

// Crea el contexto
const ChatMenuContext = createContext();

// Proveedor del contexto
// eslint-disable-next-line react/prop-types
export const ChatMenuProvider = ({ children }) => {
  
  const [selectedMenuOption, setSelectedMenuOption] = useState(null);
  const [messageData, setMessageData] = useState(null); // Estado auxiliar para almacenar el mensaje previo


  const [imagePreviewUrls, setImagePreviewUrls] = useState([]);
  const [documentFiles, setDocumentFiles] = useState([]); 
  const [errorMessage, setErrorMessage] = useState('');


  const handleChatMessageMenu = ({ type, messageId, messageText, videoSrc, audioSrc, username, imageSrc, idMessageRepliying}) => {
    console.log("type", type)
    // Siempre actualiza messageData al seleccionar un mensaje
    setMessageData({ messageId, messageText, videoSrc, audioSrc, username, imageSrc, type, idMessageRepliying});
    
    // Si se selecciona un nuevo mensaje, también podemos configurar la opción inicial a null (o cualquier otra lógica que necesites)
    setSelectedMenuOption((prevState) => ({
      ...prevState,
      option: null, // O mantener la opción anterior si deseas
    }));
  };

  const handleResponseMessage = ({ messageId, messageText, videoSrc, audioSrc, username, imageSrc, type, idMessageRepliying }) => {
    const isSameMessage =
      selectedMenuOption?.messageId === messageId &&
      selectedMenuOption?.messageText === messageText &&
      selectedMenuOption?.videoSrc === videoSrc &&
      selectedMenuOption?.audioSrc === audioSrc &&
      selectedMenuOption?.imageSrc === imageSrc &&
      selectedMenuOption?.type === type &&
      selectedMenuOption?.idMessageRepliying === idMessageRepliying &&
      selectedMenuOption?.username === username;

      console.log("messageId", messageId)

    if (!isSameMessage) {
      // Si el mensaje es diferente, actualiza selectedMenuOption y messageData
      setSelectedMenuOption({
        messageId,
        messageText,
        videoSrc,
        audioSrc,
        username,
        imageSrc,
        type,
        idMessageRepliying: messageId,
        option: 1, // Cambia la opción a 1 para indicar "respuesta"
      });
      setMessageData({ messageId, messageText, videoSrc, audioSrc, imageSrc, username, type, idMessageRepliying: messageId});
    } else if (selectedMenuOption?.option !== 1) {
      // Si el mensaje es el mismo, mantiene la opción actual en 1
      setSelectedMenuOption((prevState) => ({
        ...prevState,
        option: 1,
      }));
    }
  };

  const handleFoo = () => {
    setSelectedMenuOption((prevState) => ({
      ...prevState,
      option: 2, // Cambia la opción a 2 para la acción "foo"
    }));
  };

  const menuChatOptions = () => [
    {
      label: "Responder",
      icon: "pi pi-fw pi-reply",
      command: () => handleResponseMessage(messageData),
    },
    {
      label: "foo",
      icon: "pi pi-fw pi-file",
      command: handleFoo,
    },
  ];

  const shortcuts = [
    { title: "saludo", content: "Hola, ¿en qué puedo ayudarte hoy?" },
    { title: "despedida", content: "Gracias por comunicarte, ¡hasta luego!" },
    { title: "preguntaProducto", content: "¿Podrías darme más detalles sobre el producto?" },
    { title: "confirmacionPedido", content: "Tu pedido ha sido confirmado y está en proceso." },
    { title: "seguimientoEnvio", content: "Puedes hacer seguimiento de tu envío con el siguiente enlace." },
    { title: "consultaHorario", content: "Nuestro horario de atención es de 9:00 a 18:00 de lunes a viernes." },
    { title: "informacionPago", content: "Aceptamos pagos mediante tarjeta de crédito, débito y transferencias bancarias." },
    { title: "tiempoEntrega", content: "El tiempo estimado de entrega es de 3 a 5 días hábiles." },
    { title: "devolucionProducto", content: "Puedes realizar la devolución del producto dentro de los 30 días posteriores a la compra." },
    { title: "promocionActual", content: "Actualmente tenemos un 20% de descuento en productos seleccionados." },
    { title: "soporteTecnico", content: "Nuestro equipo de soporte técnico está disponible para ayudarte con cualquier problema." },
    { title: "instruccionesRegistro", content: "Para registrarte, haz clic en el botón de 'Crear cuenta' y completa el formulario." },
    { title: "politicasPrivacidad", content: "Puedes consultar nuestras políticas de privacidad en nuestro sitio web." },
    { title: "ayudaAdicional", content: "Si necesitas ayuda adicional, no dudes en contactarnos por este medio." },
    { title: "felicitacionCumpleaños", content: "¡Feliz cumpleaños! Esperamos que tengas un gran día." },
    { title: "confirmacionCita", content: "Tu cita ha sido confirmada. Te esperamos en la fecha y hora acordadas." },
    { title: "recordatorioRenovacion", content: "Este es un recordatorio de que tu renovación se acerca. ¿Te gustaría renovarlo ahora?" },
    { title: "instruccionesDescarga", content: "Haz clic en el siguiente enlace para descargar el archivo que necesitas." },
    { title: "agradecimientoCompra", content: "Gracias por tu compra. Esperamos que disfrutes de tu producto." },
  ];

  const chatMessagesExample = [
    {
      id: 1,
      type: "text",
      avatarSrc: "https://randomuser.me/api/portraits/lego/1.jpg",
      username: "Cliente 1",
      time: "09:18 AM",
      messageText: "Hola buenas para unos zapaticos bien bacanos",
      isSent: false,
    },
    {
      id: 2,
      type: "text",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: "09:19 AM",
      messageText:"Se le tienen compadre",
      isSent: true,
    },
    {
      id: 3,
      type: "text",
      avatarSrc: "https://randomuser.me/api/portraits/lego/1.jpg",
      username: "Cliente 1",
      time: "09:20 AM",
      messageText: (
        <>
          <img src="image.png" alt="Linner"
          //  className={styles.messageImage}
            />
          Vi estos en la pagina, muy bacana x cierto, salu2 q valen?
        </>
      ),
      isSent: false,
    },
    {
      id: 4,
      type: "text",
      avatarSrc: "https://randomuser.me/api/portraits/lego/1.jpg",
      username: "Cliente 1",
      time: "09:25 AM",
      messageText: (
        <div>
          Tienes esta otra referencia? <br />
          <a href="https://www.instagram.com/p/Cq2AzG" target="_blank" rel="noopener noreferrer">
            https://www.instagram.com/p/Cq2AzGsadaayudasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdaayudaasdasdsadasdasdasd
          </a>
        </div>
      ),
      isSent: false,
    },
    {
      id: 5,
      type: "text",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: "09:19 AM",
      messageText: "20 millones brother",
      isSent: true,
    },
    {
      id: 6,
      type: "audio",
      avatarSrc: "https://randomuser.me/api/portraits/lego/1.jpg",
      username: "Cliente 1",
      time: "09:30 AM",
      audioSrc:"https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/Lil%20Tecca%20-%20DUI%20(Official%20Audio)%20-%20Lil%20Tecca.mp3?alt=media&token=0dde6991-f89a-44b5-bae3-0767ff2a31bb",
      isSent: false,
    },
    {
      id: 7,
      type: "video",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: "09:35 AM",
      messageText: "y que te parece esta otra referencia",
      videoSrc:"https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/shoppexStores%2FJDfK9GpHVJldVzqFKRGt%2Fsection%2F19DgQGqvLnOKsOqgMU4r%2Farchive?alt=media&token=a7214314-5e4a-4bfc-b854-9037ec14e355",
      isSent: true,
    },
    {
      id: 8,
      type: "image",
      avatarSrc: "https://randomuser.me/api/portraits/lego/1.jpg",
      username: "Cliente 1",
      messageText:"Mira es esta ejemplo ",
      time: "11:35 AM",
      imageSrc: "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/products%2F0ObagfvFDbPh1xF7p06g%2Fmain_img%2FHnX4Z55QXegAZhXRp1CM?alt=media&token=e3cad75b-fe77-4422-b365-f68314b10fae",
      isSent: false,
    },
    {
      id: 9,
      type: "image",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: "11:35 AM",
      messageText: "nuevo pedido",
      imageSrc: "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/products%2F0ObagfvFDbPh1xF7p06g%2Fmain_img%2FHnX4Z55QXegAZhXRp1CM?alt=media&token=e3cad75b-fe77-4422-b365-f68314b10fae",
      isSent: true,
      isRepliying: true,
      typeRepliying:"video",
      videoSrcRepliying:"https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/shoppexStores%2FJDfK9GpHVJldVzqFKRGt%2Fsection%2F19DgQGqvLnOKsOqgMU4r%2Farchive?alt=media&token=a7214314-5e4a-4bfc-b854-9037ec14e355",
      idMessageRepliying: 7,
    },
    {
      id: 10,
      type: "text",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: "11:35 AM",
      messageText: "20 millones brother",
      isSent: true,
      isRepliying: true,
      messageTextRepliying:"Mira es esta ejemplo ",
      typeRepliying:"image",
      imgSrcRepliying: "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/products%2F0ObagfvFDbPh1xF7p06g%2Fmain_img%2FHnX4Z55QXegAZhXRp1CM?alt=media&token=e3cad75b-fe77-4422-b365-f68314b10fae",
      idMessageRepliying: 8,
    },
    {
      id: 11,
      type: "text",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: "11:35 AM",
      messageText: "20 millones brother",
      isSent: true,
      isRepliying: true,
      typeRepliying:"text",
      messageTextRepliying: "Respuesta",
      idMessageRepliying: 10,
    }
  ];

  function scrollToElementById(idMessageRepliying) {
    console.log(idMessageRepliying);
    const element = document.getElementById(idMessageRepliying);
    if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
        console.warn(`Element with ID "${idMessageRepliying}" not found.`);
    }
  }


  return (
    <ChatMenuContext.Provider 
      value={{
        menuChatOptions,
        selectedMenuOption,
        setSelectedMenuOption,
        handleChatMessageMenu,
        chatMessagesExample,
        messageData,
        shortcuts,
        scrollToElementById,
        imagePreviewUrls, setImagePreviewUrls,
        documentFiles, setDocumentFiles,
        errorMessage, setErrorMessage
      }}>
      {children}
    </ChatMenuContext.Provider>
  );
};

// Hook personalizado para usar el contexto
export const useChatMenu = () => {
  return useContext(ChatMenuContext);
};
