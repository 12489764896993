import {  useRef, useState } from 'react';
import { FaFilePdf, FaFileWord, FaTimes } from 'react-icons/fa';
import { useChatMenu } from '../ChatMenuContext';
import styles from './TextAreaInputMessage.module.css' 


const TextAreaInputMessage = ({ isDragging, setIsDragging, selectedShortcut, setSelectedShortcut, textValue, setTextValue, setShowShortcuts, setFilteredShortcuts, onDragLeave,onDragOver}) => {
  const textAreaRef = useRef(null); 
  const { shortcuts } = useChatMenu();

  const {
    imagePreviewUrls, setImagePreviewUrls,
    documentFiles, setDocumentFiles,
    errorMessage, setErrorMessage
  } = useChatMenu();


  const handleDrop = (event) => {
    event.preventDefault();

    const files = Array.from(event.dataTransfer.files);

    if (files.length > 0) {
      const newImagePreviews = [];
      const newDocuments = []; 
      let isValid = true;

      files.forEach((file) => {
        const fileType = file.type;

        if (fileType.startsWith('image/')) {
          const reader = new FileReader();
          reader.onloadend = () => {
            newImagePreviews.push(reader.result);
            // setTextValue((prev) => `${prev} ![${file.name}](${reader.result})`);
            if (newImagePreviews.length === files.length) {
              setImagePreviewUrls((prev) => [...prev, ...newImagePreviews]);
            }
          };
          reader.readAsDataURL(file);
        } else if (fileType === 'application/pdf' || fileType.startsWith('application/vnd.openxmlformats-officedocument')) {
          newDocuments.push({ name: file.name, url: URL.createObjectURL(file) });
          // setTextValue((prev) => `${prev} [${file.name}](${URL.createObjectURL(file)})`);
        } else {
          isValid = false;
        }
      });

      if (newDocuments.length > 0) {
        setDocumentFiles((prev) => [...prev, ...newDocuments]);
      }

      if (!isValid) {
        setErrorMessage('Tipo de archivo no admitido. Solo se permiten imágenes y documentos PDF/DOCX.');
      } else {
        setErrorMessage(''); 
      }
    }
  };


  const removeImage = (index) => {
    setImagePreviewUrls((prev) => prev.filter((_, i) => i !== index));
  };

  const removeDocument = (index) => {
    setDocumentFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleTextAreaInput = (e) => {
    const value = e.target.value;
    setTextValue(value);

    if (value.startsWith('/')) {
      setShowShortcuts(true); // Muestra los atajos si el primer carácter es `/`
      const query = value.slice(1).toLowerCase(); // Remueve el `/` para filtrar
      const filtered = shortcuts.filter((shortcut) =>
        shortcut.title.toLowerCase().includes(query)
      );
      setFilteredShortcuts(filtered);
    } else {
      setShowShortcuts(false); // Oculta los atajos si el primer carácter no es `/`
      setFilteredShortcuts([]); // Limpia la lista de atajos filtrados
    }
  };
 
  
  return (
    <div className={styles.container}>
      <div 
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={handleDrop}        
        >
          <textarea
            ref={textAreaRef}
            value={textValue}
            className={`${styles.textArea} ${isDragging ? styles.textAreaDragging : ''}`}
            placeholder="Escribe / para ver atajos"
            onChange={handleTextAreaInput}
          />
      </div>
      <div className={styles.padding}>
        {errorMessage && (
          <div className={styles.errorMessage}>
            {errorMessage}
            <button
              className={styles.closeButton}
              onClick={() => setErrorMessage('')}
            >
              X
            </button>
          </div>
        )}
        {imagePreviewUrls.length > 0 && (
          <div className={styles.imageContainer}>
            {imagePreviewUrls.map((url, index) => (
              <div key={index} className={styles.imagePreview}>
                <img
                  src={url}
                  alt={`Vista previa ${index}`}
                  style={{
                    objectFit: 'cover',
                    maxWidth: '100px',
                    maxHeight: '100px',
                    minHeight: '100px',
                    minWidth: '100px',
                  }}
                />
                <button
                  className={styles.imageButton}
                  onClick={() => removeImage(index)}
                >
                  X
                </button>
              </div>
            ))}
          </div>
        )}
        {documentFiles.length > 0 && (
          <div className={styles.documentContainer}>
            {documentFiles.map((doc, index) => (
              <div key={index} className={styles.documentItem}>
                <span>
                  {doc.url.endsWith('.pdf') ? (
                    <FaFilePdf className={styles.pdfIcon} size={30} />
                  ) : (
                    <FaFileWord className={styles.wordIcon} size={30} />
                  )}
                </span>
                <span>{doc.name}</span>
                <button
                  className={styles.imageButton}
                  onClick={() => removeDocument(index)}
                >
                  <FaTimes />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextAreaInputMessage;
