import { BiCheckCircle, BiChevronLeft } from "react-icons/bi";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { useTranslation } from "react-i18next";
import CustomTable from "../../Components/CustomTable/CustomTable";
import AuxButton from "../../Components/AuxButton/AuxButton";
import { useEffect, useState } from "react";
import MainButton from "../../Components/MainButton/MainButton";
import { Toast } from "primereact/toast";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import CustomModal from "../../Components/CustomModal/CustomModal";
import DetailShopifyStoreForm from "../../Components/DetailShopifyStoreForm/DetailShopifyStoreForm";
import DetailCheckShopifyOrder from "../../Components/DetailCheckShopifyOrder/DetailCheckShopifyOrder";

import { useAuthContext } from "../../contexts/Authcontext/Authcontext";
import useShopifyRequest from "../../hooks/useShopifyRequest";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";

// Componente que se encarga de mostrar y seleccionar las tiendas de Shopify asociadas al seller
export default function Stores() {
  const { userData } = useAuthContext();
  const { checkShopifyProductConnection } = useShopifyRequest();
  const { updateDocument } = useFirebaseCRUD();
  const { t } = useTranslation("Stores");
  const { toast } = useShopifyRequest();
  const { globalLoading } = useLoaderContext();

  const [stores, setStores] = useState(userData?.seller_stores);
  const [visibleStoreForm, setVisibleStoreForm] = useState(false);
  const [visibleCheckShopifyOrderModal, setVisibleCheckShopifyOrderModal] = useState(false);

  const [rowCheckProductStates, setRowCheckProductStates] = useState({});

  const [selectedSellerStore, setselectedSellerStore] = useState();
  const [selectedRowData, setSelectedRowData] = useState();

  const CustomStoresTableColumns = [
    { field: "name", header: t("table-headers.name") },
    { field: "platform.name", header: t("table-headers.platform") },
    { field: "store_url", header: t("table-headers.url") },
    {
      field: "synced_products",
      header: t("table-headers.associatedProducts"),
      body: (rowData) => (
        <div className="flex justify-center">
          {rowData?.synced_products?.length}
        </div>
      ),
    },
    // Columna para verificar la conexión de productos con Shopify
    {
      field: "",
      header: t("table-headers.connectionProducts"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div className="flex">
          {rowCheckProductStates[rowData.id] && (
            <BiCheckCircle
              style={{
                backgroundColor: "var(--main-color)",
                borderRadius: "100%",
                fontSize: "var(--font-size-h2)",
              }}
            />
          )}
          <AuxButton
            data={rowData}
            disable={false}
            label={t("testConnectionProducts")}
            style={{
              backgroundColor: "var(--main-color)",
              color: "white",
              fontSize: "var(--font-size-h5)",
            }}
            onClick={() => checkProductShopifyConnection(rowData)}
          />
        </div>
      ),
    },
    {
      // Columna para verificar la conexión de pedidos con Shopify

      field: "",
      header: t("table-headers.connectionOrders"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <div className="flex">
          <AuxButton
            data={rowData}
            disable={false}
            label={t("testConnectionProducts")}
            style={{
              backgroundColor: "var(--main-color)",
              color: "white",
              fontSize: "var(--font-size-h5)",
            }}
            onClick={() => checkOrdersShopifyConnection(rowData)}
          />
        </div>
      ),
    },
    // Columna para editar la tienda

    {
      header: "Editar",
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <AuxButton
          data={rowData}
          disable={false}
          label={t("edit")}
          style={{
            backgroundColor: "var(--main-color)",
            color: "white",
            fontSize: "var(--font-size-h5)",
          }}
          onClick={openStoresModal}
        />
      ),
    },
  ];
  // Función para verificar la conexión de productos con Shopify
  const checkProductShopifyConnection = async (rowData) => {
    console.log(rowData);
    const body = {
      shopifyUrl: rowData.store_url,
      tokenId: rowData.product_sync_shopify_app_token,
    };
    try {
      // Realiza la verificación de conexión de productos con Shopify

      const resultCheckConnection = await checkShopifyProductConnection(body);
      console.log(resultCheckConnection);
      if (
        resultCheckConnection.data.isConnected &&
        resultCheckConnection.status === 200
      ) {
        toast.current.show({
          severity: "success",
          summary: t("toast-summary.succefullProductsConnection"),
          detail: rowData.name,
          life: 8000,
        });
        setRowCheckProductStates((prevrowEditStates) => ({
          ...prevrowEditStates,
          [rowData.id]: true,
        }));
      }
      if (resultCheckConnection.data.productsLength === 0) {
        toast.current.show({
          severity: "warn",
          summary: t("toast-summary.noShopifyProducts"),
          detail: rowData.name,
          life: 8000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: t("toast-summary.checkProductConnectionError"),
        detail: rowData.name,
        life: 8000,
      });
      setRowCheckProductStates((prevrowEditStates) => ({
        ...prevrowEditStates,
        [rowData.id]: false,
      }));
    }
  };
  // Función para verificar la conexión de pedidos con Shopify
  const checkOrdersShopifyConnection = async (rowData) => {
    try {
      // Abre el modal para verificar la conexión de pedidos
      openCheckOrderModal(true);
      setSelectedRowData(rowData);
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: "error",
        summary: t("Error de conexion en productos"),
        detail: "",
        life: 8000,
      });
    }
  };
  // Función para abrir el modal de tiendas
  const openStoresModal = (store) => {
    setVisibleStoreForm(true);
    if (store.id) {
      setselectedSellerStore(store);
    } else {
      setselectedSellerStore({});
    }
  };
  // Función para cerrar el modal de tiendas
  const closeStoresModal = () => {
    setVisibleStoreForm(false);
  };
  // Función para abrir el modal de verificación de orden en Shopify
  const openCheckOrderModal = () => {
    setVisibleCheckShopifyOrderModal(true);
  };
  // Función para cerrar el modal de verificación de orden en Shopify
  const closeCheckOrderModal = () => {
    setVisibleCheckShopifyOrderModal(false);
  };
  // Manejador de evento al ocultar el componente que actualiza el campo test_order_notification_status como false al cerrar el componente
  const handleOnHide = async () => {
    try {
      await updateDocument("seller_users", userData.uid, {
        ...userData,
        test_order_notification_status: false,
      });
    } catch (error) {
      console.error(
        "Error al actualizar el campo 'test_order_notification_status':",
        error
      );
    }
  };

  // Efecto secundario para actualizar las tiendas cuando cambia alguna tienda del usuario
  useEffect(() => {
    if (userData.seller_stores) {
      const filteresShopifyStores = userData.seller_stores.filter((store) => {
        return store?.platform?.code === 2;
      });
      setStores(filteresShopifyStores);
    }
  }, [userData]);
  return (
    <div className="mb-20">
      <div style={{ marginBottom: "40px" }}>
        <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtn")} />
      </div>      
        <CustomTable
            className="custom-table"
            columns={CustomStoresTableColumns} data={stores} />
      <div>
        <CustomModal
          visible={visibleStoreForm}
          setVisible={setVisibleStoreForm}
          object={selectedSellerStore}
          // menu_option={{label:t('menu-options.home')}}
          editMode={t("updateStore")}
          createMode={t("createStore")}
          content={
            <DetailShopifyStoreForm
              onClose={setVisibleStoreForm}
              selectedSellerStore={selectedSellerStore}
              userData={userData}
              labelDelete={t("deleteStore")}
              labelSuccess={t("createStore")}
              toast={toast}
            />
          }
          onClose={closeStoresModal}
        />
      </div>
      <div>
        <CustomModal
          visible={visibleCheckShopifyOrderModal}
          setVisible={setVisibleCheckShopifyOrderModal}
          object={selectedSellerStore}
          onHide={handleOnHide}
          content={
            <DetailCheckShopifyOrder
              onClose={setVisibleCheckShopifyOrderModal}
              selectedRowData={selectedRowData}
              userData={userData}
              labelDelete={t("deleteStore")}
              labelSuccess={t("createStore")}
              toast={toast}
            />
          }
          onClose={closeCheckOrderModal}
        />
      </div>

      <div style={{ marginTop: "100px" }}>
        <MainButton
          loading={globalLoading}
          label={t("createStore")}
          onClick={openStoresModal}
        />
      </div>
      <Toast ref={toast} />
    </div>
  );
}
