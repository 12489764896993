import { useEffect, useRef, useState } from "react";
import { updateSKUsStock } from "../../utils/updateSKUsStock";
import { FaRegFileExcel } from "react-icons/fa6";
import MainButton from "../MainButton/MainButton";
import CustomTable from "../CustomTable/CustomTable";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog";
import { generateStockUpdatesByExcelData } from "../../pages/MyOrders/scripts/stockRecord";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import toastFunction from "../../utils/toastFunction";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import CustomModal from "../CustomModal/CustomModal";
import { Messages } from "primereact/messages";
import SecondaryButton from "../SecondaryButton/SecondaryButton";
import { InputNumber } from "primereact/inputnumber";
import DeleteButton from "../DeleteButton/DeleteButton";
import { Button } from "primereact/button";
import styles from "./DetailUpdateStockExcelTable.module.css"
import { generateRandomId } from "../../utils/generateRandomId";

// eslint-disable-next-line react/prop-types
const DetailUpdateStockExcelTable = ({toast, t, onClose, selectedProduct, handleToggleStockMode}) => {
    const excelTableExampleImgSrc = "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/Assets%2Fejemplo%20encabezados%20tabla%20excel%20para%20actualizar%20stock.jpeg?alt=media&token=46482d8d-bc72-4a93-b8f5-1f8bf3519f1d"
    const fileInputRef = useRef(null); // Crear un ref para el input de tipo file
    const msgsUpdateExcelData = useRef(null);

    const { globalLoading, setGlobalLoading} = useLoaderContext();
   
    const [excelTableData, setExcelTableData] = useState([]);
    const [visibleConfirmUpdateStockByExcelDialog, setVisibleConfirmUpdateStockByExcelDialog] = useState(false)
   
    const [selectedRecordType, setSelectedRecordType] = useState()
    const [messagesShown, setMessagesShown] = useState(false);
    
    const [visibleFailedSkusTableModal, setVisibleFailedSkusTableModal] = useState(false);
    const  [failedSkusData, setFailedSkusData] = useState([]);


    const handleInputBalanceStock = (id, fieldName, value) => {
        // Actualizar el estado solo si el valor es válido
        setExcelTableData((prevStockItems) =>
          prevStockItems.map((item) =>
            item.id === id ? { ...item, [fieldName]: parseInt(value) } : item
          )
        );
    };
    const handleInputStock = (id, fieldName, value) => {
        setExcelTableData((prevStockItems) =>
            prevStockItems.map((item) =>
              item.id === id ? { ...item, [fieldName]: value } : item
            )
          );
    }

    const validateQuantitys = () => {
        console.log(excelTableData);
        const isQtyNegative = excelTableData.some((row) => row.qty < 0);
        const findedNegativeValue = excelTableData.find((row) => row.qty < 0);

        if(isQtyNegative) {
            toastFunction(toast, "warn", "No pueden haber skus con cantidades negativas", findedNegativeValue.sku)
        }
        return isQtyNegative;
    }

    const updateStock = async () => {
        setGlobalLoading(true);
        if (validateQuantitys()) {
            return
        }
        const resultGenerateStockUpdates = await generateStockUpdatesByExcelData({
            selectedRecordType,
            excelTableData,
            selectedProduct
        });
        console.log("resultGenerateStockUpdates", resultGenerateStockUpdates);
        if(resultGenerateStockUpdates.length === 0) {
            onClose()
            handleToggleStockMode(false);
        }else {
            setVisibleFailedSkusTableModal(true);
            setFailedSkusData(resultGenerateStockUpdates);
        }
        setGlobalLoading(false);
    }

    const handleButtonClick = () => {
        fileInputRef.current.click(); // Simular un clic en el input file cuando se presiona el botón
    };
    
    const hanldeSelectRecordtype = (recordType) => {
        setSelectedRecordType(recordType)
    };
    const handleDeleteBtn = (rowData) => {
        console.log("fila a eliminar", rowData)
        const excelDataWithoutSelectedRow = excelTableData.filter((row) => row.id !== rowData.id);
        setExcelTableData(excelDataWithoutSelectedRow)
    }
    
    console.log("excelTableData", excelTableData)

   

    const stockRecordTypes = [
        {name: "Ingreso ajuste positivo", key: "ingreso_ajuste_positivo", code: 1},
        {name: "Ingreso futura recepcion de producto", key: "ingreso_futura_recepcion_producto", code: 2},
        {name: "Ingreso recepcion de producto", key: "ingreso_recepcion_producto", code: 3},
        {name: "Ingreso producto devuelto", key: "ingreso_producto_devuelto", code: 4},
        {name: "Ingreso por PQR", key: "ingreso_pqr", code: 5},
        {name: "Salida ajuste negativo", key: "salida_ajuste_negativo", code: 6},
        {name: "Salida reserva de producto", key: "salida_reserva_producto", code: 7},
        {name: "Salida producto despachado", key: "salida_producto_despachado", code: 8},
        {name: "Salida por PQR", key: "salida_pqr", code: 9},
        {name: "Ingreso por cancelacion", key: "ingreso_por_cancelacion", code: 10},
    ];

    const addMessage = () => {
        if(msgsUpdateExcelData.current) {
          msgsUpdateExcelData.current.show([
            {
              severity: "info",
              summary: "Info",
              detail: t("toast-detail.infoExcelFormatTable"),
              sticky: true,
              closable: false,
            },
          ]);
        }
    };
    
    const handleAddExcelTableRow = () => {
        const newRowExcelTable = {
            id: generateRandomId(),
            sku: "",
            qty:0,
        }
        setExcelTableData((prevState) =>  {
            return [newRowExcelTable, ...prevState]
        })
    }
    const excelStockRecordsColumns = [
        { 
            field: "sku",
            header: t("table-headers.sku"),
            filterable: false,
            sortable: false, 
            body: (rowData) => (
                <CustomInputText
                    placeholder={t("input-placeholders.digitSku")}
                    value={rowData?.sku}
                    inColumTable={true}
                    onChange={(e) => handleInputStock(rowData.id, "sku", e.target.value)}
                />
            )
        },
        { 
            field: "qty",
            header: t("table-headers.qty"),
            filterable: false,
            sortable: false,
            body: (rowData) => (
              <>
               <InputNumber
                        mode="decimal"
                        useGrouping={false}
                        placeholder={t("input-placeholders.qty")}
                        value={rowData?.qty}
                        onValueChange={(e) => handleInputBalanceStock(rowData.id, "qty", e.value)}
                    />
               
              </>
            ) 
          },
          { 
            filterable: false,
            sortable: false,
            body: (rowData) => (
              <>
               <DeleteButton
                        onClick={() => handleDeleteBtn(rowData)}
                        type={"button"}
                        icon={"x"}
                        label={"Eliminar fila"}
                    />
               
              </>
            ) 
          },
    ];

    const failedSkusColumns = [
        {   
            field: "sku",
            header: t("table-headers.skus"),
            filterable: false,
            sortable: false, 
        },
        {   
            field: "qty",
            header: t("table-headers.qty"),
            filterable: false,
            sortable: false, 
        },
    ];
    const handleCloseFailedSkusTable = () => {
        onClose();
        handleToggleStockMode(false);
    } 
      useEffect(() => {
        const delay = setTimeout(() => {
          if (!messagesShown) {
            addMessage();
            setMessagesShown(true);
          }
        }, 100);
      
        return () => clearTimeout(delay);
      }, [messagesShown]);


    return (
        <>
        <Messages ref={msgsUpdateExcelData} />
        <img 
            className="w-full bg-cover mb-4 mt-2 shadow-lg"
            src={excelTableExampleImgSrc}
            />
        <CustomSelect
            floatLabel={true}
            optionLabel={"name"}
            options={stockRecordTypes}
            valueTemplate={""}
            itemTemplate={""}
            value={selectedRecordType}
            placeholder={t("input-placeholders.productName")}
            onChange={(e) => hanldeSelectRecordtype(e.target.value)}
        />
        
         <div
            htmlFor="chargeSKUsStock"
            className="flex mt-4 mb-8"
            >
            <MainButton 
                onClick={handleButtonClick}
                type={"button"}
                icon={
                    <FaRegFileExcel style={{fontSize:"30px"}}/>
                }
                label={t("loadExcelArchive")}
                disabled={!selectedRecordType}

            />
        </div>
        <div style={{marginLeft:"15px"}}>
            <input
                ref={fileInputRef} 
                id='chargeSKUsStock' 
                type="file"
                onChange={(e) => updateSKUsStock(e.target.files[0], setExcelTableData)} 
                style={{display: 'none'}} 
            />
        </div>
        <Button
            className={styles.secondaryButton}
            type={"button"}
            label={t("addExcelTableRow")}
            loading={globalLoading}
            onClick={() => handleAddExcelTableRow()}
        />
        <CustomTable
            className="custom-table"
            columns={excelStockRecordsColumns}
            data={excelTableData}
            action={() => {}}
        />
        
        {(excelTableData.length !== 0 &&  selectedRecordType ) && (
            <div
            className="mt-8">
            <MainButton 
                onClick={() => setVisibleConfirmUpdateStockByExcelDialog(true)}
                type={"button"}
                label={t("updateStock")}
                loading={globalLoading}
            />
            </div>
        )}
        <CustomConfirmDialog
                visible={visibleConfirmUpdateStockByExcelDialog}
                onHide={() => setVisibleConfirmUpdateStockByExcelDialog(false)}
                header={t("confirmDialog-updateStock.header")}
                message={t("confirmDialog-updateStock.message")}
                icon="pi pi-question-circle"
                accept={() => updateStock()}
                reject={() => setVisibleConfirmUpdateStockByExcelDialog(false)}
                acceptLabel={t("confirmDialog-updateStock.acceptLabel")}
                rejectLabel={t("confirmDialog-updateStock.rejectLabel")}
            />
        <CustomModal
            visible={visibleFailedSkusTableModal}
            setVisible={setVisibleFailedSkusTableModal}
            editMode={t("updateShippingAddress")}
            createMode={t("generateStockRecordsByExcelTable")}
            closable={false}
            content={
              <>
                <p className="text-red-600">
                    Los siguientes SKUS no se encontraron en el sistema, porfavor revisalos
                </p>
               <CustomTable
                    className="custom-table"
                    columns={failedSkusColumns}
                    data={failedSkusData}
                    action={() => {}}
                />
                <SecondaryButton
                    onClick={() => handleCloseFailedSkusTable()}
                    type={"button"}
                    label={t("Esta bien")}
                    loading={globalLoading}
                />
              </>
            }
            onClose={() => setVisibleFailedSkusTableModal(false)}
        />
        </>
    )
}

export default DetailUpdateStockExcelTable;
