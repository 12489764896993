import PropTypes from "prop-types";
import styles from "./DetailPromotionForm.module.css";
import { useEffect, useState } from "react";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import MainButton from "../MainButton/MainButton";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import { generateRandomId } from "../../utils/generateRandomId.JS";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext";
import { useTranslation } from "react-i18next";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog";
import DeleteButton from "../DeleteButton/DeleteButton";
import CustomInputSwitch from "../FormComponents/CustomInputSwitch/CustomInputSwitch";
import CustomMultiSelect from "../CustomMultiSelect/CustomMultiSelect";
import CustomTable from "../CustomTable/CustomTable";
import CustomModal from "../CustomModal/CustomModal";
import DetailVolumeTableRange from "../FormComponents/DetailVolumeTableRange/DetailVolumeTableRange";
import AuxButton from "../AuxButton/AuxButton";
import CustomInputNumber from "../FormComponents/CustomInputNumber/CustomInputNumber";
import toastFunction from "../../utils/toastFunction";
import { Divider } from "primereact/divider";
import { getPaginatedProducts } from "../../utils/getPaginatedProducts";


// Componente de formulario que muestra el formulario para crear o editar las promociones asociadas
const DetailPromotionForm = ({
  selectedSellerPromotion,
  labelSuccess,
  userData,
  labelDelete,
  onClose,
  toast,
  zones,
}) => {
const { t } = useTranslation("Promotions");
const {
  updateDocument,
  deleteDocument,
  createDocument,
  getAllDocumentsFromSubcollection,

} = useFirebaseCRUD();

const { globalLoading, setGlobalLoading } = useLoaderContext();
const { promotionTypes, unityTypes, eligibleLevels} = useGlobalContext();

// Estados para gestionar diferentes aspectos del componente
const [visibleConfirmDialog, setVisibleConfirmDialog] = useState(false);
const [eligibleLevel, setEligibleLevel] = useState(selectedSellerPromotion?.eligible_level || []);
// Estados relacionados con el formulario de promoción
const [relatedStoresIds, setRelatedStoresIds] = useState(selectedSellerPromotion?.related_stores_ids || []);
const [eligiblesProductsIds, setEligiblesProductsIds] = useState(selectedSellerPromotion?.eligible_products_ids || []);
const [eligibleDestinies, setEligibleDestinies] = useState(selectedSellerPromotion?.eligible_destines_ids || []);

// Otros estados relacionados con el componente
const [filteredSellerStores, setFilteredSellerStores] = useState([]);
const [syncedSHOPPEXProduct, setSyncedSHOPPEXProduct] = useState([]);
const hasEdit = !!Object.keys(selectedSellerPromotion).length;
  // Estado que contiene los valores finales de la promoción
  const initialPromotionValues = {
    id: selectedSellerPromotion?.id || generateRandomId(),
    related_business_id: userData?.business_id,
    promotion_name: selectedSellerPromotion?.promotion_name || "",
    is_active: selectedSellerPromotion?.is_active || false,
    is_accumulative: selectedSellerPromotion?.is_accumulative || false,
    all_stores_related: selectedSellerPromotion?.all_stores_related || false,
    related_stores_ids: relatedStoresIds,
    promotion_type: selectedSellerPromotion?.promotion_type || null,
    discount_unit: selectedSellerPromotion?.discount_unit || null,
    all_products_are_eligibles: selectedSellerPromotion?.all_products_are_eligibles || false,
    eligible_products_ids: eligiblesProductsIds,
    all_destines_are_eligibles: selectedSellerPromotion?.all_destines_are_eligibles || false,
    eligible_destines_ids: eligibleDestinies ,
    eligible_level: eligibleLevel ,
    code: selectedSellerPromotion?.code || null,

    discount_value: selectedSellerPromotion?.discount_value || null,
    discount_upsell_value: selectedSellerPromotion?.discount_upsell_value || null,
    discount_downsell_value: selectedSellerPromotion?.discount_downsell_value || null,
    discount_crossell_value: selectedSellerPromotion?.discount_crossell_value || null,
    discount_shipping_value: selectedSellerPromotion?.discount_shipping_value || null,

    volumen_discounts: selectedSellerPromotion?.volumen_discounts || [],
    upsell_product: selectedSellerPromotion?.upsell_product || null,
    downsell_product: selectedSellerPromotion?.downsell_product || null,
    crossell_product: selectedSellerPromotion?.crossell_product || null,

  };

  
  const [promotionValues, setPromotionValues] = useState(initialPromotionValues);
  const [visible, setVisible] = useState( false);

  const [loading, setLoading] = useState( false);

  const [selectedVolumeRange, setselectedVolumeRange] = useState();
  const CustomVolumeTableColumns = [
    {
      field: "min",
      filterable: false,
      header: t("custom-volume-table-headers.rangeMin"),
    },
    {
      field: "max",
      filterable: false,
      header: t("custom-volume-table-headers.rangeMax"),
    },
    {
      filterable: false,
      field: "value",
      header: t("custom-volume-table-headers.rangeAmount"),
    },
    {
      header: t("edit"),
      filterable: false,
      sortable: false,
      body: (rowData) => (
        <AuxButton
          data={rowData}
          disable={false}
          label={t("edit")}
          style={{
            backgroundColor: "var(--main-color)",
            color: "white",
            fontSize: "var(--font-size-h5)",
          }}
          onClick={() => openVolumeTableModal(rowData)}
        />
      ),
    },
  ];
  // Función para manejar las operaciones (Create, Update) en una promoción
  const handleCRUDPromotion = async (e) => {
    e.preventDefault();
    const hasEdit = !!Object.keys(selectedSellerPromotion).length;
    // Comprobar si se está editando una promoción existente
    if (validationPromotionFields()) { 
      if (hasEdit) {
        try {
          // console.log("antes de editar", promotionValues);
          // Actualizar la promoción existente en la base de datos
          const resultUpdatePromotion = await updateDocument(
            "promotions",
            selectedSellerPromotion.id,
            promotionValues
          );

          console.log(resultUpdatePromotion);
          if (resultUpdatePromotion) {
            toastFunction(toast, "success", t("toast-summary.updateStore"), "", 3000);
          }
          onClose(false);
        } catch (error) {
          toastFunction(toast, "error", t("toast-summary.errorUpdateStore"), error, 3000);
        }
      } else {
        try {
          //  Crear una nueva promoción en la base de datos
      setGlobalLoading(true);
          console.log("antes de crear", promotionValues);
          const result = await createDocument("promotions", promotionValues.id, {
            ...promotionValues,
            created_at: new Date(),
          });
          console.log(result);         
          toastFunction(toast, "success", t("toast-summary.createPromotion"),  "", 3000);
        } catch (error) {
          toastFunction(toast, "error", t("toast-summary.errorUpdatePromotion"),  error, 3000);
        }
      }
    setGlobalLoading(false);
    onClose(false);
    }
   
  };


  // Función para manejar la eliminación de una promoción
  const validationPromotionFields = () => {
    if (!promotionValues.promotion_type) {
      toastFunction(toast, "warn", "Porfavor elige un tipo de promocion", "", 3000);
      return false
    }else if (!promotionValues.discount_unit) {
      toastFunction(toast, "warn", "Porfavor elige la unidad del descuento", "", 3000);
      return false
    }else if (promotionValues.eligible_level === null) {
      toastFunction(toast, "warn", "Porfavor elige el nivel del descuento", "", 3000);
      return false
    }else if(promotionValues.promotion_type) {
      switch (promotionValues?.promotion_type?.code) {
        case 0:
          if (promotionValues.code === "") {
            toastFunction(toast, "warn", "Porfavor digita un codigo valido", "", 3000);
            return false
          }else if (promotionValues.discount_value === 0 || !promotionValues.discount_value) {
            toastFunction(toast, "warn", "Porfavor agrega un monto valido", "", 3000);
            return false
          }
          break;
        case 1:
          if (promotionValues.volumen_discounts.length === 0) {
            toastFunction(toast, "warn", "Porfavor agrega por lo menos un rango de volumen", "", 3000);
            return false
          }
        break;
        case 2:
          if (!promotionValues.upsell_product) {
            toastFunction(toast, "warn", "Porfavor elige un producto en UPSELL", "", 3000);
            return false
          } else if (!promotionValues.discount_upsell_value || promotionValues.discount_upsell_value == 0) {
            toastFunction(toast, "warn", "Porfavor agrega un monto valido", "", 3000);
          }
        break;
        case 3:
          if (!promotionValues.downsell_product) {
            toastFunction(toast, "warn", "Porfavor elige un producto en DOWNSELL", "", 3000);
            return false
          } else if (!promotionValues.discount_downsell_value || promotionValues.discount_downsell_value === 0) {
            toastFunction(toast, "warn", "Porfavor agrega un monto valido", "", 3000);
            return false
          }
        break;
        case 4:
          if (!promotionValues.crossell_product) {
            toastFunction(toast, "warn", "Porfavor elige un producto CROSSELL", "", 3000);
            return false
          } else if (!promotionValues.discount_crossell_value || promotionValues.discount_crossell_value === 0) {
            toastFunction(toast, "warn", "Porfavor agrega un monto valido", "", 3000);
            return false
          }
        break;
        case 5:   
          if (!promotionValues.discount_shipping_value || promotionValues.discount_crossell_value === 0) {
            toastFunction(toast, "warn", "Porfavor elige un producto SHIPPING", "", 3000);
            return false
          }
        break;
        default:
          break;
      }
    }

    return true
  };

  const handleDeletePromotion = async () => {
    setGlobalLoading(true);
    // Eliminar la promoción de la base de datos

    const resultDeleteFromFirestore = await deleteDocument(
      "promotions",
      promotionValues.id
    );
    if (resultDeleteFromFirestore) {
      toastFunction(toast, "success", t("toast-summary.deleteSellerStore"),  "", 3000);
    } else {
      toastFunction(toast, "error", t("toast-summary.errorSellerStore"),  "", 3000);
    }
    setGlobalLoading(false);
    onClose(false);
  };

  // Función para actualizar varios campos de la promoción basándose en el campo modificado
  const updatePromotionValues = (fieldName, value) => {
    let newValue = value;
    let newAmount = value; // Se utiliza para actualizar el estado 'amount' si es necesario
    switch (fieldName) {
      case "eligible_level": 
      setPromotionValues((prevValues) => ({
        ...prevValues,
        [fieldName]: value.code,
      }));
      setEligibleLevel(value);
        break;
      case "discount_unit":
        setPromotionValues((prevValues) => ({
          ...prevValues,
          [fieldName]: value,
          // discount_value: 0,
        }));
        break;      
      case "all_stores_related":
        setPromotionValues((prevValues) => ({
          ...prevValues,
          [fieldName]: value,
          related_stores_ids: [],
        }));
        break;
      case "all_products_are_eligibles":
        setPromotionValues((prevValues) => ({
          ...prevValues,
          [fieldName]: value,
          eligible_products_ids: [],
        }));
        break;
      case "all_destines_are_eligibles":
        setPromotionValues((prevValues) => ({
          ...prevValues,
          [fieldName]: value,
          eligible_destines_ids: [],
        }));
        break;
      case "promotion_type":
        setPromotionValues((prevValues) => ({
          ...prevValues,
          [fieldName]: value,
          discount_value: null,
          discount_crossell_value: null,
          discount_upsell_value: null,
          discount_downsell_value: null,
          discount_shipping_value: null,

        }));
        if (value.code === 5) {
          setEligibleLevel({code: 0, name: "Orden"})
        }
        
        break;
      case "discount_crossell_value":
      case "discount_upsell_value":
      case "discount_downsell_value":
      case "discount_shipping_value":
      case "discount_value":
        // console.log("unityType.code", promotionValues?.discount_unit?.code, value)
        if (promotionValues?.discount_unit?.code === 0) {
          // Si promotionValues.discount_unit.code es igual a 0, asegúrate de que el valor no sea más de 100
          const numericValue = parseInt(value); // Convertir a número
          if (!isNaN(numericValue)) {
              newAmount = Math.min(numericValue, 100); // Limitar el valor a 100 si es mayor
              newValue = parseInt(newAmount); // Convertir a número entero
          }
        } else if (promotionValues?.discount_unit?.code === 1) {
          // Si promotionValues.discount_unit.code es igual a 1, formatea el valor como pesos colombianos usando numeral
          const numericValue = parseFloat(value); // Convertir a número
          if (!isNaN(numericValue)) {
            newValue = numericValue;
          }
        }
        setPromotionValues((prevValues) => ({
          ...prevValues,
          [fieldName]: newValue,
        }));
        break;
      default:
      setPromotionValues((prevValues) => ({
        ...prevValues,
        [fieldName]: value,
      }));

      break;
    }
  
  };

  const updatePromotionMultiSelect = (fieldName, newValue) => {
    setPromotionValues((prevValues) => ({
      ...prevValues,
      [fieldName]: newValue,
    }));
  };
  // Función para abrir un modal que muestra una tabla de volumen

  const openVolumeTableModal = (range) => {
    console.log(range);
    setVisible(true);
    if (range.value) {
      setselectedVolumeRange(range);
    } else {
      setselectedVolumeRange({});
    }
  };
  // Función para cerrar el modal de la tabla de volumen
  const closeVolumeTableModal = () => {
    setVisible(false);
  };

  // Función para calcular la cantidad de productos y productos sincronizados con Shoppex de todas las tiendas del seller
  const amountAndMatchRelatedProducts = async (sellerStores) => {
    if (!userData || !sellerStores) return [];

    const productsIds = [];
    const syncedProductsIds = [];
    let relatedProducts = [];
    
    // SI la plataforma es SHOPPEX 

    await Promise.all(
      sellerStores.map(async (store) => {
                try {
                    // Obtenemos todos los documentos de la subcolección "related_products"
                    const productsResult = await getAllDocumentsFromSubcollection("stores", store.id, "related_products");
                    
                    // Iteramos sobre los productos y extraemos los `product_id` y `id`
                    productsResult.forEach((product) => {
                        if (product?.product_id && product?.id) {
                            productsIds.push(product.product_id); // Agregamos el product_id al array
                            syncedProductsIds.push(product.id);   // Agregamos el id al array
                            relatedProducts.push(product);        // Acumulamos el producto en relatedProducts
                        }
                    });
                } catch (error) {
                    console.error(`Error obteniendo productos para la tienda ${store.id}:`, error);
                }
        })
    );

    // Obtener los productos a partir de los productIds

    console.log("productsIds:", productsIds);
    
    const originalProducts = await getProductosFromIds(productsIds);

    console.log("Productos originales:", originalProducts);
    console.log("Productos relacionados:", relatedProducts);

    // Emparejamos los productos de "products" con los productos de "relatedProducts"
    const mergedProducts = originalProducts.map((product) => {
        // Buscamos en relatedProducts el objeto que tenga el mismo product_id
        const matchingProduct = relatedProducts.find(result => result.product_id === product.id);

        // Si encontramos una coincidencia, combinamos sus propiedades
        return matchingProduct
            ? { ...product, ...matchingProduct, related_synced_name: matchingProduct.custom_title, related_synced_id:matchingProduct.id }
            : product; // Si no hay coincidencia, devolvemos el producto original
    });

    console.log("Productos combinados:", mergedProducts);

    // Devolver los productos combinados
    return mergedProducts.length > 0 ? mergedProducts: [];
  };

  // Trae los productos de la coleccion "products" en base a su Id
  const getProductosFromIds = async (productsIds) => {
    try {
      if ( productsIds.length === 0 ) {
        return []
      }
      const body = {
        productsIds: productsIds,
        quantityDocuments: 30,
        type: "onlyProductsSnapshot",
        setProducts: setSyncedSHOPPEXProduct,
      };
      const response = await getPaginatedProducts(body)
      if (!response) {
        throw new Error("Error al obtener los productos");
      }
      return response;
    } catch (error) {
      console.error("Error al obtener los productos:", error);
      throw error;
    }
  };

  // Efecto segundario que filtra las tiendas del seller por su plataforma que se ejecuta cuando el componente se monta o cuando userData cambia
  useEffect(() => {
    // Verificar si userData y userData.seller_stores existen y no están vacíos
    setLoading(true)
    const fetchAndMatchInitialData = async () => {
      if (userData?.seller_stores && userData.seller_stores.length > 0 ) {
        const productsWithSyncedValues = [];
        // Filtrar las tiendas cuya palataforma sea supplaii
        const filteredStores = userData?.seller_stores.filter((store) => store.platform.code === 1);
        // Obtener productos sincronizados de las tiendas filtradas
      
        const products =  await amountAndMatchRelatedProducts(filteredStores);
        productsWithSyncedValues.push(...products)
        setSyncedSHOPPEXProduct(productsWithSyncedValues);
  
        const foundPromotionValue = eligibleLevels.find((level) => level.code === selectedSellerPromotion.eligible_level);
        setEligibleLevel(foundPromotionValue);
        setFilteredSellerStores(filteredStores);
      
      }
    }
    setLoading(false)
    if(userData) {
      fetchAndMatchInitialData()
    }
  }, [userData]);
  // console.log("VERIFICACION ---------")
  // console.log("TIENDAS ELEGIBLES opciones- valores" , filteredSellerStores, promotionValues.related_stores_ids, )

  // console.log("PRODUCTOS ELEGIBLES  opciones- valores" ,syncedSHOPPEXProduct, promotionValues.eligible_products_ids)


  return (
    <>
      {/* // Formulario para la creación, edición o eliminación de promociones */}
     { !loading ? 
     <form onSubmit={handleCRUDPromotion} className={styles.containerInputs}> 
        <CustomInputText
          floatLabel={true}
          type={"text"}
          value={promotionValues.promotion_name}
          placeholder={t("input-placeholders.name")}
          onChange={(e) =>  updatePromotionValues("promotion_name", e.target.value)}
          required={true}
        />

        <Divider />

        <CustomMultiSelect
          optionLabel={"name"}
          options={filteredSellerStores}
          value={promotionValues.related_stores_ids}
          onValue={setRelatedStoresIds}
          placeholder={t("input-placeholders.applicableStores")}
          disabled={ promotionValues.all_stores_related || filteredSellerStores.length === 0}
          updateField={updatePromotionMultiSelect}
          fieldNameToUpdate={"related_stores_ids"}
        />
      
        <div className={styles.containerSwitch}>
          <CustomInputSwitch
            checked={promotionValues.all_stores_related}
            onChange={(e) => updatePromotionValues("all_stores_related", e.target.value)}
          />
          <h5>Aplicar a todas las tiendas</h5>
        </div>
        <CustomMultiSelect
          optionLabel={"product_name"}
          options={syncedSHOPPEXProduct}
          value={promotionValues.eligible_products_ids}
          onValue={setEligiblesProductsIds}
          placeholder={t("input-placeholders.elegibleProducts")}
          disabled={promotionValues.all_products_are_eligibles || syncedSHOPPEXProduct.length === 0}
          updateField={updatePromotionMultiSelect}
          fieldNameToUpdate={"eligible_products_ids"}
        />

        <div className={styles.containerSwitch}>
          <CustomInputSwitch
            checked={promotionValues.all_products_are_eligibles}
            onChange={(e) => updatePromotionValues("all_products_are_eligibles", e.target.value)}
          />
          <h5>Aplicar a todas los productos</h5>
        </div>
        <div className="grid gap-4">
          <CustomMultiSelect
            floatLabel={true}
            optionLabel={"city_name"}
            options={zones}
            value={promotionValues.eligible_destines_ids}
            onValue={setEligibleDestinies}
            placeholder={t("input-placeholders.elegibleDestinations")}
            disabled={promotionValues.all_destines_are_eligibles}
            updateField={updatePromotionMultiSelect}
            fieldNameToUpdate={"eligible_destines_ids"}
          />

          <div className={styles.containerSwitch}>
            <CustomInputSwitch
              checked={promotionValues.all_destines_are_eligibles}
              onChange={(e) => updatePromotionValues("all_destines_are_eligibles", e.target.value)}
            />
            <h5>Aplicar a todas los destinos</h5>
          </div>
        </div>

        <Divider />

        <CustomSelect
          floatLabel={true}
          optionLabel={"name"}
          options={promotionTypes}
          valueTemplate={""}
          itemTemplate={""}
          value={promotionValues?.promotion_type}
          placeholder={t("input-placeholders.typePromotion")}
          onChange={(e) => { updatePromotionValues("promotion_type", e.target.value);}}
        />
      
        <CustomSelect
          floatLabel={true}
          optionLabel={"name"}
          options={eligibleLevels}
          valueTemplate={""}
          itemTemplate={""}
          value={eligibleLevel}
          placeholder={t("input-placeholders.eligibleLevel")}
          onChange={(e) => updatePromotionValues("eligible_level", e.target.value)}
          required={true}
          disabled={promotionValues?.promotion_type?.code === 5 }
        />

        <CustomSelect
          floatLabel={true}
          optionLabel={"name"}
          options={unityTypes}
          valueTemplate={""}
          itemTemplate={""}
          value={promotionValues.discount_unit}
          placeholder={t("input-placeholders.unityTypes")}
          onChange={(e) => updatePromotionValues("discount_unit", e.target.value)}
          disabled={promotionValues?.promotion_type?.code === 1 && promotionValues?.volumen_discounts.length > 0}
        />
    {/* Se muestran los componentes correspondientes segun el tipo de promocion  */}
        <div >
          {promotionValues?.promotion_type?.code === 0 && (
            <>
              <CustomInputText
                floatLabel={true}
                type={"text"}
                value={promotionValues?.code}
                placeholder={t("input-placeholders.promotionalCode")}
                onChange={(e) => updatePromotionValues("code", e.target.value)}
              />
              <CustomInputNumber
                floatLabel={true}
                type={"number"}
                value={promotionValues.discount_value}
                placeholder={t("input-placeholders.amount")}
                prefix={promotionValues?.discount_unit?.code === 1 ? "$" : ""}
                suffix={promotionValues?.discount_unit?.code === 0 ? "%" : ""}
                onChange={(e) => updatePromotionValues("discount_value", e.value)}
                disabled={!promotionValues.discount_unit}
                required={true}
              />
            </>
          )}
          {promotionValues?.promotion_type?.code === 1 && (
            <div className="mt-4 mb-4">            
              <CustomTable
                className= {"custom-table"}
                columns={CustomVolumeTableColumns}
                data={promotionValues?.volumen_discounts}
              />
              <div className="mt-4 mb-4">
                <MainButton
                  loading={globalLoading}
                  type={"button"}
                  label={t("createVolumeRange")}
                  onClick={openVolumeTableModal}
                />
              </div>
            <Divider />
            </div>
          )}
          {promotionValues?.promotion_type?.code === 2 && (
            <>
              <CustomSelect
                floatLabel={true}
                optionLabel={"product_name"}
                options={syncedSHOPPEXProduct}
                valueTemplate={""}
                itemTemplate={""}
                value={promotionValues?.upsell_product}
                placeholder={t("input-placeholders.upsellProductSelect")}
                onChange={(e) => {updatePromotionValues("upsell_product", e.target.value);}}

              />
              <CustomInputNumber
                floatLabel={true}
                type={"number"}
                value={promotionValues.discount_upsell_value}
                placeholder={t("input-placeholders.amount")}
                prefix={promotionValues?.discount_unit?.code === 1 ? "$" : ""}
                suffix={promotionValues?.discount_unit?.code === 0 ? "%" : ""}
                onChange={(e) => updatePromotionValues("discount_upsell_value", e.value)}
                disabled={!promotionValues.discount_unit}
                required={true}
              />
            </>
          )}
          {promotionValues?.promotion_type?.code === 3 && (
            <>
              <CustomSelect
                floatLabel={true}
                optionLabel={"product_name"}
                options={syncedSHOPPEXProduct}
                valueTemplate={""}
                itemTemplate={""}
                value={promotionValues?.downsell_product}
                placeholder={t("input-placeholders.downsellProductSelect")}
                onChange={(e) => { updatePromotionValues("downsell_product_id", e.target.value); }}
              />
              <CustomInputNumber
                floatLabel={true}
                type={"number"}
                value={promotionValues?.discount_downsell_value}
                placeholder={t("input-placeholders.amount")}
                prefix={promotionValues?.discount_unit?.code === 1 ? "$" : ""}
                suffix={promotionValues?.discount_unit?.code === 0 ? "%" : ""}
                onChange={(e) => updatePromotionValues("discount_downsell_value", e.value)}
                disabled={!promotionValues.discount_unit}
                required={true}
              />
            </>
          )}
          {promotionValues?.promotion_type?.code === 4 && (
            <>
              <CustomSelect
                floatLabel={true}
                optionLabel={"product_name"}
                options={syncedSHOPPEXProduct}
                valueTemplate={""}
                itemTemplate={""}
                value={promotionValues?.crossell_product}
                placeholder={t("input-placeholders.downsellProductSelect")}
                onChange={(e) => { updatePromotionValues("downsell_product_id", e.target.value); }}
              />
              <CustomInputNumber
                floatLabel={true}
                type={"number"}
                value={promotionValues.discount_crossell_value}
                placeholder={t("input-placeholders.amount")}
                prefix={promotionValues?.discount_unit?.code === 1 ? "$" : ""}
                suffix={promotionValues?.discount_unit?.code === 0 ? "%" : ""}
                onChange={(e) => updatePromotionValues("discount_crossell_value", e.value)}
                disabled={!promotionValues.discount_unit}
                required={true}
              />
            </>
            
          )}
          {promotionValues?.promotion_type?.code === 5 && (
            <>
              <CustomInputNumber
                floatLabel={true}
                type={"number"}
                value={promotionValues.discount_shipping_value}
                placeholder={t("input-placeholders.amount")}
                prefix={promotionValues?.discount_unit?.code === 1 ? "$" : ""}
                suffix={promotionValues?.discount_unit?.code === 0 ? "%" : ""}
                onChange={(e) => updatePromotionValues("discount_shipping_value", e.value)}
                disabled={!promotionValues.discount_unit}
                required={true}
              />
            </>

          )}
        </div>
        
        <Divider />

        <div className={styles.containerSwitch}>
          <CustomInputSwitch
            checked={promotionValues.is_accumulative}
            onChange={(e) => updatePromotionValues("is_accumulative", e.target.value)}
          />
          <h5>¿La promoción es acumulable?</h5>
        </div>
        
        <div className={styles.containerSwitch}>
          <CustomInputSwitch
            checked={promotionValues.is_active}
            onChange={(e) => updatePromotionValues("is_active", e.target.value)}
          />
          <h5>Activar</h5>
        </div>
        <div className={styles.containerBtns}>
          <MainButton
            type={"onSubmit"}
            label={hasEdit ? t("updatePromotion") : labelSuccess}
            loading={globalLoading}
            autoFocus
          />
          {hasEdit && (
            <DeleteButton
              type={"button"}
              label={labelDelete}
              loading={globalLoading}
              onClick={() => setVisibleConfirmDialog(true)}
            />
          )}
        </div>
        {/* Confirmación personalizada para eliminar la promoción */}
        <div>
          <CustomConfirmDialog
            visible={visibleConfirmDialog}
            onHide={() => setVisibleConfirmDialog(false)}
            header={t("confirmDialog-deletePromotion.header")}
            message={t("confirmDialog-deletePromotion.message")}
            icon="pi pi-question-circle"
            accept={handleDeletePromotion}
            reject={() => setVisibleConfirmDialog(false)}
            acceptLabel={t("confirmDialog-deletePromotion.acceptLabel")}
            rejectLabel={t("confirmDialog-deletePromotion.rejectLabel")}
          />
        </div>
        <div>
          {/* Modal personalizado para editar el rango de volumen de la promoción */}
          <CustomModal
            visible={visible}
            setVisible={setVisible}
            object={selectedVolumeRange}
            // menu_option={{label:t('menu-options.home')}}
            editMode={t("updateVolumenRange")}
            createMode={t("createVolumeRange")}
            content={
              <DetailVolumeTableRange
                updatePromotionValues={updatePromotionValues}
                onClose={setVisible}
                promotionData={promotionValues}
                selectedVolumeRange={selectedVolumeRange}
                userData={userData}
                labelDelete={t("deletePromotion")}
                labelSuccess={t("createPromotion")}
                toast={toast}
              />
            }
            onClose={closeVolumeTableModal}
          />
        </div>
      </form> : 
        <div>KARGANDO</div>
      }
    </>
  );
};

DetailPromotionForm.propTypes = {
  selectedSellerPromotion: PropTypes.object,
  userData: PropTypes.object,
  labelSuccess: PropTypes.string,
  labelDelete: PropTypes.string,
  onClose: PropTypes.func,
  index: PropTypes.number,
  toast: PropTypes.object,
  zones: PropTypes.array,
};

export default DetailPromotionForm;
