import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import CustomMultiSelect from "../CustomMultiSelect/CustomMultiSelect.jsx";
import CustomInputText from "../FormComponents/CustomInputText/CustomInputText.jsx";
import { Divider } from "primereact/divider";
import CustomSelect from "../FormComponents/CustomSelect/CustomSelect.jsx";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext.jsx";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext.jsx";
import { generateRandomId } from "../../utils/generateRandomId.js";
import BannerCard from "../BannerCard/BannerCard.jsx";
import MainButton from "../MainButton/MainButton.jsx";
import useAuth from "../../hooks/useAuth.js";
import CustomConfirmDialog from "../OverlayComponents/CustomConfirmDialog/CustomConfirmDialog.jsx";
import DeleteButton from "../DeleteButton/DeleteButton.jsx";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD.js";
import { COVER_BANNER, COVER_COLLECTION, DESKTOP, MOBILE } from "../router/paths.js";
import CustomInputNumber from "../FormComponents/CustomInputNumber/CustomInputNumber.jsx";
import CustomInputSwitch from "../FormComponents/CustomInputSwitch/CustomInputSwitch.jsx";
import styles from "./DetailBannerForm.module.css"

const DetailBannerForm = ({onClose, selectedBanner, categoriesData, t, storeTags, shoppexStoreId, shoppexStoreValues, setShoppexStoreValues}) => {
    const groupType = "banner"
    let hasEdit;
    if (selectedBanner) {
        hasEdit = !!Object.keys(selectedBanner)?.length;
    }
    const { deleteFolderStorageAndContents, removeFromArray } = useFirebaseCRUD();
    const { globalLoading} = useLoaderContext();
    const { coverTypes, collectionTypes, defaultCoverImage } = useGlobalContext();
    
    const { toast } = useAuth();
    const bannerInitialValues = {
        id: selectedBanner?.id || generateRandomId(),
        position: selectedBanner?.position || 0, // Posicion del banner
        collection_id: selectedBanner?.collection_id || generateRandomId(), // Id de la colección
        media_type: selectedBanner?.media_type || "", // Tipo del contenido multimedia del slide "img" || "video" 
        media_src: selectedBanner?.media_src || defaultCoverImage ,
        media_path: selectedBanner?.media_path || "", // URL del contenido multimedia
        media_mobile_src: selectedBanner?.media_mobile_src || defaultCoverImage, // URL del contenido multimedia mobile
        media_mobile_path: selectedBanner?.media_mobile_path || "",
        alt: selectedBanner?.alt || null,
        is_vertical_video: selectedBanner?.is_vertical_video || true, // Indicador de si el slide abrira el video en vertical
        vertical_video_product_id: selectedBanner?.vertical_video_product_id || [],  // Array de ids de los videos de los productos a mostrar al abrir el slide
        collection_type: selectedBanner?.collection_type || "", // Tipo de coleccion. “byCategorie”, “byTag”
        category_ids: selectedBanner?.categories_ids ||  [], // Ids de las categorias que forman la coleccion
        tag_ids: selectedBanner?.tag_ids || [], // Ids de los tags que conforman la coleccion
        collection_banner_media: selectedBanner?.collection_banner_media || [{
          media_src: defaultCoverImage , // URL del contenido multimedia
          media_path: null, // URL del contenido multimedia
          media_mobile_src: defaultCoverImage, // URL del contenido multimedia mobile
          media_mobile_path: null,
          alt: null,
        }]
    }
    const [bannerValues, setBannerValues] = useState(bannerInitialValues)
    
    const [selectedCollectionTypesValues, setSelectedCollectionTypesValues] = useState();
    const [collectionTypesOptions, setCollectionTypesOptions] = useState();

    const [ collectionType, setCollectionType ] = useState([]);
    const [ coverBannerType, setCoverBannerType ] = useState(null);
    const [ coverCollectionType, setCoverCollectionType ] = useState(null);

    const [visibleConfirmDeleteBannerModal, setVisibleConfirmDeleteBannerModal] = useState(false);

    
    const updateCollectionTypesField = (fieldName, newValue) => {
      if (fieldName === "tag_ids") {
        setBannerValues((prevValues) => ({
          ...prevValues,
          [fieldName]: newValue?.map((value) => value),
        }));
      } else if (fieldName === "category_ids") {
        setBannerValues((prevValues) => ({
          ...prevValues,
          [fieldName]: newValue?.map((value) => value?.id),
        }));
      }
      
    };
    const handleInputChange = (fieldName, e) => {
        let value = e;
        const collectionMediaBannerObject = bannerValues.collection_banner_media[0];
        if (fieldName === "collection_type") {
          console.log(value)
          switch (value?.code) {  // SI el codigo de el tipo de collecion es 0 es por "byCategorie"
            case 0:
              setBannerValues((prevData) => ({
                ...prevData,
                [fieldName]: value?.string,
                category_ids: [],
              }));
              setSelectedCollectionTypesValues([]);
              setCollectionTypesOptions(categoriesData);
              break;
            case 1:  // SI el codigo de el tipo de collecion es 1 es por "byTag"
              setBannerValues((prevData) => ({
                ...prevData,
                [fieldName]: value?.string,
                tag_ids: [],

              }));
              setSelectedCollectionTypesValues([]);

              setCollectionTypesOptions(storeTags);
              break;
            default:
              setBannerValues((prevData) => ({
                ...prevData,
                [fieldName]: value?.string,
              }));
              break;
          }
            setCollectionType(value);
        } else if ( fieldName === "media_type") {
          setBannerValues((prevData) => ({
            ...prevData,
            [fieldName]:  value?.string, // Tipo del contenido multimedia del slide "img" || "video"
          }));
          setCoverBannerType(value);
        } else if ( fieldName === "collection_banner_media.media_type") {
          setBannerValues((prevData) => ({
            ...prevData,
            collection_banner_media:  [{
              ...collectionMediaBannerObject,
              media_type: value?.string, // Tipo del contenido multimedia del slide "img" || "video"
            }]
          }));
          setCoverCollectionType(value);
        } else if ( fieldName === "collection_banner_media.alt") {
          setBannerValues((prevData) => ({
            ...prevData,
            collection_banner_media:  [{
              ...collectionMediaBannerObject,
              alt: value, // Tipo del contenido multimedia del slide "img" || "video"
            }]
          }));
        } else {
          setBannerValues((prevData) => ({
            ...prevData,
            [fieldName]: value,
          }));
        }
    };  
    const handleDeleteCoverBanner = (mediaType) => {
       // Mostrar imagen predeterminada si mediaSrc está vacío
      switch (mediaType) {
        case DESKTOP:
          setBannerValues((prevData) => ({
            ...prevData,
            media_src:  defaultCoverImage,
          }));
          break;
        case MOBILE:
          setBannerValues((prevData) => ({
            ...prevData,
            media_mobile_src: defaultCoverImage,
          }));
          break;

        default:
          break;
      }
      
    }
    const handleDeleteCoverColection = (mediaType) => {
      const collectionMediaBannerObject = bannerValues.collection_banner_media[0];
      // Mostrar imagen predeterminada si mediaSrc está vacío
     switch (mediaType) {
       case DESKTOP:
        setBannerValues((prevData) => ({
          ...prevData,
          collection_banner_media:  [{
            ...collectionMediaBannerObject,
            media_src:  defaultCoverImage,
          }]
        }));
        break;
       case MOBILE:
        setBannerValues((prevData) => ({
          ...prevData,
          collection_banner_media:  [{
            ...collectionMediaBannerObject,
            media_mobile_src: defaultCoverImage,
          }]
        }));
        break;

       default:
         break;
     }
     
    }
    const saveBannerValues = async (e) => {
      e.preventDefault();
        const filteredBanners = shoppexStoreValues?.main_banner?.filter((banner) => banner?.id !== bannerValues.id);
        setShoppexStoreValues((prevData) => {
          return { ...prevData, main_banner: [...filteredBanners, bannerValues] };
        });
        onClose();
      
    }
    const handleDeleteBanner = async () => {
      const pathToDelete = `shoppexStores/${shoppexStoreId}/${groupType}/${bannerValues.id}/`;
    
      try {
        // Eliminar la ruta del almacenamiento
        await deleteFolderStorageAndContents(pathToDelete);
        console.log('Ruta eliminada exitosamente del almacenamiento de Firebase.', selectedBanner);
        
        if (hasEdit)      {
          const result = await removeFromArray("stores", shoppexStoreId, "main_banner", selectedBanner);          
          console.log(result)
        }
        // Si el proceso de eliminación se completa correctamente, actualizar el estado o realizar otras acciones necesarias
        const filteredBanners = shoppexStoreValues?.main_banner?.filter((banner) => banner?.id !== bannerValues.id);
        setShoppexStoreValues((prevData) => {
          return { ...prevData, main_banner: filteredBanners};
        });
        onClose();
      } catch (error) {
        console.error('Error al eliminar la ruta del almacenamiento de Firebase:', error);
      }
    }
  
  useEffect(() => {
    if (selectedBanner) {
      let findedCollectionTypes = null;
      let findedCollectionOptions = null;
      const findedCollectionType = collectionTypes?.find((collectionType) => selectedBanner?.collection_type === collectionType?.string);

      switch (findedCollectionType?.code) {
        case 0: // SI el codigo de el tipo de collecion es 0 es por "byCategorie"
          findedCollectionTypes = selectedBanner?.category_ids?.map(categoryId =>
            categoriesData?.find(category => category.id === categoryId)
          )?.filter(category => category !== undefined);
          findedCollectionOptions = categoriesData;
          break;
        case 1:  // SI el codigo de el tipo de collecion es 1 es por "byTag"
          findedCollectionTypes = selectedBanner?.tag_ids?.map(tagId =>
            storeTags?.find(tag => tag === tagId)
          )?.filter(tagId => tagId !== undefined);
          findedCollectionOptions = storeTags;
          break;
        default:
          break;
      }
      
      const findedCoverBannerType = coverTypes?.find((bannerType) => selectedBanner?.media_type === bannerType?.string)
      const findedCoverCollectionType = coverTypes?.find((bannerType) => selectedBanner?.collection_banner_media[0]?.media_type === bannerType?.string)
      setSelectedCollectionTypesValues(findedCollectionTypes)
      setCollectionTypesOptions(findedCollectionOptions);
      setCollectionType(findedCollectionType);
      setCoverBannerType(findedCoverBannerType);
      setCoverCollectionType(findedCoverCollectionType)
    }
  }, [selectedBanner]);
  console.log(bannerValues, selectedBanner)

return (
    <>
      <Divider/>
          <CustomInputNumber
            floatLabel={true}
            type={"text"}
            value={bannerValues.position}
            placeholder={t("input-placeholders.position")}
            onChange={(e) => handleInputChange("position", e.value)}
            disabled={globalLoading}
          />
          <CustomSelect
            floatLabel={true}
            optionLabel={"name"}
            options={collectionTypes}
            valueTemplate={""}
            itemTemplate={""}
            value={collectionType}
            placeholder={t("input-placeholders.collectionTypes")}
            onChange={(e) => handleInputChange("collection_type", e.target.value)}
            required={true}
          />
          <CustomMultiSelect
            optionLabel={collectionType?.code === 0 ? "category_label": ""}
            options={collectionTypesOptions}
            value={selectedCollectionTypesValues}
            onValue={setSelectedCollectionTypesValues}
            placeholder={collectionType?.code === 0 ? t("input-placeholders.categoryProduct") : collectionType?.code === 1  ? t("input-placeholders.tagProduct") : t("input-placeholders.tagAndCategorysProduct")}
            updateField={updateCollectionTypesField}
            fieldNameToUpdate={collectionType?.code === 0 ? "category_ids": "tag_ids"}
            disabled={globalLoading}
        />
        <div className ={styles.inputSwitch}>
            <CustomInputSwitch 
              checked={bannerValues?.is_vertical_video}
              onChange={(e) => handleInputChange("is_vertical_video", e.target.value)}
            />
            <label 
              // onClick={() => setIsSupplier(true)}
              className='clickable'
              style={{color: "var(--font-body-color)"}}
              >
                  ¿ Abre un video vertical ?
              </label>
          </div>
        <Divider/>
        <h4>Cover del banner</h4>
        
        <CustomSelect
            floatLabel={true}
            optionLabel={"name"}
            options={coverTypes}
            valueTemplate={""}
            itemTemplate={""}
            value={coverBannerType}
            placeholder={t("input-placeholders.coverTypes")}
            onChange={(e) => handleInputChange("media_type", e.target.value)}
            required={true}
          />
     
        <CustomInputText
            floatLabel={true}
            type={"text"}
            value={bannerValues?.alt}
            placeholder={t("input-placeholders.alt")}
            onChange={(e) => handleInputChange("alt", e.target.value)}
            required={true}
        />

        <div className="grid gap-12 mt-10 ">
          <BannerCard shoppexStoreId={shoppexStoreId} data={bannerValues} coverTypeMedia={coverBannerType} coverType={COVER_BANNER} groupType={groupType} groupId={bannerValues.id} mediaType={DESKTOP} onClick={handleDeleteCoverBanner} isErasable={true} toast={toast} setBannerValues={setBannerValues} />
          <BannerCard shoppexStoreId={shoppexStoreId} data={bannerValues} coverTypeMedia={coverBannerType} coverType={COVER_BANNER} groupType={groupType} groupId={bannerValues.id} mediaType={MOBILE} onClick={handleDeleteCoverBanner} isErasable={true} toast={toast} setBannerValues={setBannerValues} />
        </div>
    
        <Divider/>
        <h4>Cover de la coleccion</h4>
        <CustomSelect
            floatLabel={true}
            optionLabel={"name"}
            options={coverTypes}
            valueTemplate={""}
            itemTemplate={""}
            value={coverCollectionType}
            placeholder={t("input-placeholders.collectionTypes")}
            onChange={(e) => handleInputChange("collection_banner_media.media_type", e.target.value)}
            required={true}
          />
     
        <CustomInputText
            floatLabel={true}
            type={"text"}
            value={bannerValues?.collection_banner_media[0]?.alt}
            placeholder={t("input-placeholders.alt")}
            onChange={(e) => handleInputChange("collection_banner_media.alt", e.target.value)}
            required={true}
        />
        <div className="grid gap-12 mt-10 ">
          <BannerCard shoppexStoreId={shoppexStoreId} coverTypeMedia={coverCollectionType}coverType={COVER_COLLECTION} groupType={groupType} groupId={bannerValues.id} data={bannerValues?.collection_banner_media[0]} mediaType={DESKTOP} onClick={handleDeleteCoverColection} isErasable={true} toast={toast} setBannerValues={setBannerValues} />
          <BannerCard shoppexStoreId={shoppexStoreId} coverTypeMedia={coverCollectionType} coverType={COVER_COLLECTION} groupType={groupType} groupId={bannerValues.id} data={bannerValues?.collection_banner_media[0]} mediaType={MOBILE} onClick={handleDeleteCoverColection} isErasable={true} toast={toast} setBannerValues={setBannerValues} />
        </div>

        <div className="grid gap-4 mt-12">
          <MainButton
            loading={globalLoading}
            label={t("save")}
            type={"button"}
            onClick={saveBannerValues}
          />
         {hasEdit && (
              <span>
                <DeleteButton
                  type={"button"}
                  label={t("deleteBanner")}
                  loading={globalLoading}
                  onClick={() => setVisibleConfirmDeleteBannerModal(true)}
                  disabled={globalLoading}
                />
              </span>
            )}
        </div>
      
      <CustomConfirmDialog
          visible={visibleConfirmDeleteBannerModal}
          onHide={() => setVisibleConfirmDeleteBannerModal(false)}
          header={t("confirmDialog-deleteBanner.header")}
          message={t("confirmDialog-deleteBanner.message")}
          icon="pi pi-question-circle"
          accept={handleDeleteBanner}
          reject={() => setVisibleConfirmDeleteBannerModal(false)}
          acceptLabel={t("confirmDialog-deleteBanner.acceptLabel")}
          rejectLabel={t("confirmDialog-deleteBanner.rejectLabel")}
      />
    </>
)
}
DetailBannerForm.propTypes = {
    selectedBanner: PropTypes.object,
    categoriesData: PropTypes.array,
    updateCollectionTypesField: PropTypes.func,
    toast: PropTypes.object,
    t: PropTypes.func,
    storeTags: PropTypes.array,
    shoppexStoreId: PropTypes.string,
    onClose: PropTypes.func,
    object: PropTypes.object,
    shoppexStoreValues: PropTypes.object,
    setShoppexStoreValues: PropTypes.func,

}
export default DetailBannerForm;