import styles from "./MessageListCard.module.css"
export default function MessageListCard({onMessageSelect}) {

    return (
      <li 
        className={styles.messageCardContainer}
        onClick={onMessageSelect}
        >
        <div className={styles.contactAvatar}>
          <img src="contact-avatar.png" alt="contact" />
        </div>
        <div className={styles.contactInfo}>
          <h4>Nombre de contacto</h4>
          <p>Ultimo mensaje...</p>
        </div>
        <div className={styles.messageTime}>09:26 PM</div>
      </li>
    );
  }